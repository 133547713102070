import { createReducer } from 'typesafe-actions'
import { setLanguageIsLoaded } from '../actions/app/i18n.actions'

type LanguageIsLoadedState = boolean

export const defaultState: LanguageIsLoadedState = false

const languageIsLoadedReducer = createReducer(defaultState)
    .handleAction(setLanguageIsLoaded, (state, action) => action.payload)

export default languageIsLoadedReducer
