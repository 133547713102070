import { createReducer } from 'typesafe-actions'
import { setSidebarExpandState } from '../actions/app/sidebar.actions'

export type UiState = {
    sidebarIsExpanded: boolean
}

export const defaultState: UiState = {
    sidebarIsExpanded: false,
}

const uiReducer = createReducer(defaultState)
    .handleAction(setSidebarExpandState, (state, action) => ({
        ...state,
        sidebarIsExpanded: action.payload,
    }))

export default uiReducer
