import React, { FunctionComponent, PropsWithChildren } from 'react'
import style from './loadingIndicatorWrapper.css'

export type Props = PropsWithChildren<{
    isLoading: boolean
    className?: string
}>

export const getLoadingClassName = (isLoading: boolean): string => (isLoading ? style.loading : '')

const LoadingIndicatorWrapper: FunctionComponent<Props> = (props) => {
    const { isLoading, className, children } = props
    const classNames = isLoading ? [
        className,
        getLoadingClassName(true),
    ].join(' ') : null

    return <div className={classNames}>{children}</div>
}

export default LoadingIndicatorWrapper
