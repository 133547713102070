import { createReducer } from 'typesafe-actions'
import { BuildabilityType } from '../../entities/BuildabilityType'
import { setCurrentBuildabilityType } from '../actions/app/buildabilitySwitcher.actions'

export const defaultState: BuildabilityType = null

const currentBuidabilityTypeReducer = createReducer(defaultState)
    .handleAction(setCurrentBuildabilityType, (state, action) => action.payload)

export default currentBuidabilityTypeReducer
