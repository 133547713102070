import { FunctionComponent, useEffect } from 'react'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    activeNavLinkTitle: string
}

const DocumentTitle: FunctionComponent<Props> = (props) => {
    const { activeNavLinkTitle } = props
    const { t } = useTranslation()

    useEffect(() => {
        document.title = `${t('title')} - ${t(activeNavLinkTitle)}`
    }, [activeNavLinkTitle, t])

    return null
}

export default DocumentTitle
