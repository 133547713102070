import React, { FunctionComponent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import useTranslation from '../../hooks/useTranslation'
import Notification from '../../entities/Notification'
import CancelIcon from '../../assets/media/icons/cancel.svg'
import style from './notificationList.css'

export type Props = {
    notifications: Notification[]
    resetNotifications: Function
    removeNotification: (notification: Notification) => void
}

const NotificationList: FunctionComponent<Props> = (props) => {
    const { notifications, removeNotification } = props
    const { i18n, t } = useTranslation()

    const listElements = notifications.map((notification) => {
        const { type, message } = notification
        const wrapperClassName = `${style.wrapper} ${style[`wrapper-${type}`]}`
        const translatedMessage = i18n.exists(message) ? t(message) : message

        return (
            <motion.li
                key={message}
                className={style.listItem}
                initial={{
                    opacity: 0,
                    y: '30px',
                }}
                animate={{
                    opacity: 1,
                    y: '0',
                }}
                exit={{
                    opacity: 0,
                    y: '-30px',
                }}
                transition={{
                    ease: [0.75, 0.02, 0.5, 1],
                    duration: 0.42,
                }}
            >
                <div className={wrapperClassName}>
                    <p className={style.message}>{translatedMessage}</p>
                    <button
                        onClick={() => removeNotification(notification)}
                        className={style.button}
                        type="button"
                    >
                        <CancelIcon className={style.icon} />
                    </button>
                </div>
            </motion.li>
        )
    })

    return (
        <div className={style.container}>
            <ul className={style.list}>
                <AnimatePresence initial>
                    {listElements}
                </AnimatePresence>
            </ul>
        </div>
    )
}

export default NotificationList
