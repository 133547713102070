import React, { FunctionComponent } from 'react'
import CancelIcon from '../../assets/media/icons/cancel.svg'
import style from './modal.css'

export type Props = {
    onClick: Function
    className?: string
}

const ModalClosingButton: FunctionComponent<Props> = (props) => {
    const { onClick, className } = props
    const finalClassName = `${className} ${style.closeButton}`

    const closeModal = (): void => {
        onClick()
    }

    return (
        <button onClick={closeModal} className={finalClassName} type="button" data-cy="modal-button-close">
            <CancelIcon className={style.closeButtonIcon} />
        </button>
    )
}

ModalClosingButton.defaultProps = {
    className: '',
}

export default ModalClosingButton
