import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import SummaryData from '../../entities/SummaryData'
import Cartbox from '../cartbox'
import OverviewEquipmentGroups from '../overview-equipment-groups'
import DisclaimerText from '../disclaimer-text'
import style from './overviewView.css'
import { OrderKey } from '../../entities/ConfigurationResponse'
import SummaryTable from '../summary-table'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'

export type Props = {
    summary: SummaryData
    carlineTitle: string
    loadSummaryData: Function
    orderKey: OrderKey
    isLoading: boolean
}

const OverviewView: FunctionComponent<Props> = (props) => {
    const {
        summary,
        carlineTitle,
        loadSummaryData,
        orderKey,
        isLoading,
    } = props
    const { t } = useTranslation()

    const envkvLabelObject = summary.envkvLabelUrl
        ?
            <div className={style.envkvLabelWrapper}>
                <img alt="" className={style.envkvLabelImage} src={summary.envkvLabelUrl
                }/>
            </div>
        : null

    return (
        <>
            <div className="contentWithCartboxContainer">
                <div className="contentWithCartbox">
                    <LoadingIndicatorWrapper isLoading={isLoading}>
                        <h1>
                            {t('overview.heading')}
                        </h1>
                        <h2 className={style.subTitle}>
                            {carlineTitle}
                        </h2>
                        <SummaryTable summary={summary} loadSummaryData={loadSummaryData} />
                        {envkvLabelObject}
                        <h2 className={style.subTitle}>
                            {t('overview.orderKey')}
                        </h2>
                        <p>
                            <b data-cy="order-key">{orderKey}</b>
                        </p>
                        <OverviewEquipmentGroups />
                    </LoadingIndicatorWrapper>
                    <DisclaimerText remarks={summary.remarks} />
                </div>
                <Cartbox />
            </div>
        </>
    )
}

export default OverviewView
