import React, { FunctionComponent } from 'react'
import CarlineGroupPlaceholderImage from '../../assets/media/car-silhouette56.svg'
import useTranslation from '../../hooks/useTranslation'
import carlineGridGroupStyle from '../carline-grid-group/carlineGridGroup.css'
import style from './carlineGridView.css'

const CarlineGridViewPlaceholder: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <>
            {[...Array(12)].map((element, index) => (
                <div className={style.listItem} key={`carline-groups-placeholder-${index}`}>
                    <div className={carlineGridGroupStyle.buttonPlaceholder}>
                        <CarlineGroupPlaceholderImage className={style.imgPlaceholder} />
                        <span className={carlineGridGroupStyle.titlePlaceholder}>{t('loading')}</span>
                    </div>
                </div>
            ))}
        </>
    )
}

export default CarlineGridViewPlaceholder
