import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    closeInfoModal,
    openInfoModal,
    setInfoModalData,
    setInfoModalDataState,
} from '../../actions/app/infoModal.actions'
import infoModalSelector from '../../selectors/infoModalSelector'

const infoModalMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(setInfoModalData, action)) {
        dispatch(setInfoModalDataState(action.payload))
    }

    if (isActionOf(openInfoModal, action)) {
        dispatch(setInfoModalData({
            ...action.payload,
            modalIsOpen: true,
        }))
    }

    if (isActionOf(closeInfoModal, action)) {
        const infoModalData = infoModalSelector(getState())
        const onCloseCallback = infoModalData.onClose

        if (onCloseCallback) {
            dispatch(onCloseCallback)
        }

        dispatch(setInfoModalData({
            modalIsOpen: false,
        }))
    }
}

export default infoModalMiddleware
