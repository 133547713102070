import { createSelector } from 'reselect'
import modelsSelector from './modelsSelector'
import configuredModelIdSelector from './configuredModelIdSelector'
import Model from '../../../entities/Model'

const modelsWithSelectedSelector = createSelector(
    modelsSelector,
    configuredModelIdSelector,
    (models, configuredModelId): Model[] => models.map((model) => ({
        ...model,
        selected: model.id === configuredModelId,
    })),
)

export default modelsWithSelectedSelector
