import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { selectCarline } from '../../actions/app/carlineSelection.actions'
import carlinesSelector from '../../selectors/carlines/carlinesSelector'
import carlineGroupsSelector from '../../selectors/carlines/carlineGroupsSelector'
import { setTrackingState } from '../../actions/tracking/tracking.actions'
import trackingSelector from '../../selectors/tracking/trackingSelector'
import createCarlineTrackingObj from './helpers/createCarlineTrackingObj'
import getSelectedCarlineGroup from './helpers/getSelectedCarlineGroup'
import brandSelector from '../../selectors/brandSelector'
import getManufacturer from './helpers/getManufacturer'

const trackSelectCarlineMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(selectCarline, action)) {
        const state = store.getState()
        const tracking = trackingSelector(state)
        const carlines = carlinesSelector(state)
        const selectedCarline = carlines.find((carline) => carline.id === action.payload)
        const carlineGroups = carlineGroupsSelector(state)
        const selectedCarlineGroup = getSelectedCarlineGroup(carlineGroups, selectedCarline)

        if (selectedCarline === undefined || selectedCarlineGroup === undefined) {
            return
        }

        const updatedTracking = {
            ...tracking,
        }

        const brand = brandSelector(state)
        const manufacturer = getManufacturer(brand)

        const carlineTrackingObj = createCarlineTrackingObj(
            selectedCarlineGroup,
            selectedCarline,
            manufacturer,
        )

        updatedTracking.products = {
            ...updatedTracking.products,
            configuredVehicle: [
                {
                    ...updatedTracking.products.configuredVehicle[0],
                    carline: carlineTrackingObj,
                },
            ],
        }

        store.dispatch(setTrackingState(updatedTracking, {
            causedBy: action,
        }))
    }
}

export default trackSelectCarlineMiddleware
