import i18n from 'i18next'
import { UseTranslationOptions, UseTranslationResponse, useTranslation as useI18nTranslation } from 'react-i18next'

const useTranslation = (options?: UseTranslationOptions): UseTranslationResponse<'dcc-app'> =>
    useI18nTranslation('dcc-app', {
        ...options,
        useSuspense: false,
    })

export const translate = (key: string, params?: {}): string | null => {
    const result = i18n.t(key, params)

    return result === key ? null : result
}

export default useTranslation
