import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CornerRibbon'
import previewCornerRibbonIsVisibleSelector from '../../redux/selectors/feature-scope/previewCornerRibbonIsVisibleSelector'

type StateProps = Pick<Props, 'isVisible'>

const mapStateTopProps: MapStateToProps<StateProps, {}> = (state) => ({
    isVisible: previewCornerRibbonIsVisibleSelector(state),
})

export default connect(mapStateTopProps)
