import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { setActiveRenderImgIdx, setActiveRenderImgIdxState } from '../../actions/app/renderImages.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'

const renderImagesMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(setActiveRenderImgIdx, action)) {
        dispatch(setActiveRenderImgIdxState(action.payload))
    }

    if (isActionOf(fetchVisualizationAsync.success, action)) {
        const { selectedView, renderImages } = action.payload
        const selectedViewIdx = renderImages.findIndex((image) => image.id === selectedView)
        const idx = (selectedViewIdx === -1) ? 0 : selectedViewIdx

        dispatch(setActiveRenderImgIdxState(idx))
    }
}

export default renderImagesMiddleware
