import { Markup } from 'interweave'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Table, Td, Tr } from '../../table'
import { useMappedConfiguration } from '../hooks/useMappedConfiguration'

export type Props = {}

const NameContainer = styled.div`
    display: flex;
    align-items: center;
`

const NameColumn = styled(Td)`
    padding: var(--space-xs);
    width: 38%;
`

const ValueColumn = styled(Td)`
    padding: var(--space-xs);
`

const HeadingWrapper = styled.div<{ first?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${(props) => (props.first ? '0' : 'var(--space-xl)')};
    margin-bottom: var(--space-m);
`

const Title = styled(Markup)`
    margin-top: 0;
    margin-bottom: 0;
`

const TechnicalDataTab: FunctionComponent<Props> = () => {
    const { technicalData } = useMappedConfiguration()

    const tableElements = technicalData.map((group, index) => {
        const dataEntryElements = group.entries?.map((entry) => (
            <Tr key={entry.label}>
                <NameColumn>
                    <NameContainer>
                        <div>
                            <Markup content={entry.label} tagName="span" />
                        </div>
                    </NameContainer>
                </NameColumn>
                <ValueColumn>
                    <Markup content={entry.value} tagName="span" />
                </ValueColumn>
            </Tr>
        ))

        return (
            <section key={index}>
                <HeadingWrapper first={index === 0}>
                    <Title content={group.headline} tagName="h3" />
                </HeadingWrapper>
                <Table>
                    <tbody>{dataEntryElements}</tbody>
                </Table>
            </section>
        )
    })

    return <>{tableElements}</>
}

export default TechnicalDataTab
