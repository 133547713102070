import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as FeatureHubReact from '@feature-hub/react'
import { defineExternals } from '@feature-hub/module-loader-amd'
import config from '../config'

defineExternals({
    react: React,
    'react-dom': ReactDOM,
    '@feature-hub/react': FeatureHubReact,
})

const externals = {
    react: config.REACT_VERSION,
    'react-dom': config.REACT_VERSION,
    '@feature-hub/react': config.FEATURE_HUB_REACT_VERSION,
}

export default externals
