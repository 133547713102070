import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { AppliedFilter } from '../../entities/Filter'
import style from './attributeFilters.css'
import FilterOption from '../../entities/FilterOption'
import RangeSlider from '../range-slider/RangeSlider'
import AttributeFiltersPlaceholder from './AttributeFiltersPlaceholder'
import { Checkbox } from '../form'

export type Props = {
    filterOptions: FilterOption[]
    applyFilter: (filter: AppliedFilter) => void
    isLoading: boolean
    layout: 'vertical' | 'horizontal'
}

const AttributeFilters: FunctionComponent<Props> = (props) => {
    const {
        filterOptions,
        applyFilter,
        isLoading,
        layout,
    } = props
    const { t } = useTranslation()

    const filterElements = (filterOptions.length === 0 && isLoading)
        ? <AttributeFiltersPlaceholder />
        : filterOptions.map((filterOption) => {
            const {
                attribute,
                type,
                values,
                activeValues,
            } = filterOption

            const inputChangeHandler = (value: number | string): void => {
                applyFilter({
                    id: attribute,
                    value,
                    type,
                })
            }

            if (type === 'multiple') {
                if (values.length < 2) {
                    return null
                }

                const inputElements = (values as string[]).map((value) => {
                    const isChecked = activeValues?.includes(value)

                    return (
                        <li className={style.inputListItem} key={value}>
                            <label className={style.inputLabelWrapper}>
                                <Checkbox
                                    value={value}
                                    onChange={({ target }) => inputChangeHandler(target.value)}
                                    checked={isChecked}
                                    data-cy={`attribute-filter-${value}`}
                                />
                                <span className={style.inputLabel}>
                                    {t(`attributes.valueLabels.${attribute}.${value}`)}
                                </span>
                            </label>
                        </li>
                    )
                })

                return (
                    <div key={attribute}>
                        <span className={style.title}>
                            {t(`filters.filterLabels.${attribute}`)}
                        </span>
                        <ul className={style.inputList}>
                            {inputElements}
                        </ul>
                    </div>
                )
            }

            if (type === 'max') {
                const initialValue = activeValues ? activeValues[0] as number : undefined

                return (
                    <label key={attribute}>
                        <span className={style.title}>{t(`filters.filterLabels.${attribute}-${type}`)}</span>
                        <RangeSlider
                            initial={initialValue}
                            values={values as number[]}
                            onChange={inputChangeHandler}
                            debounceTime={200}
                            suffix={t(`filters.filterValueSuffix.${attribute}`)}
                            dataCy={`attribute-filter-${attribute}`}
                        />
                    </label>
                )
            }

            return null
        })

    const wrapperClassName = style[`wrapper-${layout}`]

    return (
        <div className={wrapperClassName}>
            {filterElements}
        </div>
    )
}

export default AttributeFilters
