import { connect } from 'react-redux'
import { Props } from './ResetConfigurationButton'
import { resetConfiguration } from '../../redux/actions/app/configuration.actions'

type DispatchProps = Pick<Props, 'resetConfiguration'>

const mapDispatchToProps: DispatchProps = {
    resetConfiguration,
}

export default connect(undefined, mapDispatchToProps)
