import { createSelector } from 'reselect'
import languageSelector from './languageSelector'
import countrySelector from './countrySelector'

const languageKeySelector = createSelector(
    languageSelector,
    countrySelector,
    (language, country): string => `${language}${country ? `_${country}` : ''}`,
)

export default languageKeySelector
