import { createSelector } from 'reselect'
import entryDataSelector from '../entryDataSelector'
import EntryData from '../../../entities/EntryData'

const renderImagesOrderingSelector = createSelector(
    entryDataSelector,
    (entryData): EntryData['renderImagesOrdering'] => entryData.renderImagesOrdering,
)

export default renderImagesOrderingSelector
