import { connect, MapStateToProps } from 'react-redux'
import configuredCarlineIdSelector from '../../redux/selectors/carlines/configuredCarlineIdSelector'
import { selectCarline } from '../../redux/actions/app/carlineSelection.actions'
import { Props } from './ResetCarlineSelection'
import configuredCarlineNameSelector from '../../redux/selectors/carlines/configuredCarlineNameSelector'
import carlineSelectConfigMismatchSelector from '../../redux/selectors/carlines/carlineSelectConfigMismatchSelector'

type StateProps = Pick<Props, 'isVisible' | 'configuredCarlineName' | 'configuredCarlineId'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isVisible: carlineSelectConfigMismatchSelector(state),
    configuredCarlineName: configuredCarlineNameSelector(state),
    configuredCarlineId: configuredCarlineIdSelector(state),
})

type DispatchProps = Pick<Props, 'selectCarline'>

const mapDispatchToProps: DispatchProps = {
    selectCarline,
}

export default connect(mapStateToProps, mapDispatchToProps)
