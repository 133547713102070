import { createAction } from 'typesafe-actions'
import { AppliedFilter } from '../../../entities/Filter'

export const applyFilter = createAction('CMD / Filter / apply')<AppliedFilter>()

export const addFilterToState = createAction('DOC / Filter / add to state')<AppliedFilter>()

export const removeFilterFromState = createAction('DOC / Filter / remove from state')<AppliedFilter>()

export const resetFilters = createAction('CMD / Filter / reset')()

export const resetFiltersState = createAction('DOC / Filter / reset state')()
