import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useOnMount from '../../hooks/useOnMount'

export type Props = {
    startAbort: Function
}

const Abort: FunctionComponent<Props> = (props) => {
    const { startAbort } = props
    const { t } = useTranslation()

    useOnMount(() => {
        startAbort()
    })

    return (
        <>
            <h1>
                {t('abort.heading')}
            </h1>
            <p>
                {t('abort.infoText')}
            </p>
        </>
    )
}

export default Abort
