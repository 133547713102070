import { createAction, createAsyncAction } from 'typesafe-actions'
import Cart from '../../../entities/Cart'
import { PriceViewFlag } from '../../../entities/PriceViewFlag'

export const fetchCart = createAction('CMD / Cart / fetch')()

export const fetchCartAsync = createAsyncAction(
    'EVT / Cart / fetch request',
    'EVT / Cart / fetch success',
    'EVT / Cart / fetch failure',
)<undefined, Cart, Error>()

export const putCart = createAction('CMD / Cart / put')<PriceViewFlag>()

export const putCartAsync = createAsyncAction(
    'EVT / Cart / put request',
    'EVT / Cart / put success',
    'EVT / Cart / put failure',
)<PriceViewFlag, Cart, Error>()

export const setCartState = createAction('DOC / Cart / set state')<Cart>()

export const resetCartState = createAction('DOC / Cart / reset state')()
