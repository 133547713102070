import React, { FunctionComponent } from 'react'
import style from './cornerRibbon.css'

export type Props = {
    isVisible: boolean
}

const CornerRibbon: FunctionComponent<Props> = (props) => {
    const { isVisible } = props

    if (!isVisible) {
        return null
    }

    return (
        <div className={style.container}>
            <span className={style.text}>Preview Mode</span>
        </div>
    )
}

export default CornerRibbon
