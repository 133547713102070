import { createSelector } from 'reselect'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'
import { API_URL_COMPARE_MODELS } from '../../../constants/apiUrls'

const modelComparisonResultDataIsPendingSelector= createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_COMPARE_MODELS),
)

export default modelComparisonResultDataIsPendingSelector
