import React, { FunctionComponent } from 'react'
import { stringifyUrl } from 'query-string'
import Media from '../../entities/Media'

export type Props = {
    media: Media[]
    alt: string
    className?: string
    width?: number
    height?: number
    backgroundColor?: string
}

const MediaImg: FunctionComponent<Props> = (props) => {
    const {
        media,
        alt,
        className,
        width,
        height,
        backgroundColor,
    } = props

    const imgMedia = media.find((mediaItem) => mediaItem.type === 'image')

    if (imgMedia === undefined) {
        return null
    }

    const query = {
        downsize: width !== undefined ? `${width}px:*` : null,
        'background-color': backgroundColor !== undefined ? backgroundColor : null,
    }

    const backgroundStyle = backgroundColor !== undefined ? { background: `#${backgroundColor}` } : {}

    const src = stringifyUrl({
        url: imgMedia.path,
        query,
    }, {
        skipNull: true,
    })

    return (
        <img
            src={src}
            alt={alt}
            style={backgroundStyle}
            className={className}
            width={width}
            height={height}
        />
    )
}

export default MediaImg
