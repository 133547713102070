import { Middleware } from 'redux'
import trackResetConfigurationMiddleware from './trackResetConfigurationMiddleware'
import trackSelectCarlineMiddleware from './trackSelectCarlineMiddleware'
import trackNavigationMiddleware from './trackNavigationMiddleware'
import trackConfigurationMiddleware from './trackConfigurationMiddleware'
import trackEquipmentsMiddleware from './trackEquipmentsMiddleware'
import trackCheckoutMiddleware from './trackCheckoutMiddleware'
import trackLoadConfigurationByVehicleCodeMiddleware from './trackLoadConfigurationByVehicleCodeMiddleware'
import trackVehicleCodeCreationMiddleware from './trackVehicleCodeCreationMiddleware'
import trackingPushMiddleware from './trackingPushMiddleware'
import trackPdfMiddleware from './trackPdfMiddleware'
import loadTrackingScriptMiddleware from './loadTrackingScriptMiddleware'

declare global {
    interface Window {
        digitalData
    }
}

const trackingMiddleware: Middleware[] = [
    loadTrackingScriptMiddleware,
    trackNavigationMiddleware,
    trackResetConfigurationMiddleware,
    trackSelectCarlineMiddleware,
    trackConfigurationMiddleware,
    trackEquipmentsMiddleware,
    trackCheckoutMiddleware,
    trackLoadConfigurationByVehicleCodeMiddleware,
    trackVehicleCodeCreationMiddleware,
    trackingPushMiddleware,
    trackPdfMiddleware,
]

export default trackingMiddleware
