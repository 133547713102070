import React, { FunctionComponent, useContext } from 'react'
import { Select } from '../form'
import style from './anchorNavigation.css'
import AnchorNavigationContext from './anchorNavigationContext'

type AnchorNavigationOption = {
    title: string
    hashtag: string
}

export type Props = {
    options: AnchorNavigationOption[]
    label?: string
}

const AnchorNavigation: FunctionComponent<Props> = (props) => {
    const { options, label } = props

    const { getTopMostVisibleAnchorId } = useContext(AnchorNavigationContext)

    const setHashtag = (value: string): void => {
        window.location.hash = value
    }

    const optionElements = options.map((option) => {
        const { title, hashtag } = option

        return <option key={hashtag} value={hashtag}>{title}</option>
    })

    const topMostVisibleAnchorId = getTopMostVisibleAnchorId()

    return (
        <label className={style.container}>
            <span className={style.label}>{label}</span>
            <Select
                onChange={(event) => setHashtag(event.target.value)}
                value={topMostVisibleAnchorId}
            >
                {optionElements}
            </Select>
        </label>
    )
}

export default AnchorNavigation
