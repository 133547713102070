import { MapStateToProps, connect } from 'react-redux'
import logoIsHiddenSelector from '../../redux/selectors/feature-scope/logoIsHiddenSelector'
import { Props } from './Logo'
import brandSelector from '../../redux/selectors/brandSelector'
import countrySelector from '../../redux/selectors/i18n/countrySelector'
import { resetConfiguration } from '../../redux/actions/app/configuration.actions'

type StateProps = Pick<Props, 'isHidden' | 'brand' | 'country'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isHidden: logoIsHiddenSelector(state),
    brand: brandSelector(state),
    country: countrySelector(state),
})

type DispatchProps = Pick<Props, 'resetConfiguration'>

const mapDispatchToProps: DispatchProps = {
    resetConfiguration,
}

export default connect(mapStateToProps, mapDispatchToProps)
