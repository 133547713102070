import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { fetchCartAsync } from '../../actions/app/cart.actions'
import carlinesSelector from '../../selectors/carlines/carlinesSelector'
import carlineGroupsSelector from '../../selectors/carlines/carlineGroupsSelector'
import modelsSelector from '../../selectors/models/modelsSelector'
import trackingSelector from '../../selectors/tracking/trackingSelector'
import { pushTrackingEvent, setTrackingState } from '../../actions/tracking/tracking.actions'
import getSelectedCarlineGroup from './helpers/getSelectedCarlineGroup'
import createModelTrackingObj from './helpers/createModelTrackingObj'
import brandSelector from '../../selectors/brandSelector'
import getManufacturer from './helpers/getManufacturer'
import totalPriceSelector from '../../selectors/totalPriceSelector'
import configurationDataSelector from '../../selectors/configuration/configurationDataSelector'
import { selectModel } from '../../actions/app/models.actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import cartRequestIsPendingSelector from '../../selectors/pending-request/cartRequestIsPendingSelector'
import equipmentRequestIsPendingSelector from '../../selectors/pending-request/equipmentRequestIsPendingSelector'
import currencySelector from '../../selectors/currencySelector'

const trackConfigurationMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(selectModel, action)) {
        const tracking = trackingSelector(store.getState())

        const updatedTracking = {
            ...tracking,
            modelSelectIsPending: true,
        }

        store.dispatch(setTrackingState(updatedTracking, {
            causedBy: action,
        }))
    }

    if (isActionOf([fetchCartAsync.success, fetchEquipmentGroupsAsync.success], action)) {
        const state = store.getState()
        const cartRequestIsPending = cartRequestIsPendingSelector(state)
        const equipmentRequestIsPending = equipmentRequestIsPendingSelector(state)

        if (cartRequestIsPending || equipmentRequestIsPending) {
            return
        }

        const configurationData = configurationDataSelector(state)
        const carlines = carlinesSelector(state)
        const selectedCarline = carlines.find((carline) => (
            carline.id === configurationData.carlineId
        ))
        const carlineGroups = carlineGroupsSelector(state)
        const selectedCarlineGroup = getSelectedCarlineGroup(carlineGroups, selectedCarline)

        if (configurationData.modelId) {
            const tracking = trackingSelector(state)
            const models = modelsSelector(state)
            const configuredModel = models.find((model) => (
                model.id === configurationData.modelId
            ))

            if (
                configuredModel === undefined
                || selectedCarline === undefined
                || selectedCarlineGroup === undefined
            ) {
                return
            }

            const updatedTracking = {
                ...tracking,
            }

            const brand = brandSelector(store.getState())
            const currency = currencySelector(store.getState())
            const manufacturer = getManufacturer(brand)
            const totalPrice = totalPriceSelector(state)

            // eslint-disable-next-line max-len
            const configurationStartType = tracking.products.configuredVehicle[0].model === undefined
                ? 'engine change'
                : tracking.products.configuredVehicle[0].model.attributes.configurationStartType

            const modelObj = createModelTrackingObj(
                configuredModel,
                selectedCarlineGroup,
                configurationStartType,
                manufacturer,
                totalPrice,
                currency,
            )

            updatedTracking.products = {
                ...updatedTracking.products,
                configuredVehicle: [
                    {
                        ...updatedTracking.products.configuredVehicle[0],
                        model: modelObj,
                    },
                ],
            }

            store.dispatch(setTrackingState(updatedTracking, {
                causedBy: action,
            }))

            if (tracking.modelSelectIsPending) {
                const updatedTracking2 = {
                    ...updatedTracking,
                }

                const modelObj2 = createModelTrackingObj(
                    configuredModel,
                    selectedCarlineGroup,
                    'engine change',
                    manufacturer,
                    totalPrice,
                    currency,
                )

                delete updatedTracking2.modelSelectIsPending

                updatedTracking2.products = {
                    ...updatedTracking2.products,
                    configuredVehicle: [
                        {
                            ...updatedTracking2.products.configuredVehicle[0],
                            model: modelObj2,
                        },
                    ],
                }

                store.dispatch(setTrackingState(updatedTracking2, {
                    causedBy: action,
                }))

                const dataLayerEvent = {
                    eventInfo: {
                        eventAction: 'configuration',
                        eventName: 'dcc select engine',
                    },
                    attributes: {
                        componentName: 'modelsTable',
                        label: configuredModel.name,
                        currentURL: window.location.href,
                        targetURL: '',
                        clickID: '',
                        elementName: 'other',
                        value: '',
                        pos: '',
                    },
                }

                store.dispatch(pushTrackingEvent(dataLayerEvent))
            }
        }
    }
}

export default trackConfigurationMiddleware
