import { createSelector } from 'reselect'
import countrySelector from './i18n/countrySelector'
import config from '../../config'

const miwiClientInformationSelector = createSelector(
    countrySelector,
    (country): string => `dcc-app-${country};${config.APP_VERSION};live`,
)

export default miwiClientInformationSelector
