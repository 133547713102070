import { createSelector } from 'reselect'
import cartSelector from './cartSelector'
import Prices from '../../../entities/Prices'

const cartPricesSelector = createSelector(
    cartSelector,
    (cart): Prices => cart.prices,
)

export default cartPricesSelector
