const removeNullFields = (obj: any): any => {
    if (typeof obj === 'object' && obj !== null) {
        if (Array.isArray(obj)) {
            return obj.map((item) => removeNullFields(item))
        }

        const newObj: Record<string, any> = {}

        Object.keys(obj).forEach((key) => {
            if (obj[key] !== null && obj[key] !== undefined) {
                newObj[key] = removeNullFields(obj[key])
            }
        })
        return newObj
    }

    return obj
}

export default removeNullFields
