import { connect, MapStateToProps } from 'react-redux'
import modelFilterOptionsSelector from '../../redux/selectors/models/modelFilterOptionsSelector'
import { applyFilter } from '../../redux/actions/app/filter.actions'
import { Props } from '../attribute-filters'

type StateProps = Pick<Props, 'filterOptions'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    filterOptions: modelFilterOptionsSelector(state),
})

type DispatchProps = Pick<Props, 'applyFilter'>

const mapDispatchToProps: DispatchProps = {
    applyFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)
