import { createReducer } from 'typesafe-actions'
import { setTrackingState } from '../actions/tracking/tracking.actions'

const trackingDefaultState = {
    products: {
        configuredVehicle: [
            {
                carParts: [],
            },
        ],
    },
}

const trackingReducer = createReducer(trackingDefaultState)
    .handleAction(setTrackingState, (state, action) => action.payload)

export default trackingReducer
