import { createReducer } from 'typesafe-actions'
import {
    resetComparisonResultData,
    setComparisonResultData,
} from '../actions/app/comparisonResultData.actions'

const comparisonResultDataReducer = createReducer({})
    .handleAction(setComparisonResultData, (state, action) => action.payload)
    .handleAction(resetComparisonResultData, (() => {
        return {}
    }))

export default comparisonResultDataReducer
