import { applyMiddleware, compose, createStore } from 'redux'
import middleware from './middleware'
import rootReducer from './reducers/rootReducer'
import applyDevtoolExtension from './applyDevtoolExtension'
import { getPersistedState } from './persistState'

const enhancer = compose(
    applyMiddleware(...middleware),
    applyDevtoolExtension(),
)

const persistedState = getPersistedState()

export default createStore(rootReducer, persistedState, enhancer)
