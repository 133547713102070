import { createSelector } from 'reselect'
import { getType } from 'typesafe-actions'
import failedRequestsSelector from './failedRequestsSelector'
import { fetchEntryDataAsync, fetchEntryData } from '../actions/app/entryData.actions'

const entryDataRequestHasFailedSelector = createSelector(
    failedRequestsSelector,
    (failedRequests): boolean => failedRequests.some((
        (failedRequest) => (failedRequest.type === getType(fetchEntryDataAsync.request)
                || failedRequest.type === getType(fetchEntryData))
    )),
)

export default entryDataRequestHasFailedSelector
