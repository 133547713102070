import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    loadTrackingScriptAsync,
    setTrackingIsReadyState,
} from '../../actions/tracking/tracking.actions'
import { init } from '../../actions/core/init.actions'
import config from '../../../config'

const loadTrackingScriptMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(init, action)) {
        store.dispatch(loadTrackingScriptAsync.request(undefined, undefined))
    }

    if (isActionOf(loadTrackingScriptAsync.request, action)) {
        if (config.TRACKING_URL === undefined) {
            store.dispatch(loadTrackingScriptAsync.failure(undefined, undefined))
            console.error('No Tracking URL provided. Tracking is disabled')
            return
        }

        const trackingScript = document.createElement('script')

        trackingScript.onload = () => {
            store.dispatch(loadTrackingScriptAsync.success(undefined, undefined))
        }

        trackingScript.onerror = () => {
            store.dispatch(loadTrackingScriptAsync.failure(undefined, undefined))
        }

        trackingScript.src = config.TRACKING_URL

        document.head.append(trackingScript)
    }

    if (
        isActionOf(loadTrackingScriptAsync.success, action)
        || isActionOf(loadTrackingScriptAsync.failure, action)
    ) {
        store.dispatch(setTrackingIsReadyState(true))
    }
}

export default loadTrackingScriptMiddleware
