import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { getEfficiencyDataApiUrl } from '../../../constants/apiUrls'
import {
    fetchEfficiencyData,
    fetchEfficiencyDataAsync,
    setEfficiencyDataState,
} from '../../actions/app/efficiencyData.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'
import isMiwiEnabledSelector from '../../selectors/feature-scope/isMiwiEnabledSelector'
import { ApiRequest } from '../../apiRequest'

// eslint-disable-next-line max-len
const efficiencyDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchVisualizationAsync.request, action)) {
        const isMiwiEnabled = isMiwiEnabledSelector(getState())

        if (isMiwiEnabled) {
            dispatch(fetchEfficiencyData())
        }
    }

    if (isActionOf(fetchEfficiencyData, action)) {
        apiRequest({
            options: {
                url: getEfficiencyDataApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchEfficiencyDataAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchEfficiencyDataAsync.success, action)) {
        const efficiencyData = action.payload

        dispatch(setEfficiencyDataState(efficiencyData))
    }
}

export default efficiencyDataMiddleware
