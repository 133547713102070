import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CarlineGridView'
import filtersAreVisibleSelector from '../../redux/selectors/filtersAreVisibleSelector'
import { toggleFiltersAreVisible } from '../../redux/actions/app/carlineSelection.actions'

type StateProps = Pick<Props, 'filtersAreVisible'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    filtersAreVisible: filtersAreVisibleSelector(state),
})

type DispatchProps = Pick<Props, 'toggleFiltersAreVisible'>

const mapDispatchToProps: DispatchProps = {
    toggleFiltersAreVisible,
}

export default connect(mapStateToProps, mapDispatchToProps)
