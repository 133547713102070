import { connect, MapStateToProps } from 'react-redux'
import { Props } from './DocumentTitle'
import activeNavLinkTitleSelector from '../../redux/selectors/activeNavLinkTitleSelector'

type StateProps = Pick<Props, 'activeNavLinkTitle'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    activeNavLinkTitle: activeNavLinkTitleSelector(state),
})

export default connect(mapStateToProps)
