import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import i18n from '../../../i18n/i18n'
import { pushTrackingEvent, setTrackingState } from '../../actions/tracking/tracking.actions'
import trackingSelector from '../../selectors/tracking/trackingSelector'
import { resetConfiguration } from '../../actions/app/configuration.actions'

const trackResetConfigurationMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(resetConfiguration, action)) {
        const tracking = trackingSelector(store.getState())
        const updatedTracking = {
            ...tracking,
        }

        updatedTracking.products = {
            ...updatedTracking.products,
            configuredVehicle: [
                {
                    carParts: [],
                },
            ],
        }

        store.dispatch(setTrackingState(updatedTracking, {
            causedBy: action,
        }))

        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'configuration',
                eventName: 'dcc reset configuration',
            },
            attributes: {
                componentName: 'resetConfigurationButton',
                label: i18n.t('configuration.reset'),
                currentURL: window.location.href,
                targetURL: '',
                clickID: '',
                elementName: 'button',
                value: '',
                pos: '',
            },
        }

        store.dispatch(pushTrackingEvent(dataLayerEvent))
    }
}

export default trackResetConfigurationMiddleware
