import { connect, MapStateToProps } from 'react-redux'
import { Props } from './EnterVehicleCodeButton'
import brandSelector from '../../redux/selectors/brandSelector'

type StateProps = Pick<Props, 'brand'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    brand: brandSelector(state),
})

export default connect(mapStateToProps)
