import { createSelector } from 'reselect'
import foresightedBuildabilityCheckIsDisabledSelector
    from './foresightedBuildabilityCheckIsDisabledSelector'
import hasConfigurationConflictSelector from '../configuration/hasConfigurationConflictSelector'

const conflictModalIsDisabledSelector = createSelector(
    hasConfigurationConflictSelector,
    foresightedBuildabilityCheckIsDisabledSelector,
    (
        foresightedBuildabilityCheckIsDisabled,
        hasConfigurationConflict,
    ): boolean => !foresightedBuildabilityCheckIsDisabled && hasConfigurationConflict,
)

export default conflictModalIsDisabledSelector
