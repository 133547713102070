import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useOnMount from '../../hooks/useOnMount'
import style from './checkout.css'

export type Props = {
    checkoutIsEnabled: boolean
    startCheckout: Function
}

const Checkout: FunctionComponent<Props> = (props) => {
    const { checkoutIsEnabled, startCheckout } = props
    const { t } = useTranslation()

    useOnMount(() => {
        if (checkoutIsEnabled) {
            startCheckout()
        }
    })

    return (
        <div className={style.container} data-cy="carline-group-list-gridview">
            <section className={style.headerContainer}>
                <h1>
                    {t('checkout.heading')}
                </h1>

                <p>
                    {t('checkout.infoText')}
                </p>
            </section>
        </div>
    )
}

export default Checkout
