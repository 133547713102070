import {Markup} from 'interweave'
import React, {FunctionComponent} from 'react'
import {useDispatch} from 'react-redux'
import AudiExclusiveEditButtonIcon from '../../assets/media/icons/audi-exclusive-edit.svg'
import AudiExclusiveDataState from '../../entities/AudiExclusiveDataState'
import Equipment from '../../entities/Equipment'
import Visualization from '../../entities/Visualization'
import useTranslation from '../../hooks/useTranslation'
import {
    setAudiExclusiveDataState,
} from '../../redux/actions/app/audiExclusiveApp.actions'
import {Checkbox} from '../form'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import MediaImg from '../media-img'
import NoteTable from '../note-table'
import NumberSelect from '../number-select/NumberSelect'
import {Td, Tr} from '../table'
import ConflictResolutionLabel from './ConflictResolutionLabel'
import {EquipmentViewVariant} from './EquipmentTable'
import style from './equipmentTableRow.css'
import LockIcon from '../../assets/media/icons/lock.svg'

const isAudiExclusiveContentSupported = (
    equipment: Equipment,
): boolean =>
    equipment.isAudiExclusive &&
    equipment.selected &&
    !new RegExp('^Designpaket\\\\s+.*?Audi\\\\s+exclusive$').test(equipment.name)

const editAUdiExclusiveEquipmentButtonClickHandler = (
    dispatch,
    equipment,
    language,
) => {

    const audiExclusiveAppDataState: AudiExclusiveDataState = {
        audiExclusiveSelectedEquipment: equipment,
        shouldOpenAudiExclusiveApp: true,
        language: language,
    }

    dispatch(setAudiExclusiveDataState(audiExclusiveAppDataState))
}

export type Props = {
    selectEquipment: Function
    deselectEquipment: Function
    openInfoModal: Function
    equipment: Equipment
    viewVariant: EquipmentViewVariant
    priceColWidth?: string
    visualization: Visualization
    language?: string
}

const EquipmentTableRow: FunctionComponent<Props> = (props) => {
    const {
        equipment,
        deselectEquipment,
        selectEquipment,
        openInfoModal,
        viewVariant,
        priceColWidth,
        language,
    } = props
    const {
        id,
        name,
        media,
        price,
        selected,
        stealth,
        isStandard,
        conflicted,
        selectable,
        replacedBy,
        evaKey,
        hidden,
        forced,
        content,
        validPeriod,
        note,
        count,
        minCount,
        maxCount,
        unitPrice,
    } = equipment

    const {t} = useTranslation()
    const dispatch = useDispatch()

    if (hidden || stealth) {
        return null
    }

    const hasInfo = (media && media.length > 0) || (content && content.length > 0)
    const infoIsForced = media && media.find((mediaItem) => mediaItem.forced) !== undefined

    const openDiscountModal = (): void => {
        const tabs = [
            {
                id,
                label: name,
                content: <NoteTable data={note} key={id}/>,
            },
        ]

        openInfoModal({
            title: name,
            wheelEfficiencyLabelIsEnabled: false,
            media: [],
            tabs,
        })
    }

    const openModal =
        (activeMedia: string | null = null): Function =>
            () =>
                openInfoModal({
                    title: name,
                    media,
                    activeMedia,
                    content,
                })

    const changeHandler = (): void => {
        if (selected) {
            deselectEquipment(id)
        } else {
            const infoModal = infoIsForced
                ? {
                    title: name,
                    media,
                    content,
                }
                : undefined

            selectEquipment(id, {infoModal})
        }
    }

    const changeAmountHandler = (newAmount: number): void => {
        selectEquipment(`${id}*${newAmount}`, undefined)
    }

    const editAudiExclusiveButton =
        isAudiExclusiveContentSupported(
            equipment,
        ) ? (
            <button
                onClick={() =>
                    editAUdiExclusiveEquipmentButtonClickHandler(
                        dispatch,
                        equipment,
                        language,
                    )
                }
                title={t('overviewSelection.editButtonLabel')}
                className={style.optionBtn}
                type="button"
                data-cy={`edit-button-${id.replace(/\\/g, '.')}`}>
                <AudiExclusiveEditButtonIcon className={style.icon}/>
            </button>
        ) : null

    const labelClassName = [
        conflicted && viewVariant !== 'conflict' && viewVariant !== 'model_conflict' ? style.labelIsConflictual : '',
        replacedBy && replacedBy !== '' ? style.labelIsReplaced : '',
        style.label,
    ].join(' ')

    let infoButtonElement = null

    if (validPeriod) {
        infoButtonElement = (
            <InfoButton openModal={openDiscountModal} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                <InfoI isForced={infoIsForced}/>
            </InfoButton>
        )
    } else if (hasInfo) {
        infoButtonElement = (
            <InfoButton openModal={openModal()} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                <InfoI isForced={infoIsForced}/>
            </InfoButton>
        )
    }

    const imgElement = media && <MediaImg alt={name} media={media} width={100}/>
    const imgInfoButtonElement =
        imgElement !== null ? (
            <InfoButton openModal={openModal('image')} dataCy={`info-img-button-${id.replace(/\\/g, '.')}`}>
                {imgElement}
            </InfoButton>
        ) : null

    const checkboxId = `checkbox-${viewVariant}-${id}`
    const hasCheckbox = viewVariant !== 'model_conflict'
    const labelFor = hasCheckbox ? checkboxId : null
    const checkbox = hasCheckbox ? (
        <Checkbox
            id={checkboxId}
            name="equipment"
            onChange={changeHandler}
            checked={selected}
            disabled={!selectable}
            className={style.checkbox}
            data-cy={`equipment-select-${id.replace(/\\/g, '.')}`}
        />
    ) : null

    const priceElement = isStandard ? t('standard-equipment-price') : <Markup content={price} noWrap/>

    const priceColStyle = {
        minWidth: priceColWidth,
    }

    const lockIconElement = forced ? <LockIcon className={style.lockIcon}/> : null

    const nameColumnClass = validPeriod ? style.discountNameColInner : style.nameColInner

    let itemAmountBlockComponent = null

    if (count && minCount && maxCount && maxCount > 1) {
        const unitPriceLabel = t('accessory.itemPrice')

        itemAmountBlockComponent = (
            <div className={style.amountWrapper}>
                <div className={style.itemPriceWrapper}>
                    {unitPriceLabel}
                    <span className={style.itemPrice}>{unitPrice}</span>
                </div>
                <NumberSelect
                    onChange={changeAmountHandler}
                    value={count}
                    minValue={minCount}
                    maxValue={maxCount}
                    key={`${id}.gty`}
                    enable={selected}
                />
            </div>
        )
    }

    return (
        <Tr key={id}>
            <Td className={style.nameCol}>
                <div id={id} className="anchor"/>
                <div className={nameColumnClass}>
                    <span>{checkbox}</span>
                    <span>
                        <label className={labelClassName} htmlFor={labelFor}>
                            <ConflictResolutionLabel viewVariant={viewVariant} selected={selected}/>
                            <span>
                              {lockIconElement}
                                <Markup content={name}/>
                            </span>
                            <span className={style.displayId}>{evaKey}</span>
                        </label>
                    </span>
                    <span className={style.infoButtonWrapper}>
                        {imgInfoButtonElement}
                        {validPeriod}
                    </span>
                </div>
            </Td>
            <Td className={style.priceCol} style={priceColStyle}>
                {itemAmountBlockComponent}
                <div className={style.priceContainer}>{priceElement}</div>
                <div className={style.optionsWrapper}>
                    {editAudiExclusiveButton}
                    {infoButtonElement}
                </div>
            </Td>
            <Td className={style.optionCol}/>
        </Tr>
    )
}

export default EquipmentTableRow
