import { connect, MapStateToProps } from 'react-redux'
import configurationIsBuildableSelector from '../../redux/selectors/configuration/configurationIsBuildableSelector'
import { createPdf } from '../../redux/actions/app/pdf.actions'
import { Props } from './PdfButton'
import pdfRequestIsPendingSelector from '../../redux/selectors/pending-request/pdfRequestIsPendingSelector'
import isPdfButtonVisibleSelector from '../../redux/selectors/feature-scope/isPdfButtonVisibleSelector'

type StateProps = Pick<Props, 'configurationIsBuildable' | 'requestIsPending' | 'isVisible'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    configurationIsBuildable: configurationIsBuildableSelector(state),
    requestIsPending: pdfRequestIsPendingSelector(state),
    isVisible: isPdfButtonVisibleSelector(state),
})

type DispatchProps = Pick<Props, 'createPdf'>

const mapDispatchToProps: DispatchProps = {
    createPdf,
}

export default connect(mapStateToProps, mapDispatchToProps)
