import { connect, MapStateToProps } from 'react-redux'
import { loadConfigurationByVehicleCode } from '../../redux/actions/app/vehicleCode.actions'
import { Props } from './EnterVehicleCodeModal'
import brandSelector from '../../redux/selectors/brandSelector'

type StateProps = Pick<Props, 'brand'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    brand: brandSelector(state),
})

type DispatchProps = Pick<Props, 'submitVehicleCode'>

const mapDispatchToProps: DispatchProps = {
    submitVehicleCode: loadConfigurationByVehicleCode,
}

export default connect(mapStateToProps, mapDispatchToProps)
