import { Middleware } from 'redux'
import { Action, getType } from 'typesafe-actions'
import { fetchCarlineData } from '../../actions/app/carlineData.actions'
import { fetchCart } from '../../actions/app/cart.actions'
import { fetchEfficiencyData } from '../../actions/app/efficiencyData.actions'
import { fetchEntryData } from '../../actions/app/entryData.actions'
import { fetchEquipmentGroups } from '../../actions/app/equipmentGroups.actions'
import {
    addFailedRequestActionToState,
    removeFailedRequestActionFromState,
} from '../../actions/app/failedRequests.actions'
import { createNotification } from '../../actions/app/notification.actions'
import { resetConfigurationProcess } from '../../actions/app/reset.actions'
import { loadConfigurationByVehicleCode } from '../../actions/app/vehicleCode.actions'
import { fetchVisualization } from '../../actions/app/visualization.actions'
import { isApiFailureAction, isApiRequestAction } from '../../apiRequest'
import failedRequestsSelector from '../../selectors/failedRequestsSelector'

const SESSION_EXPIRED = 'SESSION_EXPIRED'
const SESSION_COOKIE_MISSING = 'SESSION_COOKIE_MISSING'
const VEHICLE_CODE_NOT_FOUND = 'VEHICLE_CODE_NOT_FOUND'

// because of back-end endpoint which is void => it has empty response body
const UNEXPECTED_END_OF_JSON_INPUT1 = 'Unexpected end of JSON input'
const UNEXPECTED_END_OF_JSON_INPUT2 = 'unexpected end of data at line 1 column 1 of the JSON data'

const errorMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    const getNotificationMessage = (type: string, message: string, causedBy: Action): string => {
        if (message === SESSION_EXPIRED || message === SESSION_COOKIE_MISSING) {
            return 'error.sessionExpired'
        }

        if (causedBy.type === getType(loadConfigurationByVehicleCode)) {
            if (message === VEHICLE_CODE_NOT_FOUND) {
                return 'vehicleCode.audi.notFound'
            }
        }

        if (type === 'fetch') {
            return 'error.fetch'
        }

        return 'error.general'
    }

    if (isApiRequestAction(action)) {
        const { getState, dispatch } = store
        const { causedBy } = action.meta
        const failedRequests = failedRequestsSelector(getState())

        if (failedRequests.some((failedRequest) => failedRequest.type === causedBy.type)) {
            dispatch(removeFailedRequestActionFromState(causedBy))
        }
    }

    if (isApiFailureAction(action)) {
        const { dispatch } = store
        const { type, message } = action.payload
        const { causedBy } = action.meta

        const isResponseBodyEmpty =
            message === UNEXPECTED_END_OF_JSON_INPUT1 || message === UNEXPECTED_END_OF_JSON_INPUT2

        if (!isResponseBodyEmpty) {
            dispatch(
                createNotification({
                    type: 'error',
                    message: getNotificationMessage(type, message, causedBy),
                }),
            )
        }

        if (message === SESSION_EXPIRED || message === SESSION_COOKIE_MISSING) {
            dispatch(resetConfigurationProcess())
            dispatch(fetchEntryData())
        }

        const requestsToRetry: string[] = [
            fetchEntryData,
            fetchCarlineData,
            fetchEfficiencyData,
            fetchVisualization,
            fetchCart,
            fetchEquipmentGroups,
        ].map((requestToRetry) => getType(requestToRetry))

        if (requestsToRetry.includes(causedBy.type)) {
            dispatch(
                addFailedRequestActionToState({
                    type: causedBy.type,
                    error: action?.payload,
                }),
            )
        }
    }
}

export default errorMiddleware
