import React, { FormEventHandler, FunctionComponent, useState } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { Input } from '../form'
import Modal, { ModalClosingButton } from '../modal'
import style from './enterVehicleCodeModal.css'

export type Props = {
    submitVehicleCode: Function
    activeState: [boolean, (isActive: boolean) => void]
    brand: string
}

const EnterVehicleCodeModal: FunctionComponent<Props> = (props) => {
    const { submitVehicleCode, activeState, brand } = props
    const { t } = useTranslation()
    const [vehicleCode, setVehicleCode] = useState('')
    const [modalIsActive, setModalIsActive] = activeState

    const vehicleCodeChange = (value: string): void => {
        setVehicleCode(value.toUpperCase().trim())
    }

    const closeModal = (): void => {
        setModalIsActive(false)
    }

    const closeButtonClickHandler = (): void => {
        setVehicleCode('')
        closeModal()
    }

    const submitHandler: FormEventHandler = (event) => {
        event.preventDefault()

        submitVehicleCode(vehicleCode)
        setVehicleCode('')
        closeModal()
    }

    return (
        <Modal isVisible={modalIsActive} onClose={closeModal}>
            <ModalClosingButton onClick={closeButtonClickHandler} />
            <div className={style.container}>
                <h2 className={style.title}>{t(`vehicleCode.${brand}.enter.title`)}</h2>
                <form onSubmit={submitHandler}>
                    <Input
                        value={vehicleCode}
                        onChange={(event) => vehicleCodeChange(event.target.value)}
                        className={style.input}
                        placeholder={t(`vehicleCode.${brand}.enter.placeholder`)}
                        required
                        minLength={8}
                        data-cy="input-vehicle-code"
                    />
                    <button className={style.button} type="submit" data-cy="submit-vehicle-code">
                        {t(`vehicleCode.${brand}.enter.submitButtonLabel`)}
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default EnterVehicleCodeModal
