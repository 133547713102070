import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchCarlineData,
    fetchCarlineDataAsync,
    setCarineDataState,
} from '../../actions/app/carlineData.actions'
import { getCarlinesApiUrl } from '../../../constants/apiUrls'
import { ApiRequest } from '../../apiRequest'

// eslint-disable-next-line max-len
const carlineDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchCarlineData, action)) {
        apiRequest({
            options: {
                url: getCarlinesApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchCarlineDataAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchCarlineDataAsync.success, action)) {
        const carlineData = action.payload

        dispatch(setCarineDataState(carlineData))
    }
}

export default carlineDataMiddleware
