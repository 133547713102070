import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { getConflictSolutionResolveUrl } from '../../../constants/apiUrls'
import { putConfigurationAsync } from '../../actions/app/configuration.actions'
import {
    acceptRescueConflictSolution,
    adoptConflictSolution,
    cancelConflictSolution,
    dontAdoptConflictSolution,
} from '../../actions/app/conflictSolution.actions'
import { ApiRequest } from '../../apiRequest'

// eslint-disable-next-line max-len
const conflictSolutionMiddleware =
    (apiRequest: ApiRequest): Middleware =>
    (store) =>
    (next) =>
    (action) => {
        next(action)
        const { dispatch } = store

        if (isActionOf(adoptConflictSolution, action) || isActionOf(acceptRescueConflictSolution, action)) {
            apiRequest(
                {
                    options: {
                        url: getConflictSolutionResolveUrl('ACCEPT'),
                        method: 'PATCH',
                    },
                    asyncActions: putConfigurationAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(dontAdoptConflictSolution, action)) {
            apiRequest(
                {
                    options: {
                        url: getConflictSolutionResolveUrl('REJECT'),
                        method: 'PATCH',
                    },
                    asyncActions: putConfigurationAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(cancelConflictSolution, action)) {
            apiRequest(
                {
                    options: {
                        url: getConflictSolutionResolveUrl('CANCEL'),
                        method: 'PATCH',
                    },
                    asyncActions: putConfigurationAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }
    }

export default conflictSolutionMiddleware
