import React, { FunctionComponent, PropsWithChildren } from 'react'
import { CarlineId } from '../../entities/Carline'
import style from './selectCarlineButton.css'

export type Props = PropsWithChildren<{
    id: CarlineId
    selectCarline: (id: CarlineId, { forwardToModelPage }: { forwardToModelPage: boolean }) => void
}>

const SelectCarlineButton: FunctionComponent<Props> = (props) => {
    const { id, selectCarline, children } = props

    const clickHandler = (): void => {
        selectCarline(id, {
            forwardToModelPage: true,
        })
    }

    return (
        <button
            onClick={clickHandler}
            className={style.button}
            type="button"
            data-cy={`select-carline-button-${id.replace(/\\/g, '.')}`}
        >
            {children}
        </button>
    )
}

export default SelectCarlineButton
