import { createSelector } from 'reselect'
import RenderImage from '../../entities/RenderImage'
import visualizationSelector from './visualizationSelector'

const renderImagesSelector = createSelector(
    visualizationSelector,
    (visualization): RenderImage[] => visualization.renderImages,
)

export default renderImagesSelector
