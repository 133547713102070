import React from 'react'
import { Icon, SpinnerCircle } from './spinner.styles'

export type SpinnerProps = {
    height?: string
    width?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ height = '48px', width = '48px' }) => {
    return (
        <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={height} width={width}>
            <circle cx="24" cy="24" r="22" fill="none" strokeWidth="1" stroke="rgba(0, 0, 0, .6)" />
            <SpinnerCircle cx="24" cy="24" r="22" fill="none" strokeWidth="3" strokeDasharray="69, 69" />
        </Icon>
    )
}
