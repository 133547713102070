import React, { FunctionComponent } from 'react'
import style from './equipmentFamiliesTables.css'
import EquipmentFamily from '../../entities/EquipmentFamily'
import EquipmentTable from '../equipment-table'
import Anchor from '../anchor'
import InfoI from '../info-i'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    equipmentFamilies: EquipmentFamily[]
    wheelEfficiencyLabelIsEnabled: boolean
    longestPrice: number
}

const EquipmentFamiliesTables: FunctionComponent<Props> = (props) => {
    const { equipmentFamilies, wheelEfficiencyLabelIsEnabled, longestPrice } = props
    const { t } = useTranslation()

    const equipmentFamiliesTableElements = equipmentFamilies.map((equipmentFamily) => {
        const {
            id,
            name,
            headlineNote,
            equipments,
        } = equipmentFamily

        if (!equipments) {
            return []
        }

        const visibleEquipments = equipments.filter(({ hidden }) => !hidden)

        const disclaimerIsActive = wheelEfficiencyLabelIsEnabled && headlineNote === 'hasWheelInfo'
        const hasWheelDisclaimer = disclaimerIsActive ? (
            <p className={style.disclaimer}>
                <small>
                    {t('wheelEfficiencyLabel.equipmentFamilyDisclaimer')}
                </small>
                <InfoI isForced={false} />
            </p>
        ) : null

        return visibleEquipments.length > 0 ? (
            <Anchor key={id} id={id}>
                <section>
                    <header className={style.header}>
                        <h2 className={style.title}>{name}</h2>
                        {hasWheelDisclaimer}
                    </header>
                    <EquipmentTable
                        equipments={equipments}
                        viewVariant="standard"
                        priceColWidth={`${longestPrice}ch`}
                    />
                </section>
            </Anchor>
        ) : null
    })

    return (
        <>
            {equipmentFamiliesTableElements}
        </>
    )
}

export default EquipmentFamiliesTables
