import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import ModelFilters from '../model-filters'
import ModelsTable from '../models-table'
import Cartbox from '../cartbox'
import ResetCarlineSelection from '../reset-carlines-selection'
import style from './modelSelection.css'

export type Props = {
    modelsLoaded: boolean
    carlineTitle: string
    isLoading: boolean
}

const ModelSelection: FunctionComponent<Props> = (props) => {
    const {
        carlineTitle,
        isLoading,
    } = props
    const { t } = useTranslation()

    return (
        <div className="contentWithCartboxContainer">
            <div className="contentWithCartbox">
                <h1>
                    {t('modelSelection.heading')}
                </h1>
                <LoadingIndicatorWrapper isLoading={isLoading}>
                    <ResetCarlineSelection />
                    <ModelFilters isLoading={isLoading} layout="horizontal" />
                    <h2 className={style.carlineTitle}>{carlineTitle}</h2>
                    <ModelsTable isLoading={isLoading} />
                </LoadingIndicatorWrapper>
            </div>
            <Cartbox />
        </div>
    )
}

export default ModelSelection
