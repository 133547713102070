import { createSelector } from 'reselect'
import cartSelector from './cartSelector'
import { CartEmissionConsumptions } from '../../../entities/CartEmissionConsumptions'

const cartAdditionalInformationSelector = createSelector(
    cartSelector,
    (cart): CartEmissionConsumptions => cart.additionalInformation,
)

export default cartAdditionalInformationSelector
