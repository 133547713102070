import { AudiExclusivePart } from '../../../../entities/AudiExclusivePart'
import { AudiExclusiveSelection } from '../../../../entities/AudiExclusiveSelection'

export const getConvertedAudiExclusiveSelection = (data: any): AudiExclusiveSelection => {
    const prString = data.prString
    const selection: { [key: string]: { [key: string]: AudiExclusivePart } } = {}

    for (const key in data.selection) {
        if (Object.prototype.hasOwnProperty.call(data.selection, key)) {
            selection[key] = {}

            for (const innerKey in data.selection[key]) {
                if (Object.prototype.hasOwnProperty.call(data.selection[key], innerKey)) {
                    const part = data.selection[key][innerKey]
                    const ids = innerKey.split(',')
                    ids.forEach(id => {
                        selection[key][id.trim()] = {
                            dccText: part.dcc_text_en,
                            aeColor: part.ae_color,
                            id: part.id,
                            imageUrl: part.image_url,
                            prValue: part.prValue,
                            matGroup: part.mat_group,
                            abiText: part.abi_text,
                            color: part.color_en,
                            prRule: part.pr_rule,
                        }
                    })
                }
            }
        }
    }

    return { aveString: prString, selection }
}
export const getAudiExclusiveSelectionWithEmptySelections = (aveString: string): AudiExclusiveSelection => ({
    aveString,
    selection: {},
})

export default {
    getConvertedAudiExclusiveSelection,
    getAveStringWithEmptySelection: getAudiExclusiveSelectionWithEmptySelections,
}
