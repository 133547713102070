import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import CarlineGroup, { DisplayMode } from '../../entities/CarlineGroup'
import CarlineGridView from '../carline-grid-view'
import CarlineListView from '../carline-list-view'
import CarlineSelectionToggleButton from './CarlineSelectionToggleButton'
import style from './carlineSelection.css'
import EnterVehicleCodeButton from '../enter-vehicle-code-button'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import BuildabilitySwitcherButton from '../buildability-switcher'

export type Props = {
    carlineGroups: CarlineGroup[]
    displayMode: DisplayMode
    setDisplayMode: (displayMode: DisplayMode) => void
    isLoading: boolean
    displayBuildabilitySwitcher: boolean
}

const CarlineSelection: FunctionComponent<Props> = (props) => {
    const {
        carlineGroups,
        displayMode,
        setDisplayMode,
        isLoading,
        displayBuildabilitySwitcher,
    } = props
    const { t } = useTranslation()

    const carlineGroupsElement = displayMode === 'list'
        ? <CarlineListView carlineGroups={carlineGroups} isLoading={isLoading} />
        : <CarlineGridView carlineGroups={carlineGroups} isLoading={isLoading} />

    return (
        <div className={style.container} data-cy="carline-group-list-gridview">
            <section className={style.headerContainer}>
                <h1 className={style.title}>
                    {t('carlineSelection.heading')}
                </h1>
                <ul className={style.optionList}>
                    {displayBuildabilitySwitcher &&
                    <li className={style.optionListItem}>
                        <BuildabilitySwitcherButton />
                    </li>}
                    <li className={style.optionListItem}>
                        <CarlineSelectionToggleButton
                            displayMode={displayMode}
                            setDisplayMode={setDisplayMode}
                        />
                    </li>
                    <li className={style.optionListItem}>
                        <EnterVehicleCodeButton />
                    </li>
                </ul>
            </section>
            <LoadingIndicatorWrapper isLoading={isLoading} className={style.loadingIndicator}>
                {carlineGroupsElement}
            </LoadingIndicatorWrapper>
        </div>
    )
}

export default CarlineSelection
