import { createSelector } from 'reselect'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import urlifyGroupName from '../../../helpers/urlifyGroupName'
import filteredEquipmentGroupsSelector from './filteredEquipmentGroupsSelector'
import currentPathnameSelector from '../router/currentPathnameSelector'

const currentEquipmentGroupSelector = createSelector(
    currentPathnameSelector,
    filteredEquipmentGroupsSelector,
    (pathname, equipmentGroups): EquipmentGroup | undefined => {
        const currentEquipmentGroup = equipmentGroups
            .find((equipmentGroup) => (urlifyGroupName(equipmentGroup.id) === pathname))

        return currentEquipmentGroup
    },
)

export default currentEquipmentGroupSelector
