import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { startAbort, abortRedirect, configurationIsResetForAbort } from '../../actions/app/abort.actions'
import { resetConfiguration } from '../../actions/app/configuration.actions'
import abortUrlSelector from '../../selectors/url/abortUrlSelector'
import { fetchEntryData } from '../../actions/app/entryData.actions'
import { resetConfigurationProcess } from '../../actions/app/reset.actions'

const abortMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(startAbort, action)) {
        dispatch(resetConfiguration(undefined, {
            onSuccess: configurationIsResetForAbort,
        }))
    }

    if (isActionOf(configurationIsResetForAbort, action)) {
        dispatch(resetConfigurationProcess())
        dispatch(fetchEntryData())
        dispatch(abortRedirect())
    }

    if (isActionOf(abortRedirect, action)) {
        const abortUrl = abortUrlSelector(getState())

        window.location.href = abortUrl
    }
}

export default abortMiddleware
