import { connect, MapStateToProps } from 'react-redux'
import { Props } from './DigitalGiveawayModal'
import {
    createDigitalGiveawayUrl,
    resetDigitalGiveawayUrl,
} from '../../redux/actions/app/digitalGiveaway.actions'
import vehicleCodeRequestIsPendingSelector from '../../redux/selectors/pending-request/vehicleCodeRequestIsPendingSelector'
import digitalGiveawayLinkRequestIsPendingSelector from '../../redux/selectors/pending-request/digitalGiveawayLinkRequestIsPendingSelector'
import digitalGiveawayLinkSelector from '../../redux/selectors/digitalGiveawayLinkSelector'

type StateProps = Pick<Props, 'isLoading' | 'urlLink'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isLoading: vehicleCodeRequestIsPendingSelector(state) || digitalGiveawayLinkRequestIsPendingSelector(state),
    urlLink: digitalGiveawayLinkSelector(state),
})

type DispatchProps = Pick<Props, 'createDigitalGiveawayUrl' | 'resetDigitalGiveawayUrl'>

const mapDispatchToProps: DispatchProps = {
    createDigitalGiveawayUrl,
    resetDigitalGiveawayUrl,
}

export default connect(mapStateToProps, mapDispatchToProps)
