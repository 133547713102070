import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { toggleSidebarExpand, setSidebarExpandState } from '../../actions/app/sidebar.actions'
import sidebarIsExpandedSelector from '../../selectors/sidebarIsExpandedSelector'

const sidebarMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(toggleSidebarExpand, action)) {
        const state = getState()
        const sidebarIsExpanded = sidebarIsExpandedSelector(state)

        dispatch(setSidebarExpandState(!sidebarIsExpanded))
    }

    if (action.type === LOCATION_CHANGE) {
        dispatch(setSidebarExpandState(false))
    }
}

export default sidebarMiddleware
