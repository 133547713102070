import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { fetchVisualization, fetchVisualizationAsync, setVisualizationState } from '../../actions/app/visualization.actions'
import { getVisualizationApiUrl } from '../../../constants/apiUrls'
import isAveStringFramePostMessageHackEnabledSelector from '../../selectors/feature-scope/isAveStringFramePostMessageHackEnabledSelector'
import { ApiRequest } from '../../apiRequest'

declare global {
    interface Window {
        aveCarConfiguration: string
    }
}

// eslint-disable-next-line max-len
const visualizationMiddleware = (window: Window, apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchVisualization, action)) {
        apiRequest({
            options: {
                url: getVisualizationApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchVisualizationAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchVisualizationAsync.success, action)) {
        const visualization = action.payload

        dispatch(setVisualizationState(visualization))

        if (isAveStringFramePostMessageHackEnabledSelector(getState())) {
            if (window.parent !== window) {
                window.parent.postMessage({
                    key: 'prCode',
                    prCode: visualization.aveString,
                }, '*')
            }

            // eslint-disable-next-line no-param-reassign
            window.aveCarConfiguration = visualization.aveString
        }
    }
}

export default visualizationMiddleware
