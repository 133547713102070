import { createReducer } from 'typesafe-actions'
import CarlineData from '../../entities/CarlineData'
import { setCarineDataState } from '../actions/app/carlineData.actions'

export type CarlineDataState = CarlineData

export const defaultState: CarlineDataState = {
    carlineGroups: [],
}

const carlineDataReducer = createReducer(defaultState)
    .handleAction(setCarineDataState, (state, action) => action.payload)

export default carlineDataReducer
