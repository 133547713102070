import React, { FunctionComponent } from 'react'
import style from './comparisonModelWrapper.css'
import { Select } from '../form'
import PlusIcon from '../../assets/media/icons/plus.svg'
import ComparisonData from '../../entities/ComparisonData'
import ComparisonCarlineStructure from '../../entities/ComparisonCarlineStructure'
import useTranslation from '../../hooks/useTranslation'
import ComparisonButtonsSection from '../comparison-buttons-section/ComparisonButtonsSection'

export type Props = {
    carlineStructure: ComparisonCarlineStructure
    comparisonData: ComparisonData
    modelIsSelected: boolean
    setCarlineId: (i: number, value: string) => void
    setModelId: (i: number, value: string) => void
    setOnlyDifferentFlag: (value: boolean) => void
    getResult: (data: ComparisonData) => void
    setCurrentModel: () => void
}

const CompareModelsWrapper: FunctionComponent<Props> = (props) => {
    const {
        carlineStructure,
        comparisonData,
        modelIsSelected,
        setCarlineId,
        setModelId,
        setOnlyDifferentFlag,
        getResult,
        setCurrentModel,
    } = props

    const { t } = useTranslation()

    const selectorContent = comparisonData.models.map((modelData, index) => {
        const cssClass = index == comparisonData.models.length-1
            ? style.modelSelectorBlock + " " + style.modelSelectorBlockLast
            : style.modelSelectorBlock

        const useConfiguration = index == 0 && modelIsSelected ? (
            <div onClick={() => setCurrentModel()} className={style.useConfigurationButton} title={t('compare.useCurrentConfigurationTip')}>
                <PlusIcon/>
            </div>
        ) : undefined

        const selectedCarline = modelData.carlineId

        const models = selectedCarline
            ? carlineStructure.carlines.filter(carline => carline.id === selectedCarline)[0].models
            : undefined

        const selectedModel = modelData.modelId

        const modelSelectBlock = models
            ? (
                <Select
                    onChange={(event) => setModelId(index, event.target.value)}
                    value={selectedModel ? selectedModel : ""}
                    className={style.select}
                >
                    <option key="none" value="">{t('compare.selectModelPlaceholder')}</option>
                    {
                        models
                            .map(model => {
                                return (
                                    <option key={model.id + "_" + index} value={model.id}>{model.name}</option>
                                )
                            })
                    }
                </Select>
            ) : (
                <Select disabled={true} className={style.select}>
                    <option key="none" value="">{t('compare.selectModelPlaceholder')}</option>
                </Select>
            )

        return (
            <div key={index} className={cssClass}>
                <div className={style.modelSelectorNumber}>{index + 1}</div>
                {useConfiguration}
                <div className={style.modelSelectorCarline}>
                    <Select
                        onChange={(event) => setCarlineId(index, event.target.value)}
                        value={selectedCarline ? selectedCarline : ""}
                        className={style.select}
                    >
                        <option key="none" value="">{t('compare.selectCarlinePlaceholder')}</option>
                        {
                            carlineStructure.carlines
                                .map(carline => {
                                    return (
                                        <option key={carline.id + "_" + index} value={carline.id}>{carline.name + " " + carline.modelYear}</option>
                                    )
                                })
                        }
                    </Select>
                </div>
                <div className='modelSelectorEngine'>
                    {modelSelectBlock}
                </div>
            </div>
        )
    })

    const activateComparisonButton = comparisonData.models
        .filter(model => model.modelId)
        .length > 1

    return (
        <ComparisonButtonsSection
            selectorContent={selectorContent}
            comparisonData={comparisonData}
            activateComparisonButton={activateComparisonButton}
            getResult={getResult}
            setOnlyDifferentFlag={setOnlyDifferentFlag}
            t={t}
        />
    )
}

export default CompareModelsWrapper
