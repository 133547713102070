import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import trackingSelector from '../../selectors/tracking/trackingSelector'
import { setTrackingState } from '../../actions/tracking/tracking.actions'
import getManufacturer from './helpers/getManufacturer'
import brandSelector from '../../selectors/brandSelector'
import currencySelector from '../../selectors/currencySelector'

const trackEquipmentsMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(fetchEquipmentGroupsAsync.success, action)) {
        const { equipmentGroups } = action.payload
        const tracking = trackingSelector(store.getState())
        const updatedTracking = {
            ...tracking,
        }
        const brand = brandSelector(store.getState())
        const currency = currencySelector(store.getState())
        const manufacturer = getManufacturer(brand)
        const carParts = []

        equipmentGroups.forEach((equipmentGroup) => {
            const { equipmentFamilies } = equipmentGroup

            if (!equipmentFamilies) {
                return
            }

            const equipments = equipmentFamilies
                .flatMap((equipmentFamily) => equipmentFamily.equipments)

            if (!equipments) {
                return
            }

            equipments.forEach((equipment) => {
                if (!equipment) {
                    return
                }

                if (equipment.selected) {
                    carParts.push({
                        productInfo: {
                            productID: equipment.evaKey,
                            productName: equipment.name,
                            manufacturer,
                        },
                        category: {
                            primaryCategory: equipmentGroup.name,
                            subCategory1: 'not available',
                            productType: 'car part',
                        },
                        attributes: {
                            selected: true,
                            enabled: equipment.selectable,
                            visible: true,
                            price: equipment.price || '',
                            currency,
                            count: equipment.count || 1,
                        },
                    })
                }
            })
        })

        updatedTracking.products = {
            ...updatedTracking.products,
            configuredVehicle: [
                {
                    ...updatedTracking.products.configuredVehicle[0],
                    carParts,
                },
            ],
        }

        store.dispatch(setTrackingState(updatedTracking, {
            causedBy: action,
        }))
    }
}

export default trackEquipmentsMiddleware
