import React, { FunctionComponent, useState } from 'react'
import useTranslation from '../../hooks/useTranslation'
import DigitalGiveawayIcon from '../../assets/media/icons/digital-giveaway-link.svg'
import CartboxButton from '../cartbox-button'
import DigitalGiveawayModal from '../digital-giveaway-modal'

export type Props = {
    openDigitalGiveawayUrl: Function
    configurationIsBuildable: boolean
}

const DigitalGiveawayButton: FunctionComponent<Props> = (props) => {
    const { openDigitalGiveawayUrl, configurationIsBuildable } = props
    const { t } = useTranslation()
    const openModalState = useState(false)
    const setOpenModalState = openModalState[1]

    const clickHandler = (): void => {
        openDigitalGiveawayUrl()
        setOpenModalState(true)
    }

    return (
        <>
            <CartboxButton
                onClick={clickHandler}
                disabled={!configurationIsBuildable}
                icon={<DigitalGiveawayIcon />}
                label={t(`digitalGiveaway.linkText`)}
                dataCy="cart-button-digital-giveaway"
            />
            <DigitalGiveawayModal openModalState={openModalState} />
        </>
    )
}

export default DigitalGiveawayButton
