import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './comparisonWrapper.css'
import ComparisonModelWrapper from '../comparison-model-wrapper/ComparisonModelWrapper'
import ComparisonCodeWrapper from '../comparison-code-wrapper/ComparisonCodeWrapper'
import ComparisonData from '../../entities/ComparisonData'
import ComparisonCarlineStructure from '../../entities/ComparisonCarlineStructure'
import ComparisonResultWrapper from '../comparison-result-wrapper/ComparisonResultWrapper'
import ComparisonResultData from '../../entities/ComparisonResultData'
import { Spinner } from '../../base-components'

export type Props = {
    carlineStructure: ComparisonCarlineStructure
    resultData: ComparisonResultData
    comparisonData: ComparisonData
    selectedCarineId: string | undefined
    selectedModelId: string | undefined
    selectedVehicleCode: string | undefined
    isLoading: boolean
    getModelComparisonResult: (data: ComparisonData) => void
    getCodeComparisonResult: (data: ComparisonData) => void
    resetComparisonResultData: () => void
    setComparisonData: (data: ComparisonData) => void
}

const ComparisonWrapper: FunctionComponent<Props> = (props) => {
    const {
        carlineStructure,
        resultData,
        comparisonData,
        selectedCarineId,
        selectedModelId,
        selectedVehicleCode,
        getModelComparisonResult,
        getCodeComparisonResult,
        resetComparisonResultData,
        isLoading,
        setComparisonData,
    } = props

    const { t } = useTranslation()

    const setComparisonType = (typeValue: string) => {
        const newData = { ...comparisonData, type:typeValue }
        setComparisonData(newData)
        resetComparisonResultData()
    }

    const setCarlineId = (index: number, carlineId: string) => {
        const newData = { ...comparisonData }
        newData.models[index].carlineId = carlineId
        newData.models[index].modelId = undefined
        setComparisonData(newData)
    }

    const setModelId = (index: number, modelId: string) => {
        const newData = { ...comparisonData }
        newData.models[index].modelId = modelId
        setComparisonData(newData)
    }

    const setVehicleCode = (index: number, vehicleCode: string) => {
        const newData = { ...comparisonData }
        newData.codes[index].code = vehicleCode !== "" ? vehicleCode : undefined
        setComparisonData(newData)
    }

    const setOnlyDifferentFlag = (value: boolean) => {
        const newData = { ...comparisonData }
        newData.onlyDifferent = value
        setComparisonData(newData)
    }

    const setCurrentModel = () => {
        const newData = { ...comparisonData }
        const modelIdParts = selectedModelId.split("-")
        newData.models[0].carlineId = selectedCarineId
        newData.models[0].modelId = modelIdParts[0]
        setComparisonData(newData)
    }

    const setCurrentVehicleCode = () => {
        const newData = { ...comparisonData }
        newData.codes[0].code = selectedVehicleCode
        setComparisonData(newData)
    }

    const modelsType = "models"
    const codesType = "codes"

    const getTypeButtonClass = (expectType) => {
        const active = comparisonData.type === expectType ? style.compareTypeActive : ""
        return style.compareType + " " + active
    }

    const typeMenuContent = (
        <ul className={style.compareTypeWrapper}>
            <li onClick={() => setComparisonType(modelsType)}
                className={getTypeButtonClass(modelsType)}>
                {t('compare.byModelsButtonText')}
            </li>
            <li onClick={() => setComparisonType(codesType)}
                className={getTypeButtonClass(codesType)}>
                {t('compare.byCodesButtonText')}
            </li>
        </ul>
    )

    const modelIsSelected = selectedCarineId && selectedModelId && true
    const codeIsSelected = selectedVehicleCode && true

    const comparisonBody = comparisonData.type === modelsType
        ? <ComparisonModelWrapper
            carlineStructure={carlineStructure}
            comparisonData={comparisonData}
            setCarlineId={setCarlineId}
            setModelId={setModelId}
            setOnlyDifferentFlag={setOnlyDifferentFlag}
            getResult={getModelComparisonResult}
            modelIsSelected={modelIsSelected}
            setCurrentModel={setCurrentModel}
        />
        : <ComparisonCodeWrapper
            comparisonData={comparisonData}
            setVehicleCode={setVehicleCode}
            setOnlyDifferentFlag={setOnlyDifferentFlag}
            getResult={getCodeComparisonResult}
            codeIsSelected={codeIsSelected}
            setCurrentVehicleCode={setCurrentVehicleCode}
        />

    const resultSection = resultData.categories
        ? <ComparisonResultWrapper
            resultData={resultData}
            comparisonData={comparisonData} />
        : undefined

    const loadingContent = isLoading
        ? (
            <div className={style.spinnerContainer}>
                <Spinner/>
            </div>
        ) : undefined

    return (
        <div className="contentWithCartboxContainer">
            <div className="contentWithCartbox">
                <div className={style.comparePageWrapper}>
                    <h1>
                        {t('compare.pageHeadline')}
                    </h1>
                    {typeMenuContent}
                    {comparisonBody}
                    {resultSection}
                    {loadingContent}
                </div>
            </div>
        </div>
    )
}

export default ComparisonWrapper
