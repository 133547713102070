import { createSelector } from 'reselect'
import availablePriceViewsSelector from './availablePriceViewsSelector'
import priceViewFlagSelector from './priceViewFlagSelector'
import { PriceViewFlag } from '../../entities/PriceViewFlag'

const vatSwitchableSelector = createSelector(
    availablePriceViewsSelector,
    priceViewFlagSelector,
    (availablePriceViewFlags, priceViewFlag): boolean => {
        const vatSwitchableMap: Record<PriceViewFlag, boolean> = {
            BRUTTO: availablePriceViewFlags.includes('NETTO'),
            NETTO: availablePriceViewFlags.includes('BRUTTO'),
            PRICE_BRUTTO: availablePriceViewFlags.includes('PRICE_NETTO'),
            RATE_BRUTTO: availablePriceViewFlags.includes('RATE_NETTO'),
            PRICE_NETTO: availablePriceViewFlags.includes('PRICE_BRUTTO'),
            RATE_NETTO: availablePriceViewFlags.includes('RATE_BRUTTO'),
            PRICE_OTR: false,
            PRICE_WLTP: false,
        }

        return vatSwitchableMap[priceViewFlag]
    },
)

export default vatSwitchableSelector
