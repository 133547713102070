import { useEffect } from 'react'

const useScrollToElement = (): void => {
    useEffect(() => {
        if (document.location.hash) {
            const element = document
                .getElementById(decodeURIComponent(document.location.hash.substr(1)))

            if (element !== null) {
                const { top } = element.getBoundingClientRect()
                const y = top + window.pageYOffset

                window.scrollTo(0, y)
            }
        }
    }, [])
}

export default useScrollToElement
