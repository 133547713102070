import { createSelector } from 'reselect'
import configuredModelIdSelector from '../models/configuredModelIdSelector'
import configurationRequestIsPendingSelector from '../pending-request/configurationRequestIsPendingSelector'

const cartIsVisibleSelector = createSelector(
    configuredModelIdSelector,
    configurationRequestIsPendingSelector,
    (modelId, configurationPending): boolean => modelId !== '' || configurationPending,
)

export default cartIsVisibleSelector
