import { createSelector } from 'reselect'
import carlinesSelector from './carlinesSelector'
import configuredCarlineIdSelector from './configuredCarlineIdSelector'

const configuredCarlineNameSelector = createSelector(
    carlinesSelector,
    configuredCarlineIdSelector,
    (carlines, configuredCarlineId): string | null => {
        const configuredCarline = carlines.find(({ id }) => id === configuredCarlineId)

        if (!configuredCarline) {
            return null
        }

        return configuredCarline.name
    },
)

export default configuredCarlineNameSelector
