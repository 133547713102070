import { connect, MapStateToProps } from 'react-redux'
import { Props } from './EquipmentSelection'
import { setTextFilter } from '../../redux/actions/app/equipmentFilter.actions'
import currentEquipmentGroupSelector from '../../redux/selectors/equipments/currentEquipmentGroupSelector'
import equipmentSelectionIsLoadingSelector from '../../redux/selectors/is-loading/equipmentSelectionIsLoadingSelector'

type StateProps = Pick<Props, 'equipmentGroup' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    equipmentGroup: currentEquipmentGroupSelector(state),
    isLoading: equipmentSelectionIsLoadingSelector(state),
})

type DispatchProps = Pick<Props, 'setTextFilter'>

const mapDispatchToProps: DispatchProps = {
    setTextFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)
