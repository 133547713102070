import { createSelector } from 'reselect'
import priceViewFlagSelector from './priceViewFlagSelector'
import { PriceViewFlag } from '../../entities/PriceViewFlag'

const vatIncludedSelector = createSelector(
    priceViewFlagSelector,
    (priceViewFlag): boolean => {
        const vatIncludedMap: Record<PriceViewFlag, boolean> = {
            PRICE_BRUTTO: true,
            RATE_BRUTTO: true,
            BRUTTO: true,
            PRICE_NETTO: false,
            RATE_NETTO: false,
            NETTO: false,
            PRICE_OTR: true,
            PRICE_WLTP: true,
        }

        return vatIncludedMap[priceViewFlag]
    },
)

export default vatIncludedSelector
