import React, { FunctionComponent, MouseEventHandler, ReactElement } from 'react'
import style from './cartbox-button.css'

export type Props = {
    onClick: MouseEventHandler
    disabled?: boolean
    icon: ReactElement
    label: string
    dataCy?: string
}

const CartboxButton: FunctionComponent<Props> = (props) => {
    const {
        onClick,
        disabled,
        icon,
        label,
        dataCy,
    } = props

    return (
        <button
            onClick={onClick}
            className={style.button}
            disabled={disabled}
            type="button"
            data-cy={dataCy}
        >
            <span className={style.iconWrapper}>{icon}</span>
            <span>{label}</span>
        </button>
    )
}

export default CartboxButton
