import { createAction, createAsyncAction } from 'typesafe-actions'
import EntryData from '../../../entities/EntryData'
import ApiError from '../../../entities/ApiError'

export const fetchEntryData = createAction('CMD / EntryData / fetch')()

export const fetchEntryDataAsync = createAsyncAction(
    'EVT / EntryData / fetch request',
    'EVT / EntryData / fetch success',
    'EVT / EntryData / fetch failure',
)<undefined, EntryData, ApiError>()

export const setEntryDataState = createAction('DOC / EntryData / set state')<EntryData>()

export const resetEntryDataState = createAction('DOC / EntryData / reset state')()
