import { FullConfigurationResponse, GroupedEntries } from '../../../api'
import Prices from '../../../entities/Prices'
import RenderImage from '../../../entities/RenderImage'
import { useCurrentConfigurationFromVehicleCode } from './useCurrentConfigurationFromVehicleCode'

const mapPrices = (data: FullConfigurationResponse) => {
    const modelPrice = data?.modelName?.price
    const totalPrice = data?.totalPrice?.value

    return [
        ...(modelPrice ? [{ id: 'MODEL', value: modelPrice, isSum: false }] : []),
        ...(totalPrice ? [{ id: 'TOTAL', value: totalPrice, isSum: true }] : []),
    ] as Prices
}

const mapImages = (images): RenderImage[] =>
    images.map(({ id, url }) => ({
        id,
        renderDetails: [100,400,900,3840].map(size => ({size, url})),
    })) as RenderImage[]

export const useMappedConfiguration = () => {
    const { data, isLoading } = useCurrentConfigurationFromVehicleCode()

    const standardsHeadline = data?.standards?.headline ?? ''
    const optionsHeadline = data?.options?.headline ?? ''

    const standards = data?.standards?.lines?.flatMap((line) => line.entries) ?? []

    const options: GroupedEntries[] = []

    if (data?.exterior?.entries?.length > 0) {
        options.push({
            headline: data?.exterior?.headline ?? '',
            entries: data?.exterior.entries,
        })
    }
    if (data?.interior?.entries?.length > 0) {
        options.push({
            headline: data?.interior?.headline,
            entries: data?.interior?.entries,
        })
    }

    if (data?.options?.lines?.length > 0) {
        options.push({
            headline: optionsHeadline,
            entries: data?.options?.lines?.flatMap((option) => option?.entries.filter((entry) => entry.id)),
        })
    }

    const technicalDataHeadline = data?.technicalData?.headline ?? ''

    return {
        isLoading,
        modelName: data?.modelName.name.join(', '),
        standards,
        standardsHeadline,
        options,
        optionsHeadline,
        technicalDataHeadline,
        technicalData: data?.technicalData?.lines ?? [],
        prices: mapPrices(data),
        images: mapImages(data?.media ?? []),
    }
}
