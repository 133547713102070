import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { pushTrackingEvent } from '../../actions/tracking/tracking.actions'
import trackingSelector from '../../selectors/tracking/trackingSelector'

const trackingPushMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(pushTrackingEvent, action)) {
        if (window.digitalData !== undefined) {
            const dataLayerEvent = action.payload
            const tracking = trackingSelector(store.getState())

            window.digitalData = {
                ...window.digitalData,
                ...tracking,
            }

            window.digitalData.event.push(dataLayerEvent)
        }
    }
}

export default trackingPushMiddleware
