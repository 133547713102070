import React, { FunctionComponent, PropsWithChildren } from 'react'
import LoadingModal from '../loading-modal'
import style from './loadingIndicator.css'

export type Props = PropsWithChildren<{
    showLoadingCursor: boolean
    showGlobalLoadingIndicator: boolean
}>

const LoadingIndicator: FunctionComponent<Props> = (props) => {
    const {
        showLoadingCursor,
        showGlobalLoadingIndicator,
        children,
    } = props
    const loadingClass = showLoadingCursor ? style.loading : ''

    return (
        <div className={loadingClass}>
            <LoadingModal isVisible={showGlobalLoadingIndicator} />
            {children}
        </div>
    )
}

export default LoadingIndicator
