import { createReducer } from 'typesafe-actions'
import { resetModelDataState, setModelDataState } from '../actions/app/models.actions'
import ModelData from '../../entities/ModelData'

export type ModelDataState = ModelData

export const defaultState: ModelDataState = {
    models: [],
    remarks: [],
}

const modelDataReducer = createReducer(defaultState)
    .handleAction(setModelDataState, (state, action) => action.payload)
    .handleAction(resetModelDataState, () => defaultState)

export default modelDataReducer
