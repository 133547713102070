import { createSelector } from 'reselect'
import { getType } from 'typesafe-actions'
import failedRequestsSelector from './failedRequestsSelector'
import entryDataRequestHasFailedSelector from './entryDataRequestHasFailedSelector'
import { fetchEntryData } from '../actions/app/entryData.actions'

// eslint-disable-next-line no-shadow
export enum ErrorPageErrorType {
    Default = 'Default',
    TokasAuthenticationFailed = 'TokasAuthenticationFailed',
}

const errorPageSelector = createSelector(
    failedRequestsSelector,
    entryDataRequestHasFailedSelector,
    (failedRequests, entryDataRequestHasFailed): { type?: ErrorPageErrorType; show: boolean } => {
        const show = entryDataRequestHasFailed
        let type: ErrorPageErrorType = show ? ErrorPageErrorType.Default : undefined

        // eslint-disable-next-line max-len
        const hasTokasError = failedRequests?.some((failedRequest) => failedRequest.type === getType(fetchEntryData)
                && failedRequest?.error?.status === 403
                && failedRequest?.error?.message === 'TOKEN_ACCESS_DENIED')

        if (hasTokasError) {
            type = ErrorPageErrorType.TokasAuthenticationFailed
        }

        return {
            show,
            type,
        }
    },
)

export default errorPageSelector
