import { ElementType } from 'react'
import { EquipmentGroupsStatus } from './EquipmentGroup'

export const NAV_LINK_STATUS_NONE = 'NAV_LINK_STATUS_NONE'

export type NavLinkStatus = EquipmentGroupsStatus | typeof NAV_LINK_STATUS_NONE

type NavLink = {
    id: string
    url: string
    navLabel: string
    iconComponent: ElementType
    title: string
    isEnabled: boolean
    isActive: boolean
    status: NavLinkStatus
}

export default NavLink
