export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'

type ApiOptions = {
    method: HTTPMethod
    headers?: HeadersInit
    body?: BodyInit
    credentials?: 'omit' | 'same-origin' | 'include'
}

const getApiOptions = ({ method, headers, body }: ApiOptions): RequestInit => {
    const options: ApiOptions = {
        method,
        credentials: 'include',
    }

    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
        options.headers = {
            'Content-Type': 'application/json',
        }
    }

    if (headers) {
        options.headers = headers
    }

    if (body) {
        options.body = body
    }

    return options
}

export default getApiOptions
