import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { applyBrand } from '../../actions/app/brand.actions'
import brandSelector from '../../selectors/brandSelector'
import audiFavicon from '../../../assets/media/favicons/audi.ico'
import vwFavicon from '../../../assets/media/favicons/vw.ico'
import seatFavicon from '../../../assets/media/favicons/seat.ico'
import skodaFavicon from '../../../assets/media/favicons/skoda.ico'
import manFavicon from '../../../assets/media/favicons/man.ico'

const brandMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(applyBrand, action)) {
        const brand = brandSelector(store.getState())

        document.body.classList.add(brand)

        const faviconMap = {
            audi: audiFavicon,
            vw: vwFavicon,
            vwn: vwFavicon,
            seat: seatFavicon,
            skoda: skodaFavicon,
            man: manFavicon,
            cupra: seatFavicon,
        }

        const faviconElement: HTMLLinkElement = document.head.querySelector('[rel="icon"]')

        faviconElement.href = faviconMap[brand]
    }
}

export default brandMiddleware
