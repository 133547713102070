import { createSelector } from 'reselect'
import Filter from '../../entities/Filter'
import entryDataSelector from './entryDataSelector'

const carlineFilterListSelector = createSelector(
    entryDataSelector,
    (entryData): Filter[] => entryData.viewFilter,
)

export default carlineFilterListSelector
