import React, { FunctionComponent, ReactElement } from 'react'
import style from './cartboxEmissionConsumption.css'
import { CartEmissionConsumptionEntry, CartEmissionConsumptions } from '../../entities/CartEmissionConsumptions'
import parser from 'react-html-parser'
import useTranslation from '../../hooks/useTranslation'
import camelCase from 'lodash.camelcase'

export type Props = {
    emissionConsumption: CartEmissionConsumptions
}

const CartboxEmissionConsumption: FunctionComponent<Props> = (props) => {
    const { emissionConsumption } = props
    const { t } = useTranslation()

    if (!emissionConsumption) {
        return null
    }

    const getBlockEntries = (entries: CartEmissionConsumptionEntry[] | null): ReactElement[] => {
        return entries
            ? entries.map((entry, index) => {
                const rawValue = entry.mark ? `${entry.mark}${entry.id}` : entry.id
                const key = parser(rawValue)
                return (
                    <div className={style.blockEntry} key={index}>
                        <dt className={style.entryKey}>{key}</dt>
                        <dd className={style.entryValue}>{entry.value}</dd>
                    </div>
                )
            })
            : []
    }

    const emissionConsumptionBlocks = emissionConsumption.map((entry) => (
        <li key={entry.headline}>
            <h4 className={style.headline}>
                {entry.headline ? t(`cartBoxTechnicalDataHeadLine.${camelCase(entry.headline)}`) : null}
            </h4>
            {entry.hyperlinks ? entry.hyperlinks.map((link, index) => (
                <a className={style.hyperlink} key={index} href={link.value}>{link.id}</a>
            )) : null}
            {getBlockEntries(entry.entries)}
        </li>
    ))

    return (
        <ul className={style.container}>
            {emissionConsumptionBlocks}
        </ul>
    )
}

export default CartboxEmissionConsumption
