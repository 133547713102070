import { connect, MapStateToProps } from 'react-redux'
import filterTextEquipmentSelector from '../../redux/selectors/equipments/filterTextEquipmentSelector'
import { Props } from '../text-filter'
import equipmentTextFilterOptionsSelector from '../../redux/selectors/equipments/equipmentTextFilterOptionsSelector'

type StateProps = Pick<Props, 'value' | 'suggestions'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    value: filterTextEquipmentSelector(state),
    suggestions: equipmentTextFilterOptionsSelector(state),
})

export default connect(mapStateToProps)
