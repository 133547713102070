import { createAction, createAsyncAction } from 'typesafe-actions'
import ComparisonResultData from '../../../entities/ComparisonResultData'
import ComparisonData from '../../../entities/ComparisonData'

export const fetchModelComparisonResultData = createAction('CMD / Model ComparisonResultData / fetch')<ComparisonData>()

export const fetchModelComparisonResultDataAsync = createAsyncAction(
    'EVT / Model ComparisonResultData / fetch request',
    'EVT / Model ComparisonResultData / fetch success',
    'EVT / Model ComparisonResultData / fetch failure',
)<ComparisonData, ComparisonResultData, Error>()

export const fetchCodeComparisonResultData = createAction('CMD / Code ComparisonResultData / fetch')<ComparisonData>()

export const fetchCodeComparisonResultDataAsync = createAsyncAction(
    'EVT / Code ComparisonResultData / fetch request',
    'EVT / Code ComparisonResultData / fetch success',
    'EVT / Code ComparisonResultData / fetch failure',
)<ComparisonData, ComparisonResultData, Error>()

export const setComparisonResultData = createAction('DOC / ComparisonResultData / set state')<ComparisonResultData>()

export const resetComparisonResultData = createAction('DOC / ComparisonResultData / reset state')()
