import { Dispatch } from 'redux'
import { Action, ActionCreator } from 'typesafe-actions'
import ApiError from '../entities/ApiError'
import getApiOptions from '../getApiOptions'

type Fetch = typeof window.fetch

export type RequestOptions = RequestInit & {
    url: string
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
}

type Config = {
    options: RequestOptions
    asyncActions: {
        request: ActionCreator
        success: ActionCreator
        failure: ActionCreator
    }
    causedBy: Action
}

type AsyncActionMeta = {
    asyncActionType: 'request' | 'success' | 'failure'
    options: RequestOptions
    causedBy: Action & { error: ApiError }
}

type ApiRequestAction = Action & {
    meta: AsyncActionMeta
}

type ApiRequestFailureAction = ApiRequestAction & {
    payload: ApiError
}

export const isApiRequestAction = (action: ApiRequestAction): action is ApiRequestAction =>
    action.meta?.asyncActionType === 'request'

export const isApiSuccessAction = (action: ApiRequestAction): action is ApiRequestAction =>
    action.meta?.asyncActionType === 'success'

export const isApiFailureAction = (action: ApiRequestAction): action is ApiRequestFailureAction =>
    action.meta?.asyncActionType === 'failure'

export const apiRequestFactory =
    (fetch: Fetch) =>
    (config: Config, dispatch: Dispatch): void => {
        const { options, asyncActions, causedBy } = config
        const { url } = options
        const enrichedOptions: RequestOptions = getApiOptions({
            ...options,
        })
        enrichedOptions.url = url
        dispatch(
            asyncActions.request(undefined, {
                asyncActionType: 'request',
                options: enrichedOptions,
                causedBy,
            }),
        )

        const handleError = (type: ApiError['type'], status?: number) => (error: Error) => {
            const apiError: ApiError = {
                url: options.url,
                status,
                type,
                message: error.message,
            }

            dispatch(
                asyncActions.failure(apiError, {
                    asyncActionType: 'failure',
                    options: enrichedOptions,
                    causedBy,
                }),
            )
        }

        const handleSuccess = (data?: object): void => {
            dispatch(
                asyncActions.success(data, {
                    asyncActionType: 'success',
                    options: enrichedOptions,
                    causedBy,
                }),
            )
        }

        const handleResponse = (response: Response): void => {
            if (!response.ok) {
                response
                    .json()
                    .then((errorBody) => {
                        const error = new Error(errorBody.message)

                        handleError('response', response.status)(error)
                    })
                    .catch(() => {
                        const error = new Error(response.statusText)

                        handleError('json', response.status)(error)
                    })

                return
            }

            if (response.status === 204) {
                handleSuccess()
                return
            }

            response.json().then(handleSuccess).catch(handleError('json', response.status))
        }

        fetch(encodeURI(options.url), enrichedOptions).then(handleResponse).catch(handleError('fetch'))
    }

export type ApiRequest = typeof apiRequest

const apiRequest = apiRequestFactory(window.fetch)

export default apiRequest
