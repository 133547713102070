import { connect, MapStateToProps } from 'react-redux'
import sidebarIsExpandedSelector from '../../redux/selectors/sidebarIsExpandedSelector'
import { toggleSidebarExpand } from '../../redux/actions/app/sidebar.actions'
import { Props } from './Sidebar'

type StateProps = Pick<Props, 'sidebarIsExpanded'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    sidebarIsExpanded: sidebarIsExpandedSelector(state),
})

type DispatchProps = Pick<Props, 'toggleSidebarExpand'>

const mapDispatchToProps: DispatchProps = {
    toggleSidebarExpand,
}

export default connect(mapStateToProps, mapDispatchToProps)
