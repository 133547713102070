import { createAction, createAsyncAction } from 'typesafe-actions'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import { EquipmentId } from '../../../entities/Equipment'
import { InfoModalData } from '../../../entities/InfoModal'
import EquipmentResponse from '../../../entities/EquipmentResponse'

export const selectEquipment = createAction('CMD / EquipmentGroups / select equipment')<EquipmentId, { infoModal?: InfoModalData } | undefined>()
export const deselectEquipment = createAction('CMD / EquipmentGroups / deselect equipment')<EquipmentId>()

export const fetchEquipmentGroups = createAction('CMD / EquipmentGroups / fetch')<EquipmentResponse>()

export const fetchEquipmentGroupsAsync = createAsyncAction(
    'EVT / EquipmentGroups / fetch request',
    'EVT / EquipmentGroups / fetch success',
    'EVT / EquipmentGroups / fetch failure',
)<undefined, EquipmentResponse, Error>()

export const setEquipmentGroupsState = createAction('DOC / EquipmentGroups / set state')<EquipmentGroup[]>()

export const resetEquipmentGroupsState = createAction('DOC / EquipmentGroups / reset state')()
