import React, { FunctionComponent } from 'react'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import style from './foresightedBuildabilityTriggerEquipment.css'

export type Props = {
    conflictItem: ConflictItem | null
}

const ForesightedBuildabilityTriggerEquipment: FunctionComponent<Props> = (props) => {
    const { conflictItem } = props

    if (conflictItem === null) {
        return null
    }

    const { name, price } = conflictItem.equipment

    return (
        <div className={style.container}>
            <span>{name}</span>
            <span className={style.price}>{price}</span>
        </div>
    )
}

export default ForesightedBuildabilityTriggerEquipment
