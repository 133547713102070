import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { checkoutGotVehicleCode, checkoutRedirect, startCheckout } from '../../actions/app/checkout.actions'
import orderUrlSelector from '../../selectors/url/orderUrlSelector'
import { createVehicleCode, setVehicleCodeState } from '../../actions/app/vehicleCode.actions'
import vehicleCodeSelector from '../../selectors/vehicleCodeSelector'

const checkoutMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(startCheckout, action)) {
        dispatch(createVehicleCode(undefined, {
            onSuccess: checkoutGotVehicleCode,
        }))
    }

    if (isActionOf(checkoutGotVehicleCode, action)) {
        const { vehicleCode } = action.payload

        dispatch(setVehicleCodeState(vehicleCode))
        dispatch(checkoutRedirect())
    }

    if (isActionOf(checkoutRedirect, action)) {
        const state = getState()
        const orderUrl = orderUrlSelector(state)
        const vehicleCode = vehicleCodeSelector(state)

        window.location.href = `${orderUrl}${vehicleCode}`
    }
}

export default checkoutMiddleware
