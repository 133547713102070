import { createAction, createAsyncAction } from 'typesafe-actions'
import MetaAsyncHandler from '../../../entities/MetaAsyncHandler'
import VehicleCode from '../../../entities/VehicleCode'
import VehicleCodeStatus from '../../../entities/VehicleCodeStatus'
import { VehicleCodeStatusState } from '../../reducers/vehicleCodeStatusReducer'

export const createVehicleCode = createAction('CMD / VehicleCode / create')<undefined, MetaAsyncHandler>()

export const createVehicleCodeAsync = createAsyncAction(
    'EVT / VehicleCode / create request',
    'EVT / VehicleCode / create success',
    'EVT / VehicleCode / create failure',
)<undefined, { vehicleCode: VehicleCode }, Error>()

export const setVehicleCodeState = createAction('DOC / VehicleCode / set state')<VehicleCode>()
export const resetVehicleCodeState = createAction('DOC / VehicleCode / reset state')()

export const loadConfigurationByVehicleCode = createAction('CMD / VehicleCode / get configuration')<VehicleCode>()

export const loadConfigurationByVehicleCodeAsync = createAsyncAction(
    'EVT / VehicleCode / get configuration request',
    'EVT / VehicleCode / get configuration success',
    'EVT / VehicleCode / get configuration failure',
)<VehicleCode, VehicleCodeStatus, Error>()

export const vehicleCodeChanged = createAction('EVT / VehicleCode / vehicle code changed')()

export const setVehicleCodeStatus = createAction('DOC / VehicleCodeRescue / set state')<VehicleCodeStatusState>()
export const resetVehicleCodeStatus = createAction('DOC / VehicleCodeRescue / reset state')()
