import { connect, MapStateToProps } from 'react-redux'
import audiExclusiveDataStateSelector from '../../redux/selectors/audiExclusiveDataStateSelector'
import AudiExclusiveApp from './AudiExclusiveApp'
import AudiExclusiveDataState from '../../entities/AudiExclusiveDataState'
import Visualization from '../../entities/Visualization'
import visualizationSelector from '../../redux/selectors/visualizationSelector'
import equipmentHaveAudiExclusiveSelector from '../../redux/selectors/equipmentHaveAudiExclusiveSelector'
import configuredModelIdSelector from '../../redux/selectors/models/configuredModelIdSelector'
import currencySelector from '../../redux/selectors/currencySelector'

type StateProps = {
    audiExclusiveDataState: AudiExclusiveDataState
    visualization: Visualization
    hasAudiExclusiveEquipments: boolean
    modelId: string
    currency: string
}

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    audiExclusiveDataState: audiExclusiveDataStateSelector(state),
    visualization: visualizationSelector(state),
    hasAudiExclusiveEquipments: equipmentHaveAudiExclusiveSelector(state),
    modelId: configuredModelIdSelector(state),
    currency: currencySelector(state),
})

export default connect(mapStateToProps)(AudiExclusiveApp)
