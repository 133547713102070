import { Location } from 'history'
import { createSelector } from 'reselect'
import routerSelector from './routerSelector'

const locationSelector = createSelector(
    routerSelector,
    (router): Location => router.location,
)

export default locationSelector
