import React, { FunctionComponent, PropsWithChildren } from 'react'
import style from './infoButton.css'

export type Props = PropsWithChildren<{
    openModal: Function
    dataCy: string
}>

const InfoButton: FunctionComponent<Props> = (props) => {
    const {
        openModal,
        children,
        dataCy,
    } = props

    return (
        <button onClick={() => openModal()} className={style.button} type="button" data-cy={dataCy}>
            {children}
        </button>
    )
}

export default InfoButton
