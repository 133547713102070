import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import useTranslation from '../../hooks/useTranslation'
import Remark from '../../entities/Remark'
import style from './disclaimerText.css'

export type Props = {
    remarks: Remark[]
}

export const getRemarkLabels = (remarks: Remark[]): Record<Remark['id'], string> => {
    if (!remarks) {
        return {}
    }

    return remarks.reduce<{[key in string]: string}>((accumulator, remark, idx) => {
        accumulator[remark.id] = '*'.repeat(idx + 1)
        return accumulator
    }, {})
}

const DisclaimerText: FunctionComponent<Props> = (props) => {
    const { remarks } = props
    const { t, i18n } = useTranslation()

    if (!remarks || remarks.length === 0) {
        return null
    }

    const remarkLabels = getRemarkLabels(remarks)

    const remarkElements = remarks.map((remark) => {
        const label = remarkLabels[remark.id]
        const translatedText = i18n.exists(remark.key) ? t(remark.key) : remark.key
        const text = `${label} ${translatedText}`

        return (
            <article key={remark.id} className={style.wrapper}>
                <Markup content={text} />
            </article>
        )
    })

    return <>{remarkElements}</>
}

export default DisclaimerText
