import { createSelector } from 'reselect'
import { ModelAttributeId } from '../../entities/Model'
import entryDataSelector from './entryDataSelector'

const viewAttributesSelector = createSelector(
    entryDataSelector,
    (entryData): ModelAttributeId[] => entryData.viewAttributes,
)

export default viewAttributesSelector
