import { createReducer } from 'typesafe-actions'
import {
    resetEquipmentsHaveAudiExclusiveState,
    setEquipmentsHaveAudiExclusiveState,
} from '../actions/app/audiExclusiveApp.actions'

export const defaultState = false

const equipmentHaveAudiExclusiveReducer = createReducer(defaultState)
    .handleAction(setEquipmentsHaveAudiExclusiveState, (state, action) => action.payload)
    .handleAction(resetEquipmentsHaveAudiExclusiveState, () => defaultState)

export default equipmentHaveAudiExclusiveReducer
