import React, { FunctionComponent } from 'react'
import PdfIcon from '../../assets/media/icons/pdf.svg'
import useTranslation from '../../hooks/useTranslation'
import CartboxButton from '../cartbox-button'

export type Props = {
    createPdf: Function
    requestIsPending: boolean
    configurationIsBuildable: boolean
    pdfType: string
    excludeParts?: string[]
    isVisible: boolean
}

const PdfButton: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation()

    const { createPdf, configurationIsBuildable, requestIsPending, pdfType, excludeParts, isVisible } = props

    if (!isVisible) {
        return null
    }

    const pdfButtonClicked = (): void => {
        createPdf(excludeParts || null)
    }

    return (
        <CartboxButton
            onClick={pdfButtonClicked}
            disabled={!configurationIsBuildable || requestIsPending}
            icon={<PdfIcon />}
            label={t(`${pdfType}.buttonLabel`)}
        />
    )
}

export default PdfButton
