import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    addNotificationToState,
    createNotification,
    removeNotification,
    removeNotificationFromState,
    resetNotifications,
    resetNotificationsState,
} from '../../actions/app/notification.actions'

const notificationMiddleware = (setTimeout: Window['setTimeout']): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(createNotification, action)) {
        const notification = action.payload

        dispatch(addNotificationToState(notification))

        setTimeout(() => {
            dispatch(removeNotification(notification))
        }, 5000)
    }

    if (isActionOf(removeNotification, action)) {
        const notification = action.payload

        dispatch(removeNotificationFromState(notification))
    }

    if (isActionOf(resetNotifications, action)) {
        dispatch(resetNotificationsState())
    }
}

export default notificationMiddleware
