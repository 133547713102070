import { createSelector } from 'reselect'
import entryDataSelector from './entryDataSelector'
import { PriceViewFlag } from '../../entities/PriceViewFlag'

const availablePriceViewsSelector = createSelector(
    entryDataSelector,
    (entryData): PriceViewFlag[] => entryData.priceViewFlags,
)

export default availablePriceViewsSelector
