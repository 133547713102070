import { createReducer } from 'typesafe-actions'
import { CarlineId } from '../../entities/Carline'
import CarlineGroup, { DisplayMode } from '../../entities/CarlineGroup'
import {
    resetCarlineSelectionState,
    setActiveCarlineGroupIdState,
    setDisplayModeState,
    setFiltersAreVisibleState,
    setSelectedCarlineState,
} from '../actions/app/carlineSelection.actions'

export type CarlineSelectionState = {
    carlineId: CarlineId
    activeGroupId: CarlineGroup['id']
    displayMode: DisplayMode
    filtersAreVisible: boolean
}

export const defaultState: CarlineSelectionState = {
    carlineId: '',
    activeGroupId: '',
    displayMode: 'grid',
    filtersAreVisible: false,
}

const carlineSelectionReducer = createReducer(defaultState)
    .handleAction(setSelectedCarlineState, (state, action) => ({
        ...state,
        carlineId: action.payload,
    }))
    .handleAction(setActiveCarlineGroupIdState, (state, action) => ({
        ...state,
        activeGroupId: action.payload,
    }))
    .handleAction(setDisplayModeState, (state, action) => ({
        ...state,
        displayMode: action.payload,
    }))
    .handleAction(setFiltersAreVisibleState, (state, action) => ({
        ...state,
        filtersAreVisible: action.payload,
    }))
    .handleAction(resetCarlineSelectionState, () => defaultState)

export default carlineSelectionReducer
