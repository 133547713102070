import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './numberSelect.css'
import { Select } from '../form'

export type Props = {
    label?: string
    value?: number
    minValue?: number
    maxValue?: number
    enable: boolean
    onChange: Function
}

const NumberSelect: FunctionComponent<Props> = (props) => {
    const {
        value,
        minValue,
        maxValue,
        label,
        onChange,
        enable,
    } = props
    const { t, i18n } = useTranslation()

    if (value && minValue && maxValue && maxValue > 1) {
        let labelValue

        if (label) {
            labelValue = i18n.exists(label) ? t(label) : label
        } else {
            labelValue = t('accessory.quantity')
        }

        const optionElements = []

        for (let i = minValue; i <= maxValue; i++) {
            optionElements.push((
                <option key={i} value={i}>{i}</option>
            ))
        }

        return (
            <label className={style.numberSelect}>
                <span className={style.labelText}>{labelValue}</span>
                <Select
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    disabled={!enable}
                    className={style.select}
                >
                    {optionElements}
                </Select>
            </label>
        )
    }

    return null
}

export default NumberSelect
