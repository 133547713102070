import { Middleware } from 'redux'
import { addPendingRequest, removePendingRequest } from '../../actions/core/pendingRequests.actions'
import { isApiFailureAction, isApiRequestAction, isApiSuccessAction } from '../../apiRequest'

const apiUiMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isApiRequestAction(action)) {
        const { url } = action.meta.options
        const { causedBy } = action.meta

        dispatch(addPendingRequest(url, { causedBy }))
    }

    if (isApiSuccessAction(action) || isApiFailureAction(action)) {
        const { url } = action.meta.options
        const { causedBy } = action.meta

        dispatch(removePendingRequest(url, { causedBy }))
    }
}

export default apiUiMiddleware
