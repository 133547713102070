import { connect, MapStateToProps } from 'react-redux'
import { Props } from './VehicleCodeModal'
import vehicleCodeSelector from '../../redux/selectors/vehicleCodeSelector'
import vehicleCodeRequestIsPendingSelector from '../../redux/selectors/pending-request/vehicleCodeRequestIsPendingSelector'
import brandSelector from '../../redux/selectors/brandSelector'

type StateProps = Pick<Props, 'isLoading' | 'vehicleCode' | 'brand'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isLoading: vehicleCodeRequestIsPendingSelector(state),
    vehicleCode: vehicleCodeSelector(state),
    brand: brandSelector(state),
})

export default connect(mapStateToProps)
