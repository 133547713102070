import { connect, MapStateToProps } from 'react-redux'
import filteredModelsSelector from '../../redux/selectors/models/filteredModelsSelector'
import { Props } from './ModelsTable'
import viewAttributesSelector from '../../redux/selectors/viewAttributesSelector'
import modelsRemarksSelector from '../../redux/selectors/models/modelsRemarksSelector'
import { deselectModel, selectModel } from '../../redux/actions/app/models.actions'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'

type StateProps = Pick<Props, 'models' | 'remarks' | 'viewAttributes'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    models: filteredModelsSelector(state),
    remarks: modelsRemarksSelector(state),
    viewAttributes: viewAttributesSelector(state),
})

type DispatchProps = Pick<Props, 'selectModel' | 'deselectModel' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    selectModel,
    deselectModel,
    openInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
