import { createReducer } from 'typesafe-actions'
import ComparisonCarlineStructure from '../../entities/ComparisonCarlineStructure'
import {
    resetComparisonCarlineStructure,
    setComparisonCarlineStructure,
} from '../actions/app/comparisonCarlineStructure.actions'

const defaultState: ComparisonCarlineStructure = {
    carlines: [],
}

const comparisonCarlineStructureReducer = createReducer(defaultState)
    .handleAction(setComparisonCarlineStructure, (state, action) => action.payload)
    .handleAction(resetComparisonCarlineStructure, (() => defaultState))

export default comparisonCarlineStructureReducer
