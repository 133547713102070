import { createAction, createAsyncAction } from 'typesafe-actions'
import AudiExclusiveDataState from '../../../entities/AudiExclusiveDataState'
import { AudiExclusiveSelection } from '../../../entities/AudiExclusiveSelection'

export const storeAudiExclusiveSelectionAsync = createAsyncAction(
    'EVT / AudiExclusiveSelection / store request',
    'EVT / AudiExclusiveSelection / store success',
    'EVT / AudiExclusiveSelection / store failure',
)<AudiExclusiveSelection | undefined, void>()

export const updateAudiExclusiveSelectionAsync = createAsyncAction(
    'EVT / void / update audi exclusive selection request',
    'EVT / void / update audi exclusive selection success',
    'EVT / void / update audi exclusive selection failure',
)<undefined | void, Error>()

export const setAudiExclusiveDataState = createAction('DOC / AudiExclusiveDataState / set state')<AudiExclusiveDataState>()

export const resetAudiExclusiveDataState = createAction('DOC / AudiExclusiveDataState / reset state')()

export const storeAudiExclusiveSelection = createAction('DOC / AudiExclusiveDataState / store Audi exclusive selection')<AudiExclusiveSelection>()

export const setEquipmentsHaveAudiExclusiveState = createAction('DOC / boolean / set state')<boolean>()

export const resetEquipmentsHaveAudiExclusiveState = createAction('DOC / boolean / reset state')()

export const updateAudiExclusiveSelection = createAction('DOC / void / update audi exclusive selection')()
