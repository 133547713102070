import { createSelector } from 'reselect'
import { API_URL_ENTRY } from '../../../constants/apiUrls'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const entryDataRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_ENTRY),
)

export default entryDataRequestIsPendingSelector
