import { createSelector } from 'reselect'
import configurationDataSelector from './configurationDataSelector'

const foresightedBuildabilityIsResolvableSelector = createSelector(
    configurationDataSelector,
    (configurationData): boolean => {
        if (
            configurationData.conflictInformation === null
            || configurationData.conflictInformation === undefined
            || configurationData.conflictInformation.hiddenFields === null
            || configurationData.conflictInformation.hiddenFields === undefined
        ) {
            return false
        }

        return !configurationData.conflictInformation.hiddenFields.includes('CANCEL')
    },
)

export default foresightedBuildabilityIsResolvableSelector
