import { createReducer } from 'typesafe-actions'
import EquipmentGroup from '../../entities/EquipmentGroup'
import {
    resetEquipmentGroupsState,
    setEquipmentGroupsState,
} from '../actions/app/equipmentGroups.actions'

export type EquipmentGroupsState = EquipmentGroup[]

export const defaultState: EquipmentGroupsState = []

const equipmentGroupsReducer = createReducer(defaultState)
    .handleAction(setEquipmentGroupsState, (state, action) => action.payload)
    .handleAction(resetEquipmentGroupsState, () => defaultState)

export default equipmentGroupsReducer
