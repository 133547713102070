import { createSelector } from 'reselect'
import navLinksSelector from './navLinksSelector'
import NavLink from '../../entities/NavLink'

const activeNavLinkTitleSelector = createSelector(navLinksSelector, (navLinks): NavLink['title'] => {
    const activeNavLink = navLinks.find((navLink) => navLink.isActive)

    if (activeNavLink === undefined) {
        return ''
    }

    return activeNavLink.title
})

export default activeNavLinkTitleSelector
