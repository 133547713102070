import { createSelector } from 'reselect'
import equipmentRequestIsPendingSelector from '../pending-request/equipmentRequestIsPendingSelector'
import configurationRequestIsPendingSelector from '../pending-request/configurationRequestIsPendingSelector'

const overviewViewIsLoadingSelector = createSelector(
    equipmentRequestIsPendingSelector,
    configurationRequestIsPendingSelector,
    (equipmentRequestIsPending, configurationRequestIsPending): boolean => (
        equipmentRequestIsPending || configurationRequestIsPending
    ),
)

export default overviewViewIsLoadingSelector
