import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import TechnicalData from '../../entities/TechnicalData'
import Cartbox from '../cartbox'
import TechnicalDataTable from '../technical-data-table'
import useOnMount from '../../hooks/useOnMount'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'

export type Props = {
    technicalData: TechnicalData
    loadTechnicalData: Function
    openInfoModal: Function
    isLoading: boolean
}

const TechnicalDataView: FunctionComponent<Props> = (props) => {
    const {
        technicalData,
        loadTechnicalData,
        openInfoModal,
        isLoading,
    } = props
    const { t } = useTranslation()

    useOnMount(() => {
        loadTechnicalData()
    })

    return (
        <div className="contentWithCartboxContainer">
            <div className="contentWithCartbox">
                <h1>
                    {t('technicalDataView.heading')}
                </h1>
                <LoadingIndicatorWrapper isLoading={isLoading}>
                    {isLoading && <p>{t('loading')}</p>}
                    <TechnicalDataTable
                        technicalData={technicalData}
                        openInfoModal={openInfoModal}
                    />
                </LoadingIndicatorWrapper>
            </div>
            <Cartbox />
        </div>
    )
}

export default TechnicalDataView
