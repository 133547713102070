import React, { FunctionComponent } from 'react'
import style from './comparisonButtonsSection.css'
import { Button } from '../../base-components'
import { Checkbox } from '../form'
import ComparisonData from '../../entities/ComparisonData'

export type Props = {
    selectorContent: JSX.Element[]
    activateComparisonButton: boolean
    getResult: (data: ComparisonData) => void
    comparisonData: ComparisonData
    setOnlyDifferentFlag: (value: boolean) => void
    t: (key: string) => string
}

const ComparisonButtonsSection: FunctionComponent<Props> = (props) => {
    const {
        selectorContent,
        comparisonData,
        activateComparisonButton,
        getResult,
        setOnlyDifferentFlag,
        t,
    } = props

    return (
        <div className={style.container}>
            <div className={style.modelSelectorWrapper}>
                {selectorContent}
                <div className={style.buttonsWrapper}>
                    <Button
                        variant='primary'
                        disabled={!activateComparisonButton}
                        className={style.compareButton}
                        onClick={() => getResult(comparisonData)}
                    >
                        {t('compare.compareButtonText')}
                    </Button>
                    <div className={style.distinctWrapper}>
                        <label className={style.inputLabelWrapper}>
                            <Checkbox checked={comparisonData.onlyDifferent} onChange={(event) => setOnlyDifferentFlag(event.target.checked)}/>
                            <span className={style.inputLabel}>{t('compare.onlyDifferentText')}</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ComparisonButtonsSection
