import { createReducer } from 'typesafe-actions'
import Notification from '../../entities/Notification'
import {
    addNotificationToState,
    removeNotificationFromState,
    resetNotificationsState,
} from '../actions/app/notification.actions'

export type NotificationsState = Notification[]

export const defaultState: NotificationsState = []

const notificationsReducer = createReducer(defaultState)
    .handleAction(addNotificationToState, (state, action) => {
        const notificationToAdd = action.payload
        const notificationAlreadySet = state.some((notificationOnState) => (
            notificationOnState.message === notificationToAdd.message
        ))

        if (notificationAlreadySet) {
            return state.map((notificationOnState) => (
                notificationOnState.message === notificationToAdd.message
                    ? notificationToAdd
                    : notificationOnState
            ))
        }

        return state.concat(notificationToAdd)
    })
    .handleAction(removeNotificationFromState, (state, action) => {
        const notificationToRemove = action.payload

        return state.filter((notificationOnState) => (
            notificationOnState.id !== notificationToRemove.id
        ))
    })
    .handleAction(resetNotificationsState, () => defaultState)

export default notificationsReducer
