import React, { FunctionComponent, InputHTMLAttributes, SelectHTMLAttributes } from 'react'
import style from './form.css'

export const Select: FunctionComponent<SelectHTMLAttributes<HTMLSelectElement>> = (props) => {
    const { className, children, ...rest } = props

    return (
        <select className={`${style.select} ${className}`} {...rest}>
            {children}
        </select>
    )
}

export const Input: FunctionComponent<InputHTMLAttributes<HTMLInputElement>> = (props) => {
    const { className, ...rest } = props

    return <input type="text" className={`${style.textInput} ${className}`} {...rest} />
}

type FixedTypeInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

export const Radio: FunctionComponent<FixedTypeInputProps> = (props) => {
    const { className, ...rest } = props

    return <input type="radio" className={`${style.radio} ${className}`} {...rest} />
}

export const Checkbox: FunctionComponent<FixedTypeInputProps> = (props) => {
    const { className, ...rest } = props

    return <input type="checkbox" className={`${style.checkbox} ${className}`} {...rest} />
}

export const Range: FunctionComponent<FixedTypeInputProps> = (props) => {
    const { className, ...rest } = props

    return <input type="range" className={`${style.range} ${className}`} {...rest} />
}
