import { connect, MapStateToProps } from 'react-redux'
import { Props } from './InfoModal'
import { closeInfoModal } from '../../redux/actions/app/infoModal.actions'
import infoModalSelector from '../../redux/selectors/infoModalSelector'
import { InfoModalData } from '../../entities/InfoModal'
import wheelEfficiencyLabelIsEnabledSelector from '../../redux/selectors/feature-scope/wheelEfficiencyLabelIsEnabledSelector'

type StateProps = Pick<Props, keyof InfoModalData | 'wheelEfficiencyLabelIsEnabled'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    ...infoModalSelector(state),
    wheelEfficiencyLabelIsEnabled: wheelEfficiencyLabelIsEnabledSelector(state),
})

type DispatchProps = Pick<Props, 'closeInfoModal'>

const mapDispatchToProps: DispatchProps = {
    closeInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
