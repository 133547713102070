import { connect, MapStateToProps } from 'react-redux'
import { Props } from './DigitalGiveawayButton'
import { openDigitalGiveawayUrl } from '../../redux/actions/app/digitalGiveaway.actions'
import configurationIsBuildableSelector from '../../redux/selectors/configuration/configurationIsBuildableSelector'

type StateProps = Pick<Props, 'configurationIsBuildable'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    configurationIsBuildable: configurationIsBuildableSelector(state),
})

type DispatchProps = Pick<Props, 'openDigitalGiveawayUrl'>

const mapDispatchToProps: DispatchProps = {
    openDigitalGiveawayUrl,
}

export default connect(mapStateToProps, mapDispatchToProps)
