import { createAction, createAsyncAction } from 'typesafe-actions'
import ConfigurationResponse from '../../../entities/ConfigurationResponse'
import VehicleCode from '../../../entities/VehicleCode'
import MetaAsyncHandler from '../../../entities/MetaAsyncHandler'

export const fetchConfiguration = createAction('CMD / Configuration / fetch')()

export const fetchConfigurationAsync = createAsyncAction(
    'EVT / Configuration / fetch request',
    'EVT / Configuration / fetch success',
    'EVT / Configuration / fetch failure',
)<undefined, ConfigurationResponse, Error>()

export const getConfigurationByVehicleCode = createAction('CMD / Configuration / get by vehicle code')<VehicleCode, MetaAsyncHandler>()

export const getConfigurationByVehicleCodeAsync = createAsyncAction(
    'EVT / Configuration / get by vehicle code request',
    'EVT / Configuration / get by vehicle code success',
    'EVT / Configuration / get by vehicle code failure',
)<undefined, ConfigurationResponse, Error>()

export const putConfiguration = createAction('CMD / Configuration / put')<{ id: string; method: 'add' | 'remove' }>()

export const putConfigurationAsync = createAsyncAction(
    'EVT / Configuration / put request',
    'EVT / Configuration / put success',
    'EVT / Configuration / put failure',
)<{ id: string; method: 'add' | 'remove' }, ConfigurationResponse, Error>()

export const setConfigurationDataState = createAction('DOC / Configuration / set state')<ConfigurationResponse>()

export const resetConfiguration = createAction('CMD / Configuration / reset')<undefined, MetaAsyncHandler>()

export const resetConfigurationAsync = createAsyncAction(
    'EVT / Configuration / reset request',
    'EVT / Configuration / reset success',
    'EVT / Configuration / reset failure',
)()

export const resetConfigurationDataState = createAction('DOC / Configuration / reset state')()

export const setExcludeAudiExclusiveSelectionState = createAction('EVT / PDF / pdf exclusions')<string[] | undefined>()
