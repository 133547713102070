import { connect, MapStateToProps } from 'react-redux'
import overviewEquipmentGroupsSelector from '../../redux/selectors/equipments/overviewEquipmentGroupsSelector'
import { deselectEquipment } from '../../redux/actions/app/equipmentGroups.actions'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'
import { Props } from './OverviewEquipmentGroups'

type StateProps = Pick<Props, 'selectedEquipmentGroups'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    selectedEquipmentGroups: overviewEquipmentGroupsSelector(state),
})

type DispatchProps = Pick<Props, 'deselectEquipment' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    deselectEquipment,
    openInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
