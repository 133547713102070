import React, { FunctionComponent, PropsWithChildren } from 'react'
import CancelIcon from '../../assets/media/icons/cancel.svg'
import HamburgerIcon from '../../assets/media/icons/hamburger.svg'
import style from './sidebar.css'

export type Props = PropsWithChildren<{
    sidebarIsExpanded: boolean
    toggleSidebarExpand: Function
}>

const Sidebar: FunctionComponent<Props> = (props) => {
    const { sidebarIsExpanded, toggleSidebarExpand, children } = props
    const statusClass = sidebarIsExpanded ? style.sidebarOpen : style.sidebarClosed
    const toggleIcon = sidebarIsExpanded ? <CancelIcon /> : <HamburgerIcon />

    const toggleIsOpen = (): void => {
        toggleSidebarExpand()
    }

    const sidebarExpandedClass = sidebarIsExpanded ? 'sidebar-expanded' : 'sidebar-collapsed'

    return (
        <aside className={`${style.container} ${statusClass} ${sidebarExpandedClass}`}>
            {children}
            <button type="button" onClick={toggleIsOpen} className={style.toggleButton}>
                {toggleIcon}
            </button>
        </aside>
    )
}

export default Sidebar
