import { createSelector } from 'reselect'
import Carline from '../../../entities/Carline'
import carlineGroupsSelector from './carlineGroupsSelector'

const carlinesSelector = createSelector(
    carlineGroupsSelector,
    (carlineGroups): Carline[] => (
        carlineGroups.reduce<Carline[]>((accumulator, carlineGroup) => (
            accumulator.concat(carlineGroup.carlines)
        ), [])
    ),
)

export default carlinesSelector
