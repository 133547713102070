import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CarlineGridGroup'
import activeCarlineGroupIdSelector from '../../redux/selectors/carlines/activeCarlineGroupIdSelector'
import { setActiveCarlineGroupId } from '../../redux/actions/app/carlineSelection.actions'

type StateProps = Pick<Props, 'activeGroupId'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    activeGroupId: activeCarlineGroupIdSelector(state),
})

type DispatchProps = Pick<Props, 'setActiveCarlineGroupId'>

const mapDispatchToProps: DispatchProps = {
    setActiveCarlineGroupId,
}

export default connect(mapStateToProps, mapDispatchToProps)
