import { createSelector } from 'reselect'
import configurationDataSelector from './configuration/configurationDataSelector'
import { OrderKey } from '../../entities/ConfigurationResponse'

const orderKeySelector = createSelector(
    configurationDataSelector,
    (configurationData): OrderKey => configurationData.orderKey,
)

export default orderKeySelector
