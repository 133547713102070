import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { changePurchaseMode, setPurchaseModeState } from '../../actions/app/purchaseMode.actions'

const purchaseModeMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(changePurchaseMode, action)) {
        dispatch(setPurchaseModeState(action.payload))
    }
}

export default purchaseModeMiddleware
