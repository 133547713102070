import React, { FunctionComponent, ReactNode } from 'react'
import { Table, Td, Tr } from '../table'
import CheckIcon from '../../assets/media/icons/check.svg'
import CancelIcon from '../../assets/media/icons/cancel.svg'
import style from './check.css'
import config from '../../config'

const Check: FunctionComponent = () => {
    const createIconElement = (variable: string | undefined): ReactNode => (!variable
        ? <CancelIcon className={style.cancel} />
        : <CheckIcon className={style.check} />)

    const {
        APP_VERSION,
        API_URL,
        TRANSLATION_SERVICE_URL,
        TRACKING_URL,
        PDF_SERVICE_URL,
        MIWI_FEATURE_LIBRARY_URL,
        MIWI_FEATURE_APP_URL,
        MIWI_MOD_FEATURE_APP_URL,
        MIWI_FEATURE_BACKEND_SWITCHER,
        AUDI_EXCLUSIVE_WEB_COMPONENT_VERSION,
    } = config

    return (
        <article className={style.container}>
            <h1>Work up and running</h1>
            <p>
                Version:
                {' '}
                {APP_VERSION}
            </p>
            <h2>Environment variables</h2>
            <Table className={style.table}>
                <tbody>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(API_URL)}
                        </Td>
                        <Td className={style.td}>
                            API_URL
                        </Td>
                        <Td className={style.td}>
                            {API_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(TRANSLATION_SERVICE_URL)}
                        </Td>
                        <Td className={style.td}>
                            TRANSLATION_SERVICE_URL
                        </Td>
                        <Td className={style.td}>
                            {TRANSLATION_SERVICE_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(TRACKING_URL)}
                        </Td>
                        <Td className={style.td}>
                            TRACKING_URL
                        </Td>
                        <Td className={style.td}>
                            {TRACKING_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(config.PDF_SERVICE_URL)}
                        </Td>
                        <Td className={style.td}>
                            PDF_SERVICE_URL
                        </Td>
                        <Td className={style.td}>
                            {config.PDF_SERVICE_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(MIWI_FEATURE_APP_URL)}
                        </Td>
                        <Td className={style.td}>
                            MIWI_FEATURE_APP_URL
                        </Td>
                        <Td className={style.td}>
                            {MIWI_FEATURE_APP_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(MIWI_MOD_FEATURE_APP_URL)}
                        </Td>
                        <Td className={style.td}>
                            MIWI_MOD_FEATURE_APP_URL
                        </Td>
                        <Td className={style.td}>
                            {MIWI_MOD_FEATURE_APP_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(MIWI_FEATURE_LIBRARY_URL)}
                        </Td>
                        <Td className={style.td}>
                            MIWI_FEATURE_LIBRARY_URL
                        </Td>
                        <Td className={style.td}>
                            {MIWI_FEATURE_LIBRARY_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(PDF_SERVICE_URL)}
                        </Td>
                        <Td className={style.td}>
                            PDF_SERVICE_URL
                        </Td>
                        <Td className={style.td}>
                            {PDF_SERVICE_URL}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td className={style.td}>
                            {createIconElement(MIWI_FEATURE_BACKEND_SWITCHER)}
                        </Td>
                        <Td className={style.td}>
                            MIWI_FEATURE_BACKEND_SWITCHER
                        </Td>
                        <Td className={style.td}>
                            {MIWI_FEATURE_BACKEND_SWITCHER}
                        </Td>
                    </Tr>
                     <Tr>
                        <Td className={style.td}>
                            {createIconElement(MIWI_FEATURE_BACKEND_SWITCHER)}
                        </Td>
                        <Td className={style.td}>
                            AUDI_EXCLUSIVE_WEB_COMPONENT_VERSION
                        </Td>
                        <Td className={style.td}>
                            {AUDI_EXCLUSIVE_WEB_COMPONENT_VERSION}
                        </Td>
                    </Tr>
                </tbody>
            </Table>
        </article>
    )
}

export default Check
