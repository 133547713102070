import { createAction, createAsyncAction } from 'typesafe-actions'
import ComparisonCarlineStructure from '../../../entities/ComparisonCarlineStructure'

export const fetchComparisonCarlineStructure = createAction('CMD / ComparisonCarlineStructure / fetch')()

export const fetchComparisonCarlineStructureAsync = createAsyncAction(
    'EVT / ComparisonCarlineStructure / fetch request',
    'EVT / ComparisonCarlineStructure / fetch success',
    'EVT / ComparisonCarlineStructure / fetch failure',
)<undefined, ComparisonCarlineStructure, Error>()

export const setComparisonCarlineStructure = createAction('DOC / ComparisonCarlineStructure / set state')<ComparisonCarlineStructure>()

export const resetComparisonCarlineStructure = createAction('DOC / ComparisonCarlineStructure / reset state')<ComparisonCarlineStructure>()
