import PDFConfiguration from '../../../../entities/PDFConfiguration'
import { translate } from '../../../../hooks/useTranslation'

const translateAndGetValue = (value: string): string => {
    if (typeof value === 'number' || typeof value === 'boolean') {
        return value
    }

    if (value.includes('http://') || value.includes('https://')) {
        return value
    }

    const formattedKey = `pdfTranslations.${value}`
    const translatedValue = translate(formattedKey)

    return translatedValue !== null ? translatedValue : value
}

/* eslint-disable no-param-reassign */
const deepTranslate = (inputObj: any): any => {
    if (typeof inputObj === 'object' && !Array.isArray(inputObj)) {
        return Object.keys(inputObj).reduce((result: any, key: string) => {
            if (typeof inputObj[key] === 'object') {
                result[key] = deepTranslate(inputObj[key])
            } else {
                result[key] = translateAndGetValue(inputObj[key])
            }

            return result
        }, {})
    }

    if (Array.isArray(inputObj)) {
        return inputObj.map((item) => deepTranslate(item))
    }

    return translateAndGetValue(inputObj)
}

const getTranslatedConfiguration = (obj: PDFConfiguration):
PDFConfiguration => deepTranslate(obj) as PDFConfiguration

export default getTranslatedConfiguration
