import React, { FunctionComponent } from 'react'
import { Spinner } from '../../base-components'
import VehicleCode from '../../entities/VehicleCode'
import useTranslation from '../../hooks/useTranslation'
import Modal, { ModalClosingButton } from '../modal'
import style from './vehicleCodeModal.css'

export type Props = {
    vehicleCode: VehicleCode
    openModalState: [boolean, Function]
    isLoading: boolean
    brand: string
}

const VehicleCodeModal: FunctionComponent<Props> = (props) => {
    const { vehicleCode, openModalState, isLoading, brand } = props
    const { t } = useTranslation()
    const [modalIsOpen, setModalIsOpen] = openModalState

    const closeHandler = (): void => {
        setModalIsOpen(false)
    }

    const vehicleCodeOrSpinner = isLoading ? (
        <div className={style.spinnerContainer}>
            <Spinner />
        </div>
    ) : (
        <p className={style.vehicleCode}>{vehicleCode}</p>
    )

    return (
        <Modal isVisible={modalIsOpen} onClose={closeHandler}>
            <ModalClosingButton onClick={closeHandler} />
            <section className={style.container}>
                <h2 className={style.headline}>{t(`vehicleCode.${brand}.create.title`)}</h2>
                {vehicleCodeOrSpinner}
            </section>
        </Modal>
    )
}

export default VehicleCodeModal
