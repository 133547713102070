import React from 'react'
import { AnchorContextState } from '../../hooks/useVisibleAnchorContext'

const AnchorNavigationContext = React.createContext<AnchorContextState>({
    getTopMostVisibleAnchorId: undefined,
    removeVisibleAnchor: undefined,
    addVisibleAnchor: undefined,
})

export default AnchorNavigationContext
