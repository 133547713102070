import { connect, MapStateToProps } from 'react-redux'
import { Props } from './BuildabilitySwitcherButton'
import { changeBuildabilityType } from '../../redux/actions/app/buildabilitySwitcher.actions'
import defaultBuildabilitySelector
    from '../../redux/selectors/buildability/defaultBuildabilitySelector'
import buildabilityTypesSelector
    from '../../redux/selectors/buildability/buildabilityTypesSelector'
import currentBuildabilityTypeSelector
    from '../../redux/selectors/buildability/currentBuildabilityTypeSelector'

type StateProps = Pick<Props, 'defaultBuildabilityType' | 'availableBuildabilityTypes' | 'currentBuildabilityType'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    defaultBuildabilityType: defaultBuildabilitySelector(state),
    availableBuildabilityTypes: buildabilityTypesSelector(state),
    currentBuildabilityType: currentBuildabilityTypeSelector(state),
})

type DispatchProps = Pick<Props, 'changeBuildabilityType'>

const mapDispatchProps: DispatchProps = {
    changeBuildabilityType: changeBuildabilityType,
}

export default connect(mapStateToProps, mapDispatchProps)
