import { connect, MapStateToProps } from 'react-redux'
import { deselectEquipment, selectEquipment } from '../../redux/actions/app/equipmentGroups.actions'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'
import { Props } from './EquipmentTable'
import visualizationSelector from '../../redux/selectors/visualizationSelector'
import languageSelector from '../../redux/selectors/i18n/languageSelector'

type StateProps = Pick<Props, 'visualization' | 'language'>

type DispatchProps = Pick<Props, 'selectEquipment' | 'deselectEquipment' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    selectEquipment,
    deselectEquipment,
    openInfoModal,
}

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    visualization: visualizationSelector(state),
    language: languageSelector(state),
})

export default connect(mapStateToProps, mapDispatchToProps)
