import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Router'
import equipmentPathsSelector from '../../redux/selectors/equipments/equipmentPathsSelector'

type StateProps = Pick<Props, 'equipmentPaths'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    equipmentPaths: equipmentPathsSelector(state),
})

export default connect(mapStateToProps)
