import { createSelector } from 'reselect'
import configurationDataSelector from './configurationDataSelector'
import { ConflictItem } from '../../../entities/ConfigurationResponse'

const foresightedBuildabilityTriggerEquipmentSelector = createSelector(
    configurationDataSelector,
    (configurationData): ConflictItem | null => {
        if (
            configurationData.conflictInformation === null
            || configurationData.conflictInformation === undefined
        ) {
            return null
        }

        return configurationData.conflictInformation.trigger || null
    },
)

export default foresightedBuildabilityTriggerEquipmentSelector
