import { createSelector } from 'reselect'
import modelsRequestIsPendingSelector from '../pending-request/modelsRequestIsPendingSelector'
import configurationRequestIsPendingSelector from '../pending-request/configurationRequestIsPendingSelector'

const modelSelectionIsLoadingSelector = createSelector(
    modelsRequestIsPendingSelector,
    configurationRequestIsPendingSelector,
    (modelsRequestIsPending, configurationRequestIsPending): boolean => (
        modelsRequestIsPending || configurationRequestIsPending
    ),
)

export default modelSelectionIsLoadingSelector
