import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchTechnicalData,
    fetchTechnicalDataAsync,
    resetTechnicalDataState,
    setTechnicalDataState,
} from '../../actions/app/technicalData.actions'
import configurationStringSelector from '../../selectors/configuration/configurationStringSelector'
import { getTechnicalDataApiUrl } from '../../../constants/apiUrls'
import { ApiRequest } from '../../apiRequest'

// eslint-disable-next-line max-len
const technicalDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchTechnicalData, action)) {
        const configurationString = action.payload === undefined
            ? configurationStringSelector(getState())
            : action.payload

        dispatch(resetTechnicalDataState())

        apiRequest({
            options: {
                url: getTechnicalDataApiUrl(configurationString),
                method: 'GET',
            },
            asyncActions: fetchTechnicalDataAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchTechnicalDataAsync.success, action)) {
        const technicalData = action.payload

        dispatch(setTechnicalDataState(technicalData))
    }
}

export default technicalDataMiddleware
