import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { Checkbox } from '../form'
import style from './attributeFilters.css'

const AttributeFiltersPlaceholder: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <div>
            <span className={style.title}>{t('loading')}</span>
            <ul className={style.inputList}>
                {[...Array(2)].map((element, index) => (
                    <li className={style.inputListItem} key={`filter-placeholder-${index}`}>
                        <label className={style.inputLabelWrapper}>
                            <Checkbox disabled />
                            <span className={style.inputLabel}>{t('loading')}</span>
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default AttributeFiltersPlaceholder
