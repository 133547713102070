import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CartboxPrices'
import cartPricesSelector from '../../redux/selectors/cart/cartPricesSelector'
import cartPricesAreHiddenSelector from '../../redux/selectors/feature-scope/cartPricesAreHiddenSelector'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'
import configurationIsBuildableSelector
    from '../../redux/selectors/configuration/configurationIsBuildableSelector'

type StateProps = Pick<Props, 'prices' | 'isHidden' | 'configIsBuildable'>

type DispatchProps = Pick<Props, 'openInfoModal'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    prices: cartPricesSelector(state),
    isHidden: cartPricesAreHiddenSelector(state),
    configIsBuildable: configurationIsBuildableSelector(state),
})

const mapDispatchToProps: DispatchProps = {
    openInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
