import { createSelector } from 'reselect'
import entryDataSelector from './entryDataSelector'
import NavTarget from '../../entities/NavTarget'
import EntryData from '../../entities/EntryData'

const navigationTargetsSelector = createSelector(
    entryDataSelector,
    (entryData: EntryData): NavTarget[] => entryData.targets ?? [],
)

export default navigationTargetsSelector
