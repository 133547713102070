import { ApiRequest } from '../../apiRequest'
import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { getComparisonCarlineStructureApiUrl } from '../../../constants/apiUrls'
import {
    fetchComparisonCarlineStructure,
    fetchComparisonCarlineStructureAsync,
    setComparisonCarlineStructure,
} from '../../actions/app/comparisonCarlineStructure.actions'

const comparisonCarlineStructureMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchComparisonCarlineStructure, action)) {
        apiRequest({
            options: {
                url: getComparisonCarlineStructureApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchComparisonCarlineStructureAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchComparisonCarlineStructureAsync.success, action)) {
        const comparisonCarlineData = action.payload
        dispatch(setComparisonCarlineStructure(comparisonCarlineData))
    }
}

export default comparisonCarlineStructureMiddleware
