import { createReducer } from 'typesafe-actions'
import Visualization from '../../entities/Visualization'
import { setVisualizationState } from '../actions/app/visualization.actions'

export type VisualizationState = Visualization

export const defaultState: VisualizationState = {
    selectedView: '',
    aveString: '',
    renderImages: [],
}

const visualizationReducer = createReducer(defaultState)
    .handleAction(setVisualizationState, (state, action) => action.payload)

export default visualizationReducer
