import { connect, MapStateToProps } from 'react-redux'
import TargetNavigation from './TargetNavigation'
import shouldNavigateToTargetSelector from '../../redux/selectors/shouldNavigateToTargetSelector'
import navigationTargetsSelector from '../../redux/selectors/navigationTargetsSelector'
import initRouterParamsSelector, { Params } from '../../redux/selectors/initRouterParamsSelector'
import NavTarget from '../../entities/NavTarget'
import navLinksSelector from '../../redux/selectors/navLinksSelector'
import NavLink from '../../entities/NavLink'
import targetNavigationIsLoadingSelector
    from '../../redux/selectors/is-loading/targetNavigationIsLoadingSelector'

type StateProps = {
    shouldNavigateToTarget: boolean
    requestsArePending: boolean
    navigationTargets: NavTarget[]
    routerParams: Params
    availableNavLinks: NavLink[]
}
const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    shouldNavigateToTarget: shouldNavigateToTargetSelector(state),
    requestsArePending: targetNavigationIsLoadingSelector(state),
    navigationTargets: navigationTargetsSelector(state),
    routerParams: initRouterParamsSelector(state),
    availableNavLinks: navLinksSelector(state),
})

export default connect(mapStateToProps)(TargetNavigation)
