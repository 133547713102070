import { Middleware } from 'redux'
import { setPersistedState } from '../../persistState'

const persistingMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    setTimeout(() => {
        const { getState } = store
        const state = getState()

        setPersistedState(state)
    })
}

export default persistingMiddleware
