import CarlineGroup from '../../../../entities/CarlineGroup'
import Model from '../../../../entities/Model'

const createModelTrackingObj = (
    model: Model,
    carlineGroup: CarlineGroup,
    configurationStartType: 'audi code' | 'engine change',
    manufacturer: string,
    totalPrice: string,
    currency: string,
): Object => ({
    productInfo: {
        productID: model.id,
        productName: model.name,
        manufacturer,
    },
    category: {
        primaryCategory: carlineGroup.name,
        subCategory1: 'not available',
        productType: 'engine',
    },
    attributes: {
        'engine-type': model.attributes.find((attr) => attr.id === 'engine-type') || '',
        'gear-type': model.attributes.find((attr) => attr.id === 'gear-type') || '',
        'power-kw': model.attributes.find((attr) => attr.id === 'power.kW') || '',
        'power-ps': model.attributes.find((attr) => attr.id === 'power.PS') || '',
        price: model.price || '',
        totalPrice,
        currency,
        configurationStartType,
    },
})

export default createModelTrackingObj
