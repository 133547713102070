import { createReducer } from 'typesafe-actions'
import { setTrackingIsReadyState } from '../actions/tracking/tracking.actions'

export type TrackingIsReadyState = boolean

export const defaultState: TrackingIsReadyState = false

const trackingIsReadyReducer = createReducer(defaultState)
    .handleAction(setTrackingIsReadyState, (state, action) => action.payload)

export default trackingIsReadyReducer
