import { connect, MapStateToProps } from 'react-redux'
import showLoadingCursorSelector from '../../redux/selectors/showLoadingCursorSelector'
import showGlobalLoadingIndicatorSelector from '../../redux/selectors/is-loading/showGlobalLoadingIndicatorSelector'
import { Props } from './LoadingIndicator'

type StateProps = Pick<Props, 'showLoadingCursor' | 'showGlobalLoadingIndicator'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    showLoadingCursor: showLoadingCursorSelector(state),
    showGlobalLoadingIndicator: showGlobalLoadingIndicatorSelector(state),
})

export default connect(mapStateToProps)
