import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import toggleVatInclusion from '../../actions/app/vatIncluded.actions'
import vatIncludedSelector from '../../selectors/vatIncludedSelector'
import { putCart } from '../../actions/app/cart.actions'
import getPriceViewFlag from './helpers/getPriceViewFlag'
import priceModeSelector from '../../selectors/priceModeSelector'

const vatIncludedMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(toggleVatInclusion, action)) {
        const state = getState()
        const vatIncluded = vatIncludedSelector(state)
        const priceMode = priceModeSelector(state)
        const priceViewFlag = getPriceViewFlag(priceMode, !vatIncluded)

        dispatch(putCart(priceViewFlag))
    }
}

export default vatIncludedMiddleware
