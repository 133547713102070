import { Action, createAction } from 'typesafe-actions'
import VehicleCode from '../../../entities/VehicleCode'
import ErrorType from '../../../entities/ErrorType'
import PDFConfiguration from '../../../entities/PDFConfiguration'

export const createPdf = createAction('CMD / PDF / create')<string[] | undefined>()

export const pdfGotVehicleCode = createAction('EVT / PDF / got vehicleCode')<{ vehicleCode: VehicleCode }>()

export const pdfGotConfiguration = createAction('EVT / PDF / got configuration')<PDFConfiguration>()

export const pdfCreationFailed = createAction('EVT / PDF / vehicleCode creation failed')<{ failureAction: Function; error: Error; type: ErrorType; url: string }, { causedBy: Action }>()
