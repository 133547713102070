import styled from 'styled-components'
import { Button } from '../../base-components'

export const TabContainer = styled.div`
    height: 25em;
    overflow-y: auto;
    padding-right: var(--space-m);
`

export const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--space-m);
`

export const ShowDetailsButton = styled(Button)`
    margin-top: var(--space-s);
`

export const PriceSection = styled.div`
    flex: 1;
    max-width: 50%;
`

export const Container = styled.div`
    padding: var(--space-xl);
    max-width: 1000px;
    width: 60em;
`

export const Title = styled.h2``

export const Subtitle = styled.p``

export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: var(--space-l);
`

export const FooterButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const FooterButton = styled(Button)`
    margin-left: var(--space-m);
`

export const InfoText = styled.p`
    margin-top: var(--space-m);
    margin-bottom: 0;
    padding-bottom: 0;
    color: var(--color-red);
`

export const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
`
