import { connect } from 'react-redux'
import { selectCarline } from '../../redux/actions/app/carlineSelection.actions'
import { Props } from './SelectCarlineButton'

type DispatchProps = Pick<Props, 'selectCarline'>

const mapDispatchToProps: DispatchProps = {
    selectCarline,
}

export default connect(undefined, mapDispatchToProps)
