import { createReducer } from 'typesafe-actions'
import EfficiencyData from '../../entities/EfficiencyData'
import { setEfficiencyDataState } from '../actions/app/efficiencyData.actions'

export type EfficiencyDataState = EfficiencyData

export const defaultState: EfficiencyDataState = {
    efficiencyGroups: [],
}

const efficiencyDataReducer = createReducer(defaultState)
    .handleAction(setEfficiencyDataState, (state, action) => action.payload)

export default efficiencyDataReducer
