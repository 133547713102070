import { connect, MapStateToProps } from 'react-redux'
import cartNameSelector from '../../redux/selectors/cart/cartNameSelector'
import cartNavLinkSelector from '../../redux/selectors/cart/cartNavLinkSelector'
import cartIsVisibleSelector from '../../redux/selectors/cart/cartIsVisibleSelector'
import { Props } from './Cartbox'
import cartIsLoadingSelector from '../../redux/selectors/is-loading/cartIsLoadingSelector'
import equipmentHaveAudiExclusiveSelector from '../../redux/selectors/equipmentHaveAudiExclusiveSelector'
import isAudiExclusivePdfVisibleSelector from '../../redux/selectors/feature-scope/isAudiExclusivePdfVisibleSelector'
import isDigitalGiveawayLinkVisibleSelector from '../../redux/selectors/feature-scope/isDigitalGiveawayLinkVisibleSelector'
import cartAdditionalInformationSelector from '../../redux/selectors/cart/cartAdditionalInformationSelector'
import cartRemarksSelector from '../../redux/selectors/cart/cartRemarksSelector'

type StateProps = Pick<Props, 'cartIsVisible' | 'cartName' | 'cartNavLink' | 'isLoading' | 'isAudiExclusivePdfVisible' | 'hasAudiExclusiveEquipments' | 'isDigitalGiveawayLinkVisible' | 'cartAdditionalInformation' | 'cartRemarks'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    cartIsVisible: cartIsVisibleSelector(state),
    cartName: cartNameSelector(state),
    cartNavLink: cartNavLinkSelector(state),
    isLoading: cartIsLoadingSelector(state),
    isAudiExclusivePdfVisible: isAudiExclusivePdfVisibleSelector(state),
    hasAudiExclusiveEquipments: equipmentHaveAudiExclusiveSelector(state),
    isDigitalGiveawayLinkVisible: isDigitalGiveawayLinkVisibleSelector(state),
    cartAdditionalInformation: cartAdditionalInformationSelector(state),
    cartRemarks: cartRemarksSelector(state),
})

export default connect(mapStateToProps)
