import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Root'
import { init } from '../../redux/actions/core/init.actions'
import shallAppRenderSelector from '../../redux/selectors/shallAppRenderSelector'
import showErrorPageSelector from '../../redux/selectors/showErrorPageSelector'

type StateProps = Pick<Props, 'showContent' | 'showError'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    showContent: shallAppRenderSelector(state),
    showError: showErrorPageSelector(state),
})

type DispatchProps = Pick<Props, 'init'>

const mapDispatchToProps: DispatchProps = {
    init,
}

export default connect(mapStateToProps, mapDispatchToProps)
