import React, { FunctionComponent,  useState } from 'react'
import style from './comparisonResultWrapper.css'
import NoEquipmentIcon from '../../assets/media/icons/no-equipment.svg'
import EmptyDotIcon from '../../assets/media/icons/empty-dot.svg'
import FilledDotIcon from '../../assets/media/icons/filled-dot.svg'
import ComparisonResultData from '../../entities/ComparisonResultData'
import ComparisonData from '../../entities/ComparisonData'
import useTranslation from '../../hooks/useTranslation'
import ComparisonResultSubcategory from '../../entities/ComparisonResultSubcategory'

export type Props = {
    resultData: ComparisonResultData
    comparisonData: ComparisonData
}

const ComparisonResultWrapper: FunctionComponent<Props> = (props) => {
    const { resultData, comparisonData} = props

    const { t } = useTranslation()

    const [ activeCategory, setActiveCategory] = useState(resultData?.categories[0]?.id)

    const categoryContent = (
        <ul className={style.compareResultCategories}>
            {
                resultData?.categories.map(category => {
                    const classes = activeCategory === category.id
                        ? style.compareResultCategory + " " + style.compareResultCategoryActive
                        : style.compareResultCategory
                    return (
                        <li key={category.id} onClick={() => setActiveCategory(category.id)} className={classes}>{t('compare.categories.' + category.id, category.name)}</li>
                    )
                })
            }
        </ul>
    )

    const subcategories = resultData?.categories
        .filter(category => category.id === activeCategory)
        .flatMap(category => category.subCategory)

    const getHeadRows = (subcategory: ComparisonResultSubcategory, columnsNumber: number[]) => {
        return (
            <tr className={style.resultRowCategory}>
                <td key={subcategory.id}>{subcategory.name}</td>
                {
                    columnsNumber.map(col => (
                        <td key={subcategory.id + "_" + col}>{col}</td>))
                }
            </tr>
        )
    }

    const getBodyRows = (subcategory: ComparisonResultSubcategory) => {
        return subcategory.rows
            .filter(row => {
                if(!comparisonData.onlyDifferent){
                    return true
                }
                return comparisonData.onlyDifferent && row.comparability === 'DIFFERENT'
            })
            .map(row => {
                return (
                    <tr key={row.id}
                        className={row.comparability === 'DIFFERENT' ? style.resultRowDifferent : ""}>
                        <td key={row.id} dangerouslySetInnerHTML={{ __html: row.name }}></td>
                        {
                            row.columns.map((column, index) => {
                                if (column.type === "TEXT") {
                                    return (
                                        <td key={row.id + "_" + index} dangerouslySetInnerHTML={{ __html: column.value }}></td>
                                    )
                                } else {
                                    const columnTypeMap = {
                                        "EXTRA": (<EmptyDotIcon/>),
                                        "STANDARD": (<FilledDotIcon/>),
                                        "UNAVAILABLE": (<NoEquipmentIcon/>),
                                    }
                                    return (
                                        <td key={row.id + "_" + index}>{columnTypeMap[column.type]}</td>
                                    )
                                }
                            })
                        }
                    </tr>
                )
            })
    }

    const subcategoryContent = (
        <ul className={style.compareResultCategoryViews}>
            <li className={style.compareResultCategoryView}>
                <table>
                    <tbody>
                        {
                            subcategories.map(subcategory => {
                                if (subcategory.rows.length === 0){
                                    return undefined
                                }
                                const columnsNumber = subcategory.rows[0].columns.map((el, index) => index + 1)
                                const headRow = getHeadRows(subcategory, columnsNumber)
                                const bodyRows = getBodyRows(subcategory)
                                return (
                                    <>
                                        {headRow}
                                        {bodyRows}
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </li>
        </ul>
    )

    const showEquipmentInfoBlock = subcategories
        .flatMap(subcategory => subcategory.rows)
        .flatMap(row => row.columns)
        .filter(column => column.type === "EXTRA" || column.type === "STANDARD")
        .length > 0

    const infoBlock = showEquipmentInfoBlock
        ? (
            <div className={style.tableInfo}>
                <span> <FilledDotIcon/> - Standard equipment</span>
                <span> <EmptyDotIcon/> - Optional equipment</span>
                <span> <NoEquipmentIcon/> - Not available</span>
            </div>
        ) : undefined

    return (
        <div className={style.compareResultWrapper}>
            {categoryContent}
            {subcategoryContent}
            {infoBlock}
        </div>
    )
}

export default ComparisonResultWrapper
