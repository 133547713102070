import { createSelector } from 'reselect'
import { API_URL_EQUIPMENT } from '../../../constants/apiUrls'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const equipmentRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_EQUIPMENT),
)

export default equipmentRequestIsPendingSelector
