import VehicleCodeStatus from '../../entities/VehicleCodeStatus'
import { State } from '../reducers/rootReducer'

function isEmptyObject(obj: Record<string, any>): obj is {} {
    return Object.keys(obj).length === 0
}

export type VehicleCodeStatusSelectorResult = (VehicleCodeStatus & { code: string }) | undefined

const vehicleCodeStatusSelector = (state: State): VehicleCodeStatusSelectorResult =>
    isEmptyObject(state.vehicleCodeStatus) ? undefined : state.vehicleCodeStatus

export default vehicleCodeStatusSelector
