import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './vatInclusionSwitch.css'

export type Props = {
    vatIncluded: boolean
    vatSwitchable: boolean
    toggleVatInclusion: Function
}

const VatInclusionSwitch: FunctionComponent<Props> = (props) => {
    const { vatIncluded, vatSwitchable, toggleVatInclusion } = props
    const { t } = useTranslation()

    const clickHandler = (): void => {
        toggleVatInclusion()
    }

    const text = vatIncluded ? t('price.vatIncluded') : t('price.vatExcluded')

    const vatSwitchButton = vatSwitchable
        ? (
            <button
                onClick={clickHandler}
                className={style.button}
                type="button"
                data-cy="vat-toggle-button"
            >
                (
                {t('price.changeVatInclusion')}
                )
            </button>
        )
        : null

    return (
        <div className={style.container}>
            <span className={style.text}>
                {text}
            </span>
            {vatSwitchButton}
        </div>
    )
}

export default VatInclusionSwitch
