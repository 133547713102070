import React, { FunctionComponent, ReactElement } from 'react'
import { Spinner } from '../spinner/Spinner'
import { ButtonContainer, IconWrapper, Label } from './button.styles'

export type ButtonVariant = 'primary' | 'secondary'

export type Props = {
    children: React.ReactNode
    className?: string
    icon?: ReactElement
    variant?: ButtonVariant
    onClick?: () => void
    loading?: boolean
    disabled?: boolean
}

export const Button: FunctionComponent<Props> = (props) => {
    const { children, onClick, className, icon, variant = 'primary', disabled } = props

    return (
        <ButtonContainer
            disabled={disabled || props.loading}
            variant={variant}
            className={className}
            type="button"
            onClick={onClick}>
            {props.loading ? (
                <Spinner height="24px" width="24px" />
            ) : (
                <>
                    {icon && <IconWrapper>{icon}</IconWrapper>}
                    <Label>{children}</Label>
                </>
            )}
        </ButtonContainer>
    )
}
