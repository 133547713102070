import { createAction } from 'typesafe-actions'
import { CarlineId } from '../../../entities/Carline'
import CarlineGroup, { DisplayMode } from '../../../entities/CarlineGroup'

export const selectCarline = createAction('CMD / CarlineSelection / select')<CarlineId, { forwardToModelPage: boolean }>()

export const setSelectedCarlineState = createAction('DOC / CarlineSelection / set selection state')<CarlineId>()

export const setActiveCarlineGroupId = createAction('CMD / CarlineSelection / set carline group id')<CarlineGroup['id']>()

export const setActiveCarlineGroupIdState = createAction('DOC / CarlineSelection / set carline group id state')<CarlineGroup['id']>()

export const setDisplayMode = createAction('CMD / CarlineSelection / set display mode')<DisplayMode>()

export const setDisplayModeState = createAction('DOC / CarlineSelection / set display mode state')<DisplayMode>()

export const resetCarlineSelectionState = createAction('DOC / CarlineSelection / reset state')()

export const toggleFiltersAreVisible = createAction('CMD / CarlineSelection / toggle filters are visible')()

export const setFiltersAreVisibleState = createAction('DOC / CarlineSelection / set filters are visible state')<boolean>()
