import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import i18n from '../../../i18n/i18n'
import { checkoutGotVehicleCode } from '../../actions/app/checkout.actions'
import { pushTrackingEvent } from '../../actions/tracking/tracking.actions'

const trackCheckoutMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(checkoutGotVehicleCode, action)) {
        const vehicleCode = action.payload
        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'order',
                eventName: 'dcc configuration-submitted',
            },
            attributes: {
                componentName: 'link-list', // TODO which Checkout link was clicked?
                label: i18n.t('navigation.goToCheckout'),
                currentURL: window.location.href,
                targetURL: '',
                clickID: '',
                elementName: 'text link',
                value: vehicleCode,
                pos: '',
            },
        }

        store.dispatch(pushTrackingEvent(dataLayerEvent))
    }
}

export default trackCheckoutMiddleware
