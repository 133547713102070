import { createReducer } from 'typesafe-actions'
import VehicleCode from '../../entities/VehicleCode'
import { resetVehicleCodeState, setVehicleCodeState } from '../actions/app/vehicleCode.actions'

export type VehicleCodeState = VehicleCode

export const defaultState: VehicleCodeState = ''

const vehicleCodeReducer = createReducer(defaultState)
    .handleAction(setVehicleCodeState, (state, action) => action.payload)
    .handleAction(resetVehicleCodeState, (() => defaultState))

export default vehicleCodeReducer
