import { connect, MapStateToProps } from 'react-redux'
import isMiwiEnabledSelector from '../../redux/selectors/feature-scope/isMiwiEnabledSelector'
import { Props } from './MiwiFeatureAppLoader'
import languageSelector from '../../redux/selectors/i18n/languageSelector'
import visualizationSelector from '../../redux/selectors/visualizationSelector'
import consumptionDataSelector from '../../redux/selectors/consumptionDataSelector'
import brandSelector from '../../redux/selectors/brandSelector'
import miwiClientInformationSelector from '../../redux/selectors/miwiClientInformationSelector'
import renderImagesOrderingSelector
    from '../../redux/selectors/render-image/renderImagesOrderingSelector'
import shouldUseMiwiModSelector from '../../redux/selectors/feature-scope/shouldUseMiwiModSelector'
import miwiModLegacyAuthEnabledSelector from '../../redux/selectors/feature-scope/miwiModLegacyAuthEnabledSelector'

type StateProps = Pick<Props,
'isMiwiEnabled'
| 'shouldUseMiwiMod'
| 'legacyAuthEnabled'
| 'language'
| 'visualization'
| 'consumptionData'
| 'brand'
| 'clientInformation'
| 'renderImagesOrdering'
>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isMiwiEnabled: isMiwiEnabledSelector(state),
    shouldUseMiwiMod: shouldUseMiwiModSelector(state),
    legacyAuthEnabled: miwiModLegacyAuthEnabledSelector(state),
    language: languageSelector(state),
    visualization: visualizationSelector(state),
    consumptionData: consumptionDataSelector(state),
    brand: brandSelector(state),
    clientInformation: miwiClientInformationSelector(state),
    renderImagesOrdering: renderImagesOrderingSelector(state),
})

export default connect(mapStateToProps)
