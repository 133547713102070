import { Middleware } from 'redux'
import { LOCATION_CHANGE } from 'connected-react-router'
import { isActionOf } from 'typesafe-actions'
import { pushTrackingEvent, setTrackingState } from '../../actions/tracking/tracking.actions'
import trackingSelector from '../../selectors/tracking/trackingSelector'
import someRequestIsPendingSelector from '../../selectors/pending-request/someRequestIsPendingSelector'
import createPageObj from './helpers/createPageObject'
import locationSelector from '../../selectors/router/locationSelector'
import getPathByLocation from './helpers/getPathByLocation'
import { init } from '../../actions/core/init.actions'

const trackNavigationMiddleware: Middleware = (store) => (next) => (action) => {
    const prevState = store.getState()
    const prevLocation = locationSelector(prevState)
    const prevPath = getPathByLocation(prevLocation)

    next(action)

    if (isActionOf(init, action)) {
        const tracking = trackingSelector(store.getState())

        store.dispatch(setTrackingState({
            ...tracking,
            referrer: document.referrer,
        }, {
            causedBy: action,
        }))
    }

    if (action.type === LOCATION_CHANGE) {
        const { getState, dispatch } = store
        const state = getState()
        const someRequestIsPending = someRequestIsPendingSelector(state)
        const tracking = trackingSelector(state)

        if (someRequestIsPending && tracking.vehicleCode) {
            return
        }

        const { referrer } = tracking

        const prevPathObj = {
            path: referrer !== undefined ? referrer : prevPath,
            isReferrer: referrer !== undefined,
        }

        dispatch(setTrackingState({
            ...tracking,
            page: createPageObj(state, prevPathObj, action.payload.location.pathname),
            user: [
                {
                    segment: {
                        isLoggedIn: false,
                    },
                },
            ],
            referrer: undefined,
        }, {
            causedBy: action,
        }))

        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'page_load',
                eventName: 'generic',
            },
            attributes: {
                componentName: '',
                label: '',
                currentURL: window.location.href,
                targetURL: '',
                clickID: '',
                elementName: '',
                value: '',
                pos: '',
            },
        }

        dispatch(pushTrackingEvent(dataLayerEvent))
    }
}

export default trackNavigationMiddleware
