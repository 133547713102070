import { createSelector } from 'reselect'
import currentEquipmentGroupSelector from './currentEquipmentGroupSelector'

const equipmentTextFilterOptionsSelector = createSelector(
    currentEquipmentGroupSelector,
    (currentEquipmentGroup): string[] => (
        currentEquipmentGroup.equipmentFamilies.flatMap((equipmentFamily) => {
            const { equipments } = equipmentFamily

            if (!equipments) {
                return []
            }

            return [
                ...equipments.map(({ evaKey }) => evaKey),
                ...equipments.map(({ name }) => name),
            ]
        })
    ),
)

export default equipmentTextFilterOptionsSelector
