import { connect, MapStateToProps } from 'react-redux'
import { Props } from './EquipmentFamiliesTables'
import wheelEfficiencyLabelIsEnabledSelector from '../../redux/selectors/feature-scope/wheelEfficiencyLabelIsEnabledSelector'
import longestPriceOfCurrentEquipmentGroupSelector from '../../redux/selectors/equipments/longestPriceOfCurrentEquipmentGroupSelector'

type StateProps = Pick<Props, 'wheelEfficiencyLabelIsEnabled' | 'longestPrice'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    wheelEfficiencyLabelIsEnabled: wheelEfficiencyLabelIsEnabledSelector(state),
    longestPrice: longestPriceOfCurrentEquipmentGroupSelector(state),
})

export default connect(mapStateToProps)
