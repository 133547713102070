import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Checkout'
import checkoutIsEnabledSelector from '../../redux/selectors/checkoutIsEnabledSelector'
import { startCheckout } from '../../redux/actions/app/checkout.actions'

type StateProps = Pick<Props, 'checkoutIsEnabled'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    checkoutIsEnabled: checkoutIsEnabledSelector(state),
})

type DispatchProps = Pick<Props, 'startCheckout'>

const mapDispatchToProps: DispatchProps = {
    startCheckout,
}

export default connect(mapStateToProps, mapDispatchToProps)
