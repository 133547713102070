import EquipmentGroup from '../../../../entities/EquipmentGroup'
import Equipment from '../../../../entities/Equipment'

const filterEquipmentsOfGroups = (
    equipmentGroups: EquipmentGroup[],
    filterFn: (equipment: Equipment) => boolean,
): EquipmentGroup[] => equipmentGroups.map((equipmentGroup) => {
    const { equipmentFamilies } = equipmentGroup

    if (!equipmentFamilies) {
        return equipmentGroup
    }

    return {
        ...equipmentGroup,
        equipmentFamilies: equipmentFamilies.map((equipmentFamily) => {
            const { equipments } = equipmentFamily

            if (!equipments) {
                return equipmentFamily
            }

            return {
                ...equipmentFamily,
                equipments: equipments.filter(filterFn),
            }
        }),
    }
})

export default filterEquipmentsOfGroups
