import { createSelector } from 'reselect'
import cartSelector from './cart/cartSelector'

const totalPriceSelector = createSelector(
    cartSelector,
    (cart): string => {
        const totalPrice = cart.prices.find((price) => price.id === 'TOTAL')

        if (totalPrice === undefined) {
            return ''
        }

        return totalPrice.value
    },
)

export default totalPriceSelector
