import { createSelector } from 'reselect'
import featureScopesSelector from './featureScopesSelector'
import hasConfigurationConflictSelector from '../configuration/hasConfigurationConflictSelector'

const foresightedBuildabilityCheckIsDisabledSelector = createSelector(
    featureScopesSelector,
    hasConfigurationConflictSelector,
    (
        featureScopes,
        hasConfigurationConflict,
    ): boolean => featureScopes.includes('disableForesightedBuildabilityCheck') || !hasConfigurationConflict,
)

export default foresightedBuildabilityCheckIsDisabledSelector
