import { createSelector } from 'reselect'
import currentEquipmentGroupSelector from './currentEquipmentGroupSelector'

const longestPriceOfCurrentEquipmentGroupSelector = createSelector(
    currentEquipmentGroupSelector,
    (currentEquipmentGroup): number => {
        const equipmentFamilies = currentEquipmentGroup?.equipmentFamilies ?? []
        const equipments = equipmentFamilies.flatMap((equipmentFamily) => (
            equipmentFamily.equipments
        ))

        return equipments.reduce((prevLongestPrice, equipment) => {
            const price = equipment.price ?? ''

            if (price.length > prevLongestPrice) {
                return price.length
            }

            return prevLongestPrice
        }, 0)
    },
)

export default longestPriceOfCurrentEquipmentGroupSelector
