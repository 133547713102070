import { createReducer } from 'typesafe-actions'
import { addPendingRequest, removePendingRequest } from '../actions/core/pendingRequests.actions'

export type PendingRequestsReducerState = Record<string, number>

export const defaultState: PendingRequestsReducerState = {}

const pendingRequestsReducer = createReducer(defaultState)
    .handleAction(addPendingRequest, (state, action) => {
        const apiUrl = action.payload
        const count = state[apiUrl] !== undefined
            ? state[apiUrl] + 1
            : 1

        return {
            ...state,
            [apiUrl]: count,
        }
    })
    .handleAction(removePendingRequest, (state, action) => {
        const apiUrl = action.payload
        const count = state[apiUrl] - 1

        return {
            ...state,
            [apiUrl]: count,
        }
    })

export default pendingRequestsReducer
