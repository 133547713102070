import { connect, MapStateToProps } from 'react-redux'
import vatIncludedSelector from '../../redux/selectors/vatIncludedSelector'
import toggleVatInclusion from '../../redux/actions/app/vatIncluded.actions'
import { Props } from './VatInclusionSwitch'
import vatSwitchableSelector from '../../redux/selectors/vatSwitchableSelector'

type StateProps = Pick<Props, 'vatIncluded' | 'vatSwitchable'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    vatIncluded: vatIncludedSelector(state),
    vatSwitchable: vatSwitchableSelector(state),
})

type DispatchProps = Pick<Props, 'toggleVatInclusion'>

const mapDispatchToProps: DispatchProps = {
    toggleVatInclusion,
}

export default connect(mapStateToProps, mapDispatchToProps)
