import { createSelector } from 'reselect'
import featureScopesSelector from './featureScopesSelector'

const orderButtonsAreActiveSelector = createSelector(
    featureScopesSelector,
    (featureScopes): boolean => (
        featureScopes.includes('activateOrderButton')
    ),
)

export default orderButtonsAreActiveSelector
