import { createSelector } from 'reselect'
import cartSelector from './cart/cartSelector'
import { PriceViewFlag } from '../../entities/PriceViewFlag'

const priceViewFlagSelector = createSelector(
    cartSelector,
    (cart): PriceViewFlag => cart.priceViewFlag,
)

export default priceViewFlagSelector
