import { connect, MapStateToProps } from 'react-redux'
import summarySelector from '../../redux/selectors/summarySelector'
import { fetchSummary } from '../../redux/actions/app/summary.actions'
import { Props } from './OverviewView'
import orderKeySelector from '../../redux/selectors/orderKeySelector'
import configuredCarlineTitleSelector from '../../redux/selectors/carlines/configuredCarlineTitleSelector'
import overviewViewIsLoadingSelector from '../../redux/selectors/is-loading/overviewViewIsLoadingSelector'

type StateProps = Pick<Props, 'summary' | 'carlineTitle' | 'orderKey' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    summary: summarySelector(state),
    carlineTitle: configuredCarlineTitleSelector(state),
    orderKey: orderKeySelector(state),
    isLoading: overviewViewIsLoadingSelector(state),
})

type DispatchProps = Pick<Props, 'loadSummaryData'>

const mapDispatchToProps: DispatchProps = {
    loadSummaryData: fetchSummary,
}

export default connect(mapStateToProps, mapDispatchToProps)
