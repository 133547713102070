import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import useTranslation from '../../../hooks/useTranslation'
import { useMappedConfiguration } from '../hooks/useMappedConfiguration'
import { EquipmentsTable } from './EquipmentsTable'

export type Props = {}

export const SectionTitle = styled.h3<{ first?: boolean }>`
    margin-top: ${(props) => (props.first ? '0' : 'var(--space-xl)')};
    margin-bottom: var(--space-m);
`

export const OptionsTab: FunctionComponent<Props> = () => {
    const { t } = useTranslation()
    const { options } = useMappedConfiguration()

    return (
        <>
            {options.map(({ headline, entries }, index) => (
                <section key={index}>
                    <SectionTitle first={index === 0}>{headline ? t(`pdfTranslations.${headline}`) : ''}</SectionTitle>
                    <EquipmentsTable enties={entries} />
                </section>
            ))}
        </>
    )
}
