import React, {
    FunctionComponent,
    PropsWithChildren,
    useContext,
    useEffect,
} from 'react'
import { useInView } from 'react-intersection-observer'
import AnchorNavigationContext from '../anchor-navigation/anchorNavigationContext'

export type Props = PropsWithChildren<{
    id: string
}>

const Anchor: FunctionComponent<Props> = (props) => {
    const { id, children } = props

    const { addVisibleAnchor, removeVisibleAnchor } = useContext(AnchorNavigationContext)
    const { ref, inView, entry } = useInView({
        rootMargin: '-95px',
    })

    const top = window.scrollY + entry?.boundingClientRect.y

    useEffect(() => {
        if (inView) {
            addVisibleAnchor({
                id,
                top,
            })
        } else {
            removeVisibleAnchor(id)
        }
    }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div id={id} className="anchor" />
            <div ref={ref}>
                {children}
            </div>
        </>
    )
}

export default Anchor
