import { createAction, createAsyncAction } from 'typesafe-actions'
import VehicleCode from '../../../entities/VehicleCode'
import VehicleCodeStatus from '../../../entities/VehicleCodeStatus'

export const loadConfigurationByPrString = createAction('CMD / PrString / get configuration')<VehicleCode>()

export const loadConfigurationByPrStringAsync = createAsyncAction(
    'EVT / PrString / get configuration request',
    'EVT / PrString / get configuration success',
    'EVT / PrString / get configuration failure',
)<VehicleCode, VehicleCodeStatus, Error>()
