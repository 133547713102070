import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { fetchSummary, fetchSummaryAsync, setSummaryState } from '../../actions/app/summary.actions'
import { getConfigurationSummaryApiUrl } from '../../../constants/apiUrls'
import { ApiRequest } from '../../apiRequest'

const summaryMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchSummary, action)) {
        apiRequest({
            options: {
                url: getConfigurationSummaryApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchSummaryAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchSummaryAsync.success, action)) {
        const summary = action.payload

        dispatch(setSummaryState(summary))
    }
}

export default summaryMiddleware
