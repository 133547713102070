import React, { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CarlineId } from '../../entities/Carline'
import CautionIcon from '../../assets/media/icons/caution.svg'
import style from './reset-carline-selection.css'

export type Props = {
    configuredCarlineName: string
    configuredCarlineId: CarlineId
    isVisible: boolean
    selectCarline: Function
}

const ResetCarlineSelection: FunctionComponent<Props> = (props) => {
    const {
        configuredCarlineId,
        configuredCarlineName,
        selectCarline,
        isVisible,
    } = props
    const { t } = useTranslation()

    if (!isVisible) {
        return null
    }

    const resetCarlineButtonClick = (): void => {
        selectCarline(configuredCarlineId)
    }

    return (
        <p className={style.container}>
            <CautionIcon className={style.icon} />
            <span>
                <Trans i18nKey="carlineSelection.otherCarlineConfigured">
                    Currently
                    <strong>{{ 'configured-carline': configuredCarlineName }}</strong>
                    configured.
                </Trans>
            </span>
            <button
                type="button"
                onClick={resetCarlineButtonClick}
                className={style.button}
            >
                {t('carlineSelection.resetSelectedCarline', {
                    'configured-carline': configuredCarlineName,
                })}
            </button>
        </p>
    )
}

export default ResetCarlineSelection
