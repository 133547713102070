import { createSelector } from 'reselect'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'
import { API_URL_COMPARE_CODES } from '../../../constants/apiUrls'

const codeComparisonResultDataIsPendingSelector= createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_COMPARE_CODES),
)

export default codeComparisonResultDataIsPendingSelector
