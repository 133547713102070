import { Middleware } from 'redux'
import { LOCATION_CHANGE } from 'connected-react-router'

const scrollTopMiddleware = (window: Window): Middleware => () => (next) => (action) => {
    next(action)

    if (action.type === LOCATION_CHANGE) {
        if (!action.payload?.location?.hash) {
            window.scrollTo(0, 0)
        }
    }
}

export default scrollTopMiddleware
