import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { init } from '../../actions/core/init.actions'
import { fetchEntryDataAsync } from '../../actions/app/entryData.actions'
import languageKeySelector from '../../selectors/i18n/languageKeySelector'
import i18n from '../../../i18n/i18n'
import { setLanguageIsLoaded } from '../../actions/app/i18n.actions'

const i18nMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf([init, fetchEntryDataAsync.success], action)) {
        const { dispatch, getState } = store
        const languageKey = languageKeySelector(getState())

        if (languageKey === '') {
            return
        }

        i18n.changeLanguage(languageKey).then(() => {
            dispatch(setLanguageIsLoaded(true))
        })
    }
}

export default i18nMiddleware
