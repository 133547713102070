import React, { FunctionComponent, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import CheckIcon from '../../assets/media/icons/check.svg'
import NewConfigurationIcon from '../../assets/media/icons/new-configuration-small.svg'
import PdfIcon from '../../assets/media/icons/pdf.svg'
import { ImagesPreview, Spinner } from '../../base-components'
import { useCurrentBrand } from '../../hooks/useCurrentBrand'
import useTranslation from '../../hooks/useTranslation'
import { resetConfiguration } from '../../redux/actions/app/configuration.actions'
import { acceptRescueConflictSolution } from '../../redux/actions/app/conflictSolution.actions'
import { resetVehicleCodeStatus } from '../../redux/actions/app/vehicleCode.actions'
import CartboxPrices from '../cartbox-prices/CartboxPrices'
import Tabbox from '../tabbox'
import { OptionsTab } from './componenets/OptionsTab'
import StandardsTab from './componenets/StandardsTab'
import TechnicalDataTab from './componenets/TechnicalDataTab'
import { useCurrentConfigurationFromVehicleCode } from './hooks/useCurrentConfigurationFromVehicleCode'
import { useMappedConfiguration } from './hooks/useMappedConfiguration'
import { useModalState } from './hooks/useModalState'
import { usePdfDownload } from './hooks/usePdfDownload'
import {
    Container,
    Footer,
    FooterButton,
    FooterButtonsContainer,
    InfoRow,
    InfoText,
    PriceSection,
    ShowDetailsButton,
    SpinnerContainer,
    Subtitle,
    TabContainer,
    Title,
} from './rescueVehicleCodeModal.styles'

export type Props = {}

const ExportPdfButton: FunctionComponent = () => {
    const { t } = useTranslation()
    const { data } = useCurrentConfigurationFromVehicleCode()
    const { download, loading } = usePdfDownload(data)

    return (
        <FooterButton icon={<PdfIcon />} onClick={download} loading={loading}>
            {t('pdf.buttonLabel')}
        </FooterButton>
    )
}

const NewConfigurationButton: FunctionComponent = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const onClick = useCallback(() => {
        dispatch(resetConfiguration(undefined, undefined))
        dispatch(resetVehicleCodeStatus())
    }, [dispatch])

    return (
        <FooterButton icon={<NewConfigurationIcon />} onClick={onClick}>
            {t('configuration.reset')}
        </FooterButton>
    )
}

const AcceptButton: FunctionComponent = () => {
    const { t } = useTranslation()
    const brand = useCurrentBrand()
    const dispatch = useDispatch()
    const onClick = useCallback(() => {
        dispatch(acceptRescueConflictSolution())
    }, [dispatch])
    return (
        <FooterButton icon={<CheckIcon />} onClick={onClick}>
            {t(`vehicleCode.${brand}.rescue.buttonAccept`)}
        </FooterButton>
    )
}

const RescueVehicleCodeModal: FunctionComponent<Props> = () => {
    const brand = useCurrentBrand()
    const { isRepairable } = useModalState()
    const { isLoading, standardsHeadline, optionsHeadline, technicalDataHeadline, modelName, prices, images } =
        useMappedConfiguration()
    const { t } = useTranslation()

    const [showDetails, setShowDetails] = useState(false)

    return (
        <Container>
            <Title>{t(`vehicleCode.${brand}.rescue.title`)}</Title>
            {isLoading ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <>
                    <Subtitle>{modelName}</Subtitle>
                    <InfoRow>
                        <PriceSection>
                            <CartboxPrices prices={prices} isHidden={false} openInfoModal={undefined} />
                        </PriceSection>
                        <ImagesPreview images={images} />
                    </InfoRow>
                    {!showDetails && (
                        <ShowDetailsButton variant="secondary" onClick={() => setShowDetails(true)}>
                            {t(`vehicleCode.${brand}.rescue.buttonShowConfigurationDetails`)}
                        </ShowDetailsButton>
                    )}
                    {showDetails && (
                        <Tabbox
                            tabs={[
                                {
                                    id: 'options',
                                    label: t(`pdfTranslations.${optionsHeadline}`),
                                    isActive: true,
                                    content: (
                                        <TabContainer>
                                            <OptionsTab />
                                        </TabContainer>
                                    ),
                                },
                                {
                                    id: 'standards',
                                    label: t(`pdfTranslations.${standardsHeadline}`),
                                    isActive: false,
                                    content: (
                                        <TabContainer>
                                            <StandardsTab />
                                        </TabContainer>
                                    ),
                                },
                                {
                                    id: 'technical-data',
                                    label: t(`pdfTranslations.${technicalDataHeadline}`),
                                    isActive: false,
                                    content: (
                                        <TabContainer>
                                            <TechnicalDataTab />
                                        </TabContainer>
                                    ),
                                },
                            ]}
                        />
                    )}
                    <InfoText>
                        {isRepairable
                            ? t(`vehicleCode.${brand}.rescue.textRepairable`)
                            : t(`vehicleCode.${brand}.rescue.textNotRepairable`)}
                    </InfoText>
                    <Footer>
                        <FooterButtonsContainer>
                            <ExportPdfButton />
                            <NewConfigurationButton />
                            {isRepairable ? <AcceptButton /> : null}
                        </FooterButtonsContainer>
                    </Footer>
                </>
            )}
        </Container>
    )
}

export default RescueVehicleCodeModal
