import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    applyFilter,
    resetFilters,
    addFilterToState,
    removeFilterFromState,
    resetFiltersState,
} from '../../actions/app/filter.actions'
import filtersSelector from '../../selectors/filtersSelector'

const filterMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(applyFilter, action)) {
        const filter = action.payload
        const state = getState()
        const filters = filtersSelector(state)
        const prevFilters = filters[filter.id]

        const filterIsAlreadySet = (
            prevFilters !== undefined
            && prevFilters.includes(filter.value)
        )

        const filterStateAction = (filterIsAlreadySet)
            ? removeFilterFromState(filter)
            : addFilterToState(filter)

        dispatch(filterStateAction)
    }

    if (isActionOf(resetFilters, action)) {
        dispatch(resetFiltersState())
    }
}

export default filterMiddleware
