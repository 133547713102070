import React, { FunctionComponent, useState } from 'react'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import ForesightedBuildabilityConflictStepItem from './ForesightedBuildabilityConflictStepItem'
import useTranslation from '../../hooks/useTranslation'
import style from './foresightedBuildabilityConflictStep.css'
import ArrowUpIcon from '../../assets/media/icons/arrow-up-small.svg'
import ArrowDownIcon from '../../assets/media/icons/arrow-down-small.svg'

export type Props = {
    conflictStep: ConflictItem[]
    openInfoModal: Function
    selectEquipment: Function
    deselectEquipment: Function
}

const ForesightedBuildabilityConflictStep: FunctionComponent<Props> = (props) => {
    const {
        conflictStep,
        openInfoModal,
        deselectEquipment,
        selectEquipment,
    } = props
    const { t } = useTranslation()
    const [alternativesAreVisible, setAlternativesAreVisible] = useState(false)

    const selectedItem = conflictStep.find((item) => item.selected)
    const alternativeElements = alternativesAreVisible ? conflictStep
        .filter((item) => !item.selected)
        .map((item) => (
            <ForesightedBuildabilityConflictStepItem
                key={item.equipment.id}
                item={item}
                checkboxIsChecked={false}
                checkboxIsVisible
                checkboxIsDisabled={false}
                openInfoModal={openInfoModal}
                selectEquipment={selectEquipment}
                deselectEquipment={deselectEquipment}
            />
        )) : null

    const toggleButtonLabel = t(alternativesAreVisible
        ? 'foresightedBuildabilityModal.button.hideAlt'
        : 'foresightedBuildabilityModal.button.showAlt')
    const toggleButtonIcon = alternativesAreVisible
        ? <ArrowUpIcon className={style.icon} />
        : <ArrowDownIcon className={style.icon} />
    const toggleButton = conflictStep.length > 1 ? (
        <div>
            <button
                type="button"
                className={style.toggleButton}
                onClick={() => setAlternativesAreVisible(!alternativesAreVisible)}
            >
                {toggleButtonIcon}
                {toggleButtonLabel}
            </button>
        </div>
    ) : null

    return (
        <div className={style.container}>
            <ForesightedBuildabilityConflictStepItem
                item={selectedItem}
                checkboxIsChecked
                checkboxIsVisible={false}
                checkboxIsDisabled
                openInfoModal={openInfoModal}
                selectEquipment={selectEquipment}
                deselectEquipment={deselectEquipment}
            />
            {toggleButton}
            {alternativeElements}
        </div>
    )
}

export default ForesightedBuildabilityConflictStep
