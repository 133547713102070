import React, { FunctionComponent } from 'react'
import CarlineGroup from '../../entities/CarlineGroup'
import CarlineGridGroupCarlines from './CarlineGridGroupCarlines'
import style from './carlineGridGroup.css'
import CarlineGroupPlaceholderImage from '../../assets/media/car-silhouette56.svg'
import CarlineImage from '../carline-image'

export type Props = {
    carlineGroup: CarlineGroup
    activeGroupId: string
    setActiveCarlineGroupId: Function
}

const CarlineGridGroup: FunctionComponent<Props> = (props) => {
    const {
        carlineGroup,
        activeGroupId,
        setActiveCarlineGroupId,
    } = props
    const {
        id,
        carlines,
        name,
        media,
    } = carlineGroup

    if (carlines.length === 0) {
        return null
    }

    const isOpen = activeGroupId === id
    const imgElement = media?.length > 0 ? (
        <CarlineImage
            media={media}
            alt={name}
            className={style.img}
            width={590}
            height={253}
            backgroundColor="ffffff"
        />
    ) : <CarlineGroupPlaceholderImage className={style.imgPlaceholder} />

    const toggleCarlines = (): void => {
        const newActiveGroupId = isOpen ? '' : id

        setActiveCarlineGroupId(newActiveGroupId)
    }

    const carlineListElement = isOpen ? (
        <CarlineGridGroupCarlines
            carlines={carlines}
            carlineGroupName={name}
            closeCarlineList={toggleCarlines}
        />
    ) : null

    const openedClass = isOpen ? style.buttonOpened : ''

    return (
        <>
            <button
                onClick={toggleCarlines}
                className={`${style.button} ${openedClass}`}
                type="button"
                data-cy={`carline-group-toggle-button-${id.replace(/\\/g, '.')}`}
            >
                {imgElement}
                <span className={style.title}>{name}</span>
            </button>
            {carlineListElement}
        </>
    )
}

export default CarlineGridGroup
