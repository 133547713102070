import { useSelector } from 'react-redux'
import { useGetConfiguration } from '../../../api/hooks'
import vehicleCodeStatusSelector from '../../../redux/selectors/vehicleCodeStatusSelector'

export const useCurrentConfigurationFromVehicleCode = () => {
    const vehicleCodeState = useSelector(vehicleCodeStatusSelector)
    const code = vehicleCodeState?.code
    const { data, isLoading } = useGetConfiguration(code)
    return { data, isLoading }
}
