import React, { FunctionComponent } from 'react'
import { EquipmentViewVariant } from './EquipmentTable'
import style from './conflictResolutionLabel.css'
import useTranslation from '../../hooks/useTranslation'

type Props = {
    viewVariant: EquipmentViewVariant | undefined
    selected: boolean
}

const ConflictResolutionLabel: FunctionComponent<Props> = (props) => {
    const { viewVariant, selected } = props
    const { t } = useTranslation()

    if (viewVariant !== 'conflict') {
        return null
    }

    const classNames = [
        style.label,
        selected ? style.labelAdd : style.labelRemove,
    ].join(' ')

    const label = selected ? 'conflictModal.remove' : 'conflictModal.add'

    return (
        <>
            <span className={classNames}>
                {t(label)}
            </span>
            <br />
        </>
    )
}

export default ConflictResolutionLabel
