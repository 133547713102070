import { connect, MapStateToProps } from 'react-redux'
import { Props } from './ConflictModal'
import conflictItemsSelector from '../../redux/selectors/conflictItemsSelector'
import configurationRequestIsPendingSelector from '../../redux/selectors/pending-request/configurationRequestIsPendingSelector'
import conflictModalIsDisabledSelector from '../../redux/selectors/feature-scope/conflictModalIsDisabledSelector'

type StateProps = Pick<Props, 'isLoading' | 'conflictItems' | 'isDisabled'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    conflictItems: conflictItemsSelector(state),
    isLoading: configurationRequestIsPendingSelector(state),
    isDisabled: conflictModalIsDisabledSelector(state),
})

export default connect(mapStateToProps)
