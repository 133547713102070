import React, { FunctionComponent } from 'react'
import InfoIIcon from '../../assets/media/icons/info-i.svg'
import style from './infoI.css'

export type Props = {
    isForced: boolean
}

const InfoI: FunctionComponent<Props> = (props) => {
    const { isForced } = props
    const wrapperClassName = isForced ? style.wrapperForced : style.wrapper

    return (
        <span className={wrapperClassName}>
            <InfoIIcon />
        </span>
    )
}

export default InfoI
