import { routerMiddleware } from 'connected-react-router'
import { Middleware } from 'redux'
import history from '../../history'
import coreMiddleware from './core'
import appMiddleware from './app'
import trackingMiddleware from './tracking'

const middleware: Middleware[] = [
    routerMiddleware(history),
    ...coreMiddleware,
    ...trackingMiddleware,
    ...appMiddleware,
]

export default middleware
