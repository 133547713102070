import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetConfiguration } from '../../../redux/actions/app/configuration.actions'
import { resetVehicleCodeStatus } from '../../../redux/actions/app/vehicleCode.actions'
import vehicleCodeStatusSelector from '../../../redux/selectors/vehicleCodeStatusSelector'

export const useModalState = () => {
    const vehicleCodeState = useSelector(vehicleCodeStatusSelector)
    const dispatch = useDispatch()
    const isVisible = vehicleCodeState?.status === 'RESCUE'

    const close = useCallback(() => {
        dispatch(resetConfiguration(undefined, undefined))
        dispatch(resetVehicleCodeStatus())
    }, [dispatch])

    return { isVisible, close, isRepairable: vehicleCodeState?.rescueInfo?.repairable }
}
