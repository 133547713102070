import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConnectedRouter as RouterProvider } from 'connected-react-router'
import React, { FunctionComponent } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Store } from 'redux'
import history from '../../history'
import useOnMount from '../../hooks/useOnMount'
import DocumentTitle from '../document-title'
import Error from '../error/Error'
import LoadingIndicator from '../loading-indicator'
import NotificationList from '../notification-list'
import PageContent from './PageContent'

export type Props = {
    store: Store
    init: Function
    showContent: boolean
    showError: boolean
}

const queryClient = new QueryClient()

const Root: FunctionComponent<Props> = (props) => {
    const { store, init, showContent, showError } = props

    useOnMount(() => {
        init()
    })

    const content = showContent ? (
        <>
            <DocumentTitle />
            <RouterProvider history={history}>
                <NotificationList />
                <PageContent />
            </RouterProvider>
        </>
    ) : null

    const error = showError ? <Error /> : null

    return (
        <ReduxProvider store={store}>
            <QueryClientProvider client={queryClient}>
                <LoadingIndicator>
                    {content}
                    {error}
                </LoadingIndicator>
            </QueryClientProvider>
        </ReduxProvider>
    )
}

export default Root
