import { createReducer } from 'typesafe-actions'
import ConfigurationResponse from '../../entities/ConfigurationResponse'
import {
    resetConfigurationDataState,
    setConfigurationDataState,
} from '../actions/app/configuration.actions'

export type ConfigurationDataState = ConfigurationResponse

export const defaultState: ConfigurationDataState = {
    configurationString: '',
    carlineId: '',
    modelId: '',
    orderKey: '',
    configurationState: 'INCOMPLETE',
    conflictInformation: null,
}

const configurationDataReducer = createReducer(defaultState)
    .handleAction(setConfigurationDataState, (state, action) => action.payload)
    .handleAction(resetConfigurationDataState, () => defaultState)

export default configurationDataReducer
