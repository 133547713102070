import React, { FunctionComponent } from 'react'
import { useMappedConfiguration } from '../hooks/useMappedConfiguration'
import { EquipmentsTable } from './EquipmentsTable'

export type Props = {}

const StandardsTab: FunctionComponent<Props> = () => {
    const { standards } = useMappedConfiguration()

    return <EquipmentsTable enties={standards} isStandard />
}

export default StandardsTab
