import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { EquipmentPackageContent } from '../../entities/Equipment'
import { Table, Td, Tr } from '../table'
import style from './packageTab.css'

export type Props = {
    content: EquipmentPackageContent[]
}

const PackageTab: FunctionComponent<Props> = (props) => {
    const { content } = props
    const { t } = useTranslation()

    const rows = content.map((contentItem) => (
        <Tr key={contentItem.id} className={style.row}>
            <Td className={style.col}>
                {contentItem.name}
            </Td>
            <Td className={style.col}>
                {contentItem.id}
            </Td>
        </Tr>
    ))

    return (
        <Table className={style.table}>
            <thead>
                <Tr>
                    <th className={style.hcol}>
                        {t('infoModal.package.name')}
                    </th>
                    <th className={style.hcol}>
                        {t('infoModal.package.id')}
                    </th>
                </Tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    )
}

export default PackageTab
