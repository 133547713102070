import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { changePriceMode } from '../../actions/app/priceMode.actions'
import { putCart } from '../../actions/app/cart.actions'
import getPriceViewFlag from './helpers/getPriceViewFlag'
import vatIncludedSelector from '../../selectors/vatIncludedSelector'

const priceModeMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(changePriceMode, action)) {
        const newPriceMode = action.payload
        const vatIncluded = vatIncludedSelector(getState())
        const priceViewFlag = getPriceViewFlag(newPriceMode, vatIncluded)

        dispatch(putCart(priceViewFlag))
    }
}

export default priceModeMiddleware
