import React, { FunctionComponent, TableHTMLAttributes } from 'react'
import style from './table.css'

export const Table: FunctionComponent<TableHTMLAttributes<HTMLTableElement>> = (props) => {
    const { children, className, ...rest } = props

    return (
        <table className={`${className} ${style.table}`} {...rest}>
            {children}
        </table>
    )
}

Table.defaultProps = {
    className: '',
}

export const Td: FunctionComponent<TableHTMLAttributes<HTMLTableDataCellElement>> = (props) => {
    const { children, className, ...rest } = props

    return (
        <td className={`${className} ${style.td}`} {...rest}>
            {children}
        </td>
    )
}

Td.defaultProps = {
    className: '',
}

export const Tr: FunctionComponent<TableHTMLAttributes<HTMLTableRowElement>> = (props) => {
    const { children, className, ...rest } = props

    return (
        <tr className={`${className} ${style.tr}`} {...rest}>
            {children}
        </tr>
    )
}

Tr.defaultProps = {
    className: '',
}
