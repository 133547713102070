import React, { FunctionComponent } from 'react'
import style from './comparisonCodeWrapper.css'
import { Input } from '../form'
import PlusIcon from '../../assets/media/icons/plus.svg'
import ComparisonData from '../../entities/ComparisonData'
import useTranslation from '../../hooks/useTranslation'
import ComparisonButtonsSection from '../comparison-buttons-section/ComparisonButtonsSection'

export type Props = {
    comparisonData: ComparisonData
    codeIsSelected: boolean
    setVehicleCode: (i: number, value: string) => void
    setOnlyDifferentFlag: (value: boolean) => void
    getResult: (data: ComparisonData) => void
    setCurrentVehicleCode: () => void
}

const ComparisonCodeWrapper: FunctionComponent<Props> = (props) => {
    const {
        comparisonData,
        codeIsSelected,
        setVehicleCode,
        setOnlyDifferentFlag,
        getResult,
        setCurrentVehicleCode,
    } = props

    const { t } = useTranslation()

    const selectorContent = comparisonData.codes.map((codeData, index) => {
        const cssClass = index == comparisonData.codes.length-1
            ? style.modelSelectorBlock + " " + style.modelSelectorBlockLast
            : style.modelSelectorBlock

        const useConfiguration = index == 0 && codeIsSelected ? (
            <div onClick={() => setCurrentVehicleCode()} className={style.useConfigurationButton} title={t('compare.useCurrentConfigurationTip')}>
                <PlusIcon/>
            </div>
        ) : null

        return (
            <div key={index} className={cssClass}>
                <div className={style.modelSelectorNumber}>{index + 1}</div>
                {useConfiguration}
                <div className={style.modelSelectorCodes}>
                    <Input
                        onChange={(event) => setVehicleCode(index, event.target.value)}
                        value={codeData.code ? codeData.code : ''}
                        className={style.input}
                        placeholder={t('compare.vehicleCodePlaceholder')}
                    />
                </div>
            </div>
        )
    })

    const activateComparisonButton = comparisonData.codes
        .filter(codeData => codeData?.code)
        .length > 1

    return (
        <ComparisonButtonsSection
            selectorContent={selectorContent}
            comparisonData={comparisonData}
            activateComparisonButton={activateComparisonButton}
            getResult={getResult}
            setOnlyDifferentFlag={setOnlyDifferentFlag}
            t={t}
        />
    )
}

export default ComparisonCodeWrapper
