import React, { ChangeEvent, FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import style from './textFilter.css'
import useOnMount from '../../hooks/useOnMount'
import { Input } from '../form'

export type Props = {
    suggestions: string[]
    setFilter: Function
    value: string
}

const TextFilter: FunctionComponent<Props> = (props) => {
    const {
        suggestions,
        setFilter,
        value,
    } = props
    const { t } = useTranslation()

    useOnMount(() => {
        setFilter('')
    })

    const suggestionsUniq = Array.from(new Set(suggestions))
    const optionElements = suggestionsUniq.map((word) => (
        <option
            key={word}
            value={word}
        >
            {word}
        </option>
    ))

    const filterChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setFilter(event.target.value)
    }

    return (
        <label className={style.container}>
            <span className={style.label}>{t('textFilter.label')}</span>
            <Input list="words" onChange={filterChange} value={value} />
            <datalist id="words">
                {optionElements}
            </datalist>
        </label>
    )
}

export default TextFilter
