import { connect, MapStateToProps } from 'react-redux'
import selectedCarlineTitleSelector from '../../redux/selectors/carlines/selectedCarlineTitleSelector'
import { Props } from './ModelSelection'
import modelsLoadedSelector from '../../redux/selectors/models/modelsLoadedSelector'
import modelSelectionIsLoadingSelector from '../../redux/selectors/is-loading/modelSelectionIsLoadingSelector'

type StateProps = Pick<Props, 'modelsLoaded' | 'carlineTitle' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    modelsLoaded: modelsLoadedSelector(state),
    carlineTitle: selectedCarlineTitleSelector(state),
    isLoading: modelSelectionIsLoadingSelector(state),
})

export default connect(mapStateToProps)
