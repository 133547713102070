import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { Checkbox } from '../form'
import { Td, Tr } from '../table'
import style from './modelsTable.css'

const ModelTableRowPlaceholder: FunctionComponent = () => {
    const { t } = useTranslation()

    return (
        <>
            {[...Array(3)].map((element, index) => (
                <Tr key={`model-table-row-placeholder-${index}`}>
                    <Td className={style.nameCol}>
                        <div className={style.nameColInner}>
                            <span>
                                <Checkbox name="model" className={style.checkbox} disabled />
                            </span>
                            <span>
                                <label className={style.label}>
                                    <b>{t('loading')}</b>
                                    <p className={style.subtext}>{t('loading')}</p>
                                </label>
                            </span>
                        </div>
                    </Td>
                    <Td className={style.priceCol}>
                        <div className={style.priceContainer}>{t('loading')}</div>
                    </Td>
                </Tr>
            ))}
        </>
    )
}

export default ModelTableRowPlaceholder
