import { createReducer } from 'typesafe-actions'
import { addFilterToState, removeFilterFromState, resetFiltersState } from '../actions/app/filter.actions'

export type FiltersState = {
    [id: string]: (string|number)[]
}

export const defaultState: FiltersState = {}

const filtersReducer = createReducer(defaultState)
    .handleAction(addFilterToState, (state, action) => {
        const { id, value, type } = action.payload
        const prevValues = state[id]

        const values = (prevValues === undefined || type === 'single' || type === 'max')
            ? [value]
            : [...prevValues, value]

        return {
            ...state,
            [id]: values,
        }
    })
    .handleAction(removeFilterFromState, (state, action) => {
        const { id, value } = action.payload
        const prevValues = state[id]
        const values = prevValues.filter((prevValue) => prevValue !== value)

        return {
            ...state,
            [id]: values,
        }
    })
    .handleAction(resetFiltersState, () => defaultState)

export default filtersReducer
