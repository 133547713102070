import { PriceMode } from '../../../actions/app/priceMode.actions'
import { PriceViewFlag } from '../../../../entities/PriceViewFlag'

const getPriceViewFlag = (priceMode: PriceMode, vatIncluded: boolean): PriceViewFlag => {
    const getVat = (): string => (vatIncluded ? 'BRUTTO' : 'NETTO')

    return `${getVat()}` as PriceViewFlag
}

export default getPriceViewFlag
