import { FunctionComponent, useContext, useEffect } from 'react'
import MiwiFeatureServiceContext from '../../feature-app/MiwiFeatureServiceContext'
import useOnMount from '../../hooks/useOnMount'

export type Props = {
    imageUrl: string
}

const MiwiShowImage: FunctionComponent<Props> = (props) => {
    const { imageUrl } = props

    const miwiFS = useContext(MiwiFeatureServiceContext)
    const miwiService = miwiFS.getMiwiService()

    useOnMount(() => () => {
        if (miwiService) {
            miwiService.stopMedia()
        }
    })

    useEffect(() => {
        if (miwiService) {
            miwiService.showImage(imageUrl)
        }
    }, [imageUrl, miwiService])

    return null
}

export default MiwiShowImage
