import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import i18n from '../../../i18n/i18n'
import { createVehicleCodeAsync } from '../../actions/app/vehicleCode.actions'
import { pushTrackingEvent } from '../../actions/tracking/tracking.actions'

const trackVehicleCodeCreationMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(createVehicleCodeAsync.success, action)) {
        const { vehicleCode } = action.payload
        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'content',
                eventName: 'dcc audi code',
            },
            attributes: {
                componentName: 'VehicleCodeButton',
                label: i18n.t('vehicleCode.audi.create.buttonLabel'),
                currentURL: window.location.href,
                targetURL: '',
                clickID: '',
                elementName: 'icon',
                value: vehicleCode,
                pos: '',
            },
        }

        store.dispatch(pushTrackingEvent(dataLayerEvent))
    }

    if (isActionOf(createVehicleCodeAsync.failure, action)) {
        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'content',
                eventName: 'dcc audi code',
            },
            attributes: {
                componentName: 'VehicleCodeButton',
                label: i18n.t('vehicleCode.audi.create.buttonLabel'),
                currentURL: window.location.href,
                targetURL: '',
                clickID: '',
                elementName: 'icon',
                value: 'audi code could not be loaded',
                pos: '',
            },
        }

        store.dispatch(pushTrackingEvent(dataLayerEvent))
    }
}

export default trackVehicleCodeCreationMiddleware
