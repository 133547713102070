import RenderImage from '../../../../entities/RenderImage'

const filterRenderImagesByOrdering = (renderImages: RenderImage[], renderImagesOrdering: string[]) => {
    if (renderImagesOrdering) {
        return renderImages.map(renderImage => ({
            ...renderImage,
            renderDetails: (() => {
                for (const order of renderImagesOrdering) {
                    const filteredDetails = renderImage.renderDetails.filter(detail => detail.backgroundType?.toLowerCase() === order?.toLowerCase())
                    if (filteredDetails.length > 0) {
                        return filteredDetails
                    }
                }
                return []
            })(),
        }))
    }
    return renderImages
}

export default filterRenderImagesByOrdering
