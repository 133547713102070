import React, {
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from 'react'
import style from './tabbox.css'

export type Tab = {
    id: string
    label: string
    content: ReactNode
    isActive?: boolean
}

export type Props = {
    tabs: Tab[]
    displaySingleTab?: boolean
}

const Tabbox: FunctionComponent<Props> = (props) => {
    const { tabs, displaySingleTab } = props

    const getInitActiveTabIdx = useCallback(() => {
        const idx = tabs.findIndex((tab) => tab.isActive)

        return (idx > -1) ? idx : 0
    }, [tabs])

    const [activeTabIdx, setActiveTabIdx] = useState(getInitActiveTabIdx())

    useEffect(() => {
        setActiveTabIdx(getInitActiveTabIdx())
    }, [getInitActiveTabIdx])

    if (tabs.length === 0 || tabs[activeTabIdx] === undefined) {
        return null
    }

    const hideTabLabels = displaySingleTab ? false : tabs.length < 2
    const activePaneElement = tabs[activeTabIdx].content

    const tabLabelElements = hideTabLabels ? null : (
        <ul className={style.tabLabelsList}>
            {tabs.map((tab, index) => {
                const { id, label } = tab
                const isActive = activeTabIdx === index
                const buttonClassName = isActive
                    ? style.tabLabelButtonActive
                    : style.tabLabelButton

                const onClick = (): void => {
                    setActiveTabIdx(index)
                }

                return (
                    <li key={id} className={style.tabLabelsListItem}>
                        <button
                            onClick={onClick}
                            className={buttonClassName}
                            type="button"
                            data-cy={`tab-button-${id}`}
                        >
                            {label}
                        </button>
                    </li>
                )
            })}
        </ul>
    )

    return (
        <div className={style.container}>
            {tabLabelElements}
            {activePaneElement}
        </div>
    )
}

export default Tabbox
