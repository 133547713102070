import { createSelector } from 'reselect'
import entryDataSelector from './entryDataSelector'
import SessionId from '../../entities/SessionId'

const sessionIdSelector = createSelector(
    entryDataSelector,
    (entryData): SessionId => entryData.sessionId,
)

export default sessionIdSelector
