import { createReducer } from 'typesafe-actions'
import { setInfoModalDataState } from '../actions/app/infoModal.actions'
import { InfoModalData } from '../../entities/InfoModal'

export type InfoModalState = InfoModalData | null

export const defaultState: InfoModalState = null

const infoModalReducer = createReducer<InfoModalState>(defaultState)
    .handleAction(setInfoModalDataState, (state, action) => action.payload)

export default infoModalReducer
