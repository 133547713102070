import { createSelector } from 'reselect'
import configuredCarlineIdSelector from './configuredCarlineIdSelector'
import carlinesSelector from './carlinesSelector'

const configuredCarlineTitleSelector = createSelector(
    configuredCarlineIdSelector,
    carlinesSelector,
    (activeCarlineId, carlines): string => {
        const activeCarline = carlines.find((carline) => carline.id === activeCarlineId)

        if (activeCarline === undefined) {
            return ''
        }

        return activeCarline.name
    },
)

export default configuredCarlineTitleSelector
