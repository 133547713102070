import React, {
    ChangeEvent,
    EventHandler,
    FunctionComponent,
    useState,
} from 'react'
import debounce from 'lodash.debounce'
import { Range } from '../form'
import style from './range-slider.css'

export type Props = {
    initial?: number
    min?: number
    max?: number
    values?: number[]
    onChange: (number: number) => void
    step?: number
    debounceTime?: number
    prefix?: string
    suffix?: string
    dataCy?: string
}

const RangeSlider: FunctionComponent<Props> = (props) => {
    const {
        initial,
        min,
        max,
        values,
        onChange,
        step,
        debounceTime,
        prefix,
        suffix,
        dataCy,
    } = props

    const maxValue = max !== undefined ? max : Math.max(...(values || []))
    const minValue = min !== undefined ? min : Math.min(...(values || []))
    const initialValue = (initial === undefined || initial > maxValue) ? maxValue : initial

    const [value, setValue] = useState(initialValue)

    const callChangeCallback = debounce(
        (numValue): void => {
            onChange(numValue)
        },
        debounceTime || 0,
        {
            leading: true,
        },
    )

    const changeHandler: EventHandler<ChangeEvent<HTMLInputElement>> = (event): void => {
        const numValue = parseInt(event.target.value, 10)

        setValue(numValue)

        callChangeCallback(numValue)
    }

    return (
        <div className={style.container}>
            <Range
                value={value}
                max={maxValue}
                min={minValue}
                step={step || 1}
                onChange={changeHandler}
                data-cy={dataCy}
            />
            <output className={style.output}>{`${prefix ? `${prefix} ` : ''}${value}${suffix ? ` ${suffix}` : ''}`}</output>
        </div>
    )
}

export default RangeSlider
