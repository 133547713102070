import { connect, MapStateToProps } from 'react-redux'
import carlineFilterOptionsSelector from '../../redux/selectors/carlines/carlineFilterOptionsSelector'
import { applyFilter } from '../../redux/actions/app/filter.actions'
import { Props } from '../attribute-filters'

type StateProps = Pick<Props, 'filterOptions'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    filterOptions: carlineFilterOptionsSelector(state),
})

type DispatchProps = Pick<Props, 'applyFilter'>

const mapDispatchToProps: DispatchProps = {
    applyFilter,
}

export default connect(mapStateToProps, mapDispatchToProps)
