import { createSelector } from 'reselect'
import entryDataSelector from './entryDataSelector'

const brandSelector = createSelector(
    entryDataSelector,
    (entryData): string => (
        entryData.featureScopes.find((featureScope) => {
            const brands = [
                'audi',
                'vw',
                'vwn',
                'seat',
                'skoda',
                'man',
                'cupra',
            ]

            return brands.includes(featureScope)
        }) || 'audi'
    ),
)

export default brandSelector
