import { createSelector } from 'reselect'
import entryDataSelector from '../entryDataSelector'
import EntryData from '../../../entities/EntryData'

const featureScopesSelector = createSelector(
    entryDataSelector,
    (entryData): EntryData['featureScopes'] => entryData.featureScopes,
)

export default featureScopesSelector
