import { createAction } from 'typesafe-actions'
import Notification from '../../../entities/Notification'

export const createNotification = createAction('CMD / Notification / create', (payload) => ({
    id: payload.message + Date.now(),
    ...payload,
}))<Notification>()

export const removeNotification = createAction('CMD / Notification / remove')<Notification>()

export const resetNotifications = createAction('CMD / Notification / reset')()

export const addNotificationToState = createAction('DOC / Notification / add to state')<Notification>()

export const removeNotificationFromState = createAction('DOC / Notification / remove from state')<Notification>()

export const resetNotificationsState = createAction('DOC / Notification / reset state')()
