import { Store } from 'redux'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: Function
    }
}

const applyDevtoolExtension = (): Function => {
    /* eslint-disable no-underscore-dangle */
    const browserExtensionIsInstalled = typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function'

    return (browserExtensionIsInstalled && window.__REDUX_DEVTOOLS_EXTENSION__)
        ? window.__REDUX_DEVTOOLS_EXTENSION__({
            trace: true,
            traceLimit: 25,
            name: 'DCC',
        }) : (store: Store) => store
    /* eslint-enable */
}

export default applyDevtoolExtension
