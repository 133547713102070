import CarlineGroup from '../../../../entities/CarlineGroup'
import Carline from '../../../../entities/Carline'

const getSelectedCarlineGroup = (
    carlineGroups: CarlineGroup[],
    selectedCarline: Carline,
): CarlineGroup => (
    carlineGroups.find((carlineGroup) => (
        carlineGroup.carlines.find(((carline) => carline.id === selectedCarline?.id))
    ))
)

export default getSelectedCarlineGroup
