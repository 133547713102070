import { createReducer } from 'typesafe-actions'
import AudiExclusiveDataState from '../../entities/AudiExclusiveDataState'
import { resetAudiExclusiveDataState, setAudiExclusiveDataState } from '../actions/app/audiExclusiveApp.actions'

export const defaultAudiExclusiveSelectedEquipment = {
    price: '0',
    name: '',
    evaKey: '',
    id: 'closed',
    selected: false,
    conflicted: false,
    selectable: false,
    stealth: false,
    isStandard: false,
    isPackage: false,
    isAudiExclusive: false,
    replacedBy: '',
    media: [],
    content: [],
    additionalInformation: null,
}

export const defaultState: AudiExclusiveDataState = {
    shouldOpenAudiExclusiveApp: false,
    audiExclusiveSelectedEquipment: defaultAudiExclusiveSelectedEquipment,
    language: 'en',
}

const audiExclusiveDataStateReducer = createReducer(defaultState)
    .handleAction(setAudiExclusiveDataState, (state, action) => action.payload)
    .handleAction(resetAudiExclusiveDataState, () => defaultState)

export default audiExclusiveDataStateReducer
