import { createAction, createAsyncAction } from 'typesafe-actions'
import ModelData from '../../../entities/ModelData'
import { CarlineId } from '../../../entities/Carline'
import { ModelId } from '../../../entities/Model'
import { InfoModalData } from '../../../entities/InfoModal'

export const selectModel = createAction('CMD / Models / select')<ModelId, { infoModal?: InfoModalData } | undefined>()

export const deselectModel = createAction('CMD / Models / deselect')<ModelId>()

export const fetchModels = createAction('CMD / Models / fetch')<CarlineId>()

export const fetchModelsAsync = createAsyncAction(
    'EVT / Models / fetch request',
    'EVT / Models / fetch success',
    'EVT / Models / fetch failure',
)<undefined, ModelData, Error>()

export const setModelDataState = createAction('DOC / Models / set state')<ModelData>()

export const resetModelDataState = createAction('DOC / Models / reset state')()
