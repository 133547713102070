import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FullConfigurationResponse } from '../../../api'
import config from '../../../config'
import getApiOptions from '../../../getApiOptions'
import { createNotification } from '../../../redux/actions/app/notification.actions'
import getTranslatedConfiguration from '../../../redux/selectors/equipments/helpers/getTranslatedConfiguration'
import removeNullFields from '../../../redux/selectors/equipments/helpers/removeNullFields'

const fetch = window.fetch
const pdfServiceUrl = config.PDF_SERVICE_URL

export const usePdfDownload = (configuration: FullConfigurationResponse) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({ loading: false })
    const partialState = useCallback((s: Partial<typeof state>) => setState((s2) => ({ ...s2, ...s })), [])

    const download = useCallback(async () => {
        partialState({ loading: true })
        try {
            const sanitizedConfiguration = removeNullFields(configuration)
            const translatedConfiguration = getTranslatedConfiguration(sanitizedConfiguration)
            const options: RequestInit = getApiOptions({
                method: 'POST',
                headers: [],
                body: JSON.stringify(translatedConfiguration),
            })
            const response = await fetch(encodeURI(pdfServiceUrl), options)
            if (!response.ok) {
                throw new Error(response.statusText)
            }
            const pdfBlob = await response.blob()

            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(pdfBlob)

            // Setting various property values
            const alink = document.createElement('a')
            alink.href = fileURL
            alink.download = `${configuration.audiCodeSection.audiCode}.pdf`
            alink.click()
        } catch (_e) {
            partialState({ loading: false })
            dispatch(
                createNotification({
                    type: 'error',
                    message: 'error.general',
                }),
            )
        }

        partialState({ loading: false })
    }, [configuration, dispatch, partialState])

    return {
        loading: state.loading,
        download,
    }
}
