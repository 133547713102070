import React, { FunctionComponent, useState } from 'react'
import style from './videoGallery.css'

export type Props = {
    videos: Pick<HTMLVideoElement, 'src' | 'width' | 'height'>[]
    activeVideoIdx?: number
}

const VideoGallery: FunctionComponent<Props> = (props) => {
    const { videos, activeVideoIdx: initialVideoIdx } = props

    const [activeVideoIdx] = useState(initialVideoIdx || 0)
    const activeVideo = videos[activeVideoIdx]

    if (videos.length === 0) {
        return null
    }

    return (
        <>
            <div className={style.container}>
                <div className={style.imgContainer}>
                    {
                        <video
                            src={activeVideo.src}
                            className={style.video}
                            width={activeVideo.width}
                            height={activeVideo.height}
                            controls
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default VideoGallery
