import { createSelector } from 'reselect'
import filterTextEquipmentSelector from './filterTextEquipmentSelector'
import equipmentGroupsSelector from './equipmentGroupsSelector'
import EquipmentGroup from '../../../entities/EquipmentGroup'

const filteredEquipmentGroupsSelector = createSelector(
    equipmentGroupsSelector,
    filterTextEquipmentSelector,
    (equipmentGroups, filterText): EquipmentGroup[] => {
        if (filterText === '') {
            return equipmentGroups
        }

        const filterTextSanitized = filterText.replace(/([()[{*+.$^\\|?])/g, '\\$1')
        const regexp = new RegExp(filterTextSanitized, 'i')

        return equipmentGroups.map((equipmentGroup) => ({
            ...equipmentGroup,
            equipmentFamilies: equipmentGroup.equipmentFamilies.map((equipmentFamily) => ({
                ...equipmentFamily,
                equipments: equipmentFamily.equipments
                    .filter((equipment) => !equipment.stealth)
                    .map((equipment) => ({
                        ...equipment,
                        hidden: !regexp.test(equipment.name) && !regexp.test(equipment.evaKey),
                    })),
            })),
        }))
    },
)

export default filteredEquipmentGroupsSelector
