import { createAction } from 'typesafe-actions'
import { InfoModalData } from '../../../entities/InfoModal'

export const setInfoModalData = createAction('CMD / InfoModal / set data')<InfoModalData>()

export const setInfoModalDataState = createAction('DOC / InfoModal / set data state')<InfoModalData>()

export const openInfoModal = createAction('CMD / InfoModal / open')<InfoModalData>()

export const closeInfoModal = createAction('CMD / InfoModal / close')()
