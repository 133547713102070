import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import Modal from '../modal'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import EquipmentTable from '../equipment-table'
import style from './modelConflictModal.css'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'

export type Props = {
    hasModelConflict: boolean
    conflictItems: ConflictItem[]
    isLoading: boolean
    adoptModelConflict: Function
    dontAdoptModelConflict: Function
    cancelModelConflict: Function
}

const ModelConflictModal: FunctionComponent<Props> = (props) => {
    const {
        hasModelConflict,
        conflictItems,
        isLoading,
        adoptModelConflict,
        dontAdoptModelConflict,
        cancelModelConflict,
    } = props
    const { t } = useTranslation()

    const adoptableEquipments = conflictItems
        .filter((conflictItem) => conflictItem.conflictType === 'ADD')
        .map((conflictItem) => conflictItem.equipment)

    const notAdoptableEquipments = conflictItems
        .filter((conflictItem) => conflictItem.conflictType === 'REMOVE')
        .map((conflictItem) => conflictItem.equipment)

    const adoptableEquipmentsTable = (adoptableEquipments.length > 0) ? (
        <>
            <p className={style.tableTitle}>{t('modelConflictModal.adoptableEquipments')}</p>
            <EquipmentTable
                equipments={adoptableEquipments}
                viewVariant="model_conflict"
            />
        </>
    ) : null

    const notAdoptableEquipmentsTable = (notAdoptableEquipments.length > 0) ? (
        <>
            <p className={style.tableTitle}>{t('modelConflictModal.notAdoptableEquipments')}</p>
            <EquipmentTable
                equipments={notAdoptableEquipments}
                viewVariant="model_conflict"
            />
        </>
    ) : null

    const adopt = (): void => adoptModelConflict()
    const dontAdopt = (): void => dontAdoptModelConflict()
    const cancel = (): void => cancelModelConflict()

    return (
        <Modal isVisible={hasModelConflict}>
            <LoadingIndicatorWrapper isLoading={isLoading}>
                <section className={style.container}>
                    <h2>{t('modelConflictModal.title')}</h2>
                    {adoptableEquipmentsTable}
                    {notAdoptableEquipmentsTable}
                    <div className={style.buttonBar}>
                        <button
                            type="button"
                            className={style.modelChooseButton}
                            onClick={adopt}
                        >
                            {t('modelConflictModal.adoptEquipment')}
                        </button>
                        <button
                            type="button"
                            className={style.modelChooseButton}
                            onClick={dontAdopt}
                        >
                            {t('modelConflictModal.doNotAdoptEquipment')}
                        </button>
                        <button
                            type="button"
                            className={style.modelChooseButton}
                            onClick={cancel}
                        >
                            {t('modelConflictModal.cancel')}
                        </button>
                    </div>
                </section>
            </LoadingIndicatorWrapper>
        </Modal>
    )
}

export default ModelConflictModal
