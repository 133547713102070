import { createSelector } from 'reselect'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'
import config from '../../../config'

const pdfRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, config.PDF_SERVICE_URL),
)

export default pdfRequestIsPendingSelector
