import { PendingRequestsReducerState } from '../../../reducers/pendingRequestsReducer'

const requestIsPending = (
    pendingRequests: PendingRequestsReducerState,
    url: string | undefined,
): boolean => {
    if (!url) {
        return false
    }

    return Object.entries(pendingRequests).some(([pendingRequest, count]) => (
        pendingRequest.match(url) && count > 0
    ))
}

export default requestIsPending
