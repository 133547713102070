import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { ModelAttribute } from '../../entities/Model'

export type Props = {
    attribute: ModelAttribute | undefined
    remarkLabels: Record<string, string>
}

const ModelTableSubAttr: FunctionComponent<Props> = (props) => {
    const { attribute, remarkLabels } = props
    const { t } = useTranslation()

    if (!attribute) {
        return null
    }

    const {
        id,
        nameRemarkRefId,
        valueRemarkRefId,
        value,
        formattedValue,
    } = attribute

    return (
        <>
            <span>
                {t(`modelTable.subtextLabels.${id}`)}
                {remarkLabels[nameRemarkRefId]}
                {': '}
                {formattedValue || value}
                {remarkLabels[valueRemarkRefId]}
            </span>
            <br />
        </>
    )
}

export default ModelTableSubAttr
