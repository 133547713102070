import React, { FunctionComponent } from 'react'
import Modal, { ModalClosingButton } from '../modal'
import RescueVehicleCodeModal from './RescueVehicleCodeModal'
import { useModalState } from './hooks/useModalState'

export type Props = {}

const RescueVehicleCodeModalContainer: FunctionComponent<Props> = () => {
    const { isVisible, close } = useModalState()

    return (
        <Modal isVisible={isVisible}>
            <ModalClosingButton onClick={close} />
            <RescueVehicleCodeModal />
        </Modal>
    )
}

export default RescueVehicleCodeModalContainer
