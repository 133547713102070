import { createSelector } from 'reselect'
import configuredCarlineIdSelector from './configuredCarlineIdSelector'
import selectedCarlineIdSelector from './selectedCarlineIdSelector'

const carlineSelectConfigMismatchSelector = createSelector(
    selectedCarlineIdSelector,
    configuredCarlineIdSelector,
    (selectedCarlineId, configuredCarlineId): boolean => (
        !!configuredCarlineId && selectedCarlineId !== configuredCarlineId
    ),
)

export default carlineSelectConfigMismatchSelector
