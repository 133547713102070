import { connect, MapStateToProps } from 'react-redux'
import { Props } from './ComparisonWrapper'
import comparisonCarlineStructureSelector
    from '../../redux/selectors/comparison/comparisonCarlineStructureSelector'
import {
    fetchCodeComparisonResultData,
    fetchModelComparisonResultData,
    resetComparisonResultData,
} from '../../redux/actions/app/comparisonResultData.actions'
import comparisonCarlineResultDataSelector
    from '../../redux/selectors/comparison/comparisonCarlineResultDataSelector'
import selectedCarlineIdSelector from '../../redux/selectors/carlines/selectedCarlineIdSelector'
import configuredModelIdSelector from '../../redux/selectors/models/configuredModelIdSelector'
import vehicleCodeSelector from '../../redux/selectors/vehicleCodeSelector'
import modelComparisonResultDataIsPendingSelector
    from '../../redux/selectors/pending-request/modelComparisonResultDataIsPendingSelector'
import codeComparisonResultDataIsPendingSelector
    from '../../redux/selectors/pending-request/codeComparisonResultDataIsPendingSelector'
import comparisonDataSelector from '../../redux/selectors/comparison/comparisonDataSelector'
import { setComparisonData } from '../../redux/actions/app/comparisonData.actions'

type StateProps = Pick<Props, 'carlineStructure' | 'resultData' | 'comparisonData' | 'selectedCarineId' | 'selectedModelId' | 'selectedVehicleCode' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    carlineStructure: comparisonCarlineStructureSelector(state),
    resultData: comparisonCarlineResultDataSelector(state),
    comparisonData: comparisonDataSelector(state),
    selectedCarineId: selectedCarlineIdSelector(state),
    selectedModelId: configuredModelIdSelector(state),
    selectedVehicleCode: vehicleCodeSelector(state),
    isLoading: modelComparisonResultDataIsPendingSelector(state) || codeComparisonResultDataIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'getModelComparisonResult' | 'getCodeComparisonResult' | 'resetComparisonResultData' | 'setComparisonData'>

const mapDispatchToProps: DispatchProps = {
    getModelComparisonResult: fetchModelComparisonResultData,
    getCodeComparisonResult: fetchCodeComparisonResultData,
    resetComparisonResultData: resetComparisonResultData,
    setComparisonData: setComparisonData,
}

export default connect(mapStateToProps, mapDispatchToProps)
