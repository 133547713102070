import { createSelector } from 'reselect'
import equipmentRequestIsPendingSelector from '../pending-request/equipmentRequestIsPendingSelector'
import modelsRequestIsPendingSelector from '../pending-request/modelsRequestIsPendingSelector'
import technicalDataRequestIsPendingSelector
    from '../pending-request/technicalDataRequestIsPendingSelector'

const targetNavigationIsLoadingSelector = createSelector(
    modelsRequestIsPendingSelector,
    equipmentRequestIsPendingSelector,
    technicalDataRequestIsPendingSelector,
    (modelRequestIsPending, equipmentRequestIsPending, technicalDataRequestIsPending): boolean => (
        modelRequestIsPending || equipmentRequestIsPending || technicalDataRequestIsPending
    ),
)

export default targetNavigationIsLoadingSelector
