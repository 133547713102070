import { createAction, createAsyncAction } from 'typesafe-actions'
import CarlineData from '../../../entities/CarlineData'

export const fetchCarlineData = createAction('CMD / CarlineData / fetch')()

export const fetchCarlineDataAsync = createAsyncAction(
    'EVT / CarlineData / fetch request',
    'EVT / CarlineData / fetch success',
    'EVT / CarlineData / fetch failure',
)<undefined, CarlineData, Error>()

export const setCarineDataState = createAction('DOC / CarlineData / set state')<CarlineData>()
