import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import useTranslation from '../../hooks/useTranslation'
import { Table, Td, Tr } from '../table'
import style from './noteTable.css'
import NoteTableData from '../../entities/NoteTableData'

export type Props = {
    data: NoteTableData
}

const NoteTable: FunctionComponent<Props> = (props) => {
    const { data } = props
    const { headline, hint, info } = data
    const { t, i18n } = useTranslation()

    if (info.length === 0) {
        return null
    }

    const translateValue = (val: string | null | undefined): null | string => {
        if (val) {
            return i18n.exists(val) ? t(val) : val
        }

        return val
    }

    const remarkLabel = translateValue(hint)

    const dataEntryElements = info.map((note) => {
        const label = translateValue(note.label)
        const value = translateValue(note.value)

        return (
            <Tr key={note.label}>
                <Td className={style.nameCol}>
                    {label}
                </Td>
                <Td className={style.valueCol}>
                    {value}
                </Td>
            </Tr>
        )
    })

    const headlineBlock = headline ? (
        <div className={style.headingWrapper}>
            <Markup content={headline} tagName="h3" className={style.title} />
        </div>
    ) : null

    return (
        <section key={headline}>
            {headlineBlock}
            <Table className={style.table}>
                <tbody>
                    {dataEntryElements}
                </tbody>
            </Table>
            <div className={style.infoBlock}>
                {remarkLabel}
            </div>
        </section>
    )
}

export default NoteTable
