import { createAction, createAsyncAction } from 'typesafe-actions'
import EfficiencyData from '../../../entities/EfficiencyData'

export const fetchEfficiencyData = createAction('CMD / EfficiencyData / fetch')()

export const fetchEfficiencyDataAsync = createAsyncAction(
    'EVT / EfficiencyData / fetch request',
    'EVT / EfficiencyData / fetch success',
    'EVT / EfficiencyData / fetch failure',
)<undefined, EfficiencyData, Error>()

export const setEfficiencyDataState = createAction('DOC / EfficiencyData / set state')<EfficiencyData>()
