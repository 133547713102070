import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { setTextFilter, setTextFilterState } from '../../actions/app/equipmentFilter.actions'

const equipmentFilterMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(setTextFilter, action)) {
        store.dispatch(setTextFilterState(action.payload))
    }
}

export default equipmentFilterMiddleware
