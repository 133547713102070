import { connect, MapStateToProps } from 'react-redux'
import { Props } from './ForesightedBuildabilityModal'
import foresightedBuildabilityItemsSelector from '../../redux/selectors/configuration/foresightedBuildabilityItemsSelector'
import configurationRequestIsPendingSelector from '../../redux/selectors/pending-request/configurationRequestIsPendingSelector'
import foresightedBuildabilityIsResolvableSelector from '../../redux/selectors/configuration/foresightedBuildabilityIsResolvableSelector'
import conflictTotalSelector from '../../redux/selectors/configuration/conflictTotalSelector'
import { adoptConflictSolution, cancelConflictSolution } from '../../redux/actions/app/conflictSolution.actions'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'
import { deselectEquipment, selectEquipment } from '../../redux/actions/app/equipmentGroups.actions'
import foresightedBuildabilityCheckIsDisabledSelector from '../../redux/selectors/feature-scope/foresightedBuildabilityCheckIsDisabledSelector'
import foresightedBuildabilityIsCancelableSelector from '../../redux/selectors/configuration/foresightedBuildabilityIsCancelableSelector'
import foresightedBuildabilityTriggerItemSelector from '../../redux/selectors/configuration/foresightedBuildabilityTriggerItemSelector'

type StateProps = Pick<Props, 'conflictSteps' | 'isLoading' | 'isResolvable' | 'isCancelable' | 'total' | 'isDisabled' | 'trigger'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    conflictSteps: foresightedBuildabilityItemsSelector(state),
    total: conflictTotalSelector(state),
    isLoading: configurationRequestIsPendingSelector(state),
    isResolvable: foresightedBuildabilityIsResolvableSelector(state),
    isCancelable: foresightedBuildabilityIsCancelableSelector(state),
    isDisabled: foresightedBuildabilityCheckIsDisabledSelector(state),
    trigger: foresightedBuildabilityTriggerItemSelector(state),
})

type DispatchProps = Pick<Props, 'adoptConflictSolution' | 'cancelConflictSolution' | 'selectEquipment' | 'deselectEquipment' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    adoptConflictSolution,
    cancelConflictSolution,
    selectEquipment,
    deselectEquipment,
    openInfoModal,
}

export default connect(mapStateToProps, mapDispatchToProps)
