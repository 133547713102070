import React, { FunctionComponent, useState } from 'react'
import RenderImagesPreview from '../../components/render-images-preview/RenderImagesPreview'
import RenderImage from '../../entities/RenderImage'

export type Props = {
    images: RenderImage[]
    isHidden?: boolean
    isLoading?: boolean
}

export const ImagesPreview: FunctionComponent<Props> = ({ images, isHidden = false, isLoading = false }) => {
    const [activeIndex, setActiveIndex] = useState(0)

    return (
        <RenderImagesPreview
            renderImages={images}
            activeRenderImgIdx={activeIndex}
            setActiveRenderImgIdx={setActiveIndex}
            isHidden={isHidden}
            isLoading={isLoading}
            renderImagesOrdering={[]}
        />
    )
}
