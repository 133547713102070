import { createAction, createAsyncAction } from 'typesafe-actions'
import TechnicalData from '../../../entities/TechnicalData'

export const fetchTechnicalData = createAction('CMD / TechnicalData / fetch')<string|undefined>()

export const fetchTechnicalDataAsync = createAsyncAction(
    'EVT / TechnicalData / fetch request',
    'EVT / TechnicalData / fetch success',
    'EVT / TechnicalData / fetch failure',
)<string|undefined, TechnicalData, Error>()

export const setTechnicalDataState = createAction('DOC / TechnicalData / set state')<TechnicalData>()

export const resetTechnicalDataState = createAction('DOC / TechnicalData / reset state')()
