import { createReducer } from 'typesafe-actions'
import TechnicalData from '../../entities/TechnicalData'
import {
    resetTechnicalDataState,
    setTechnicalDataState,
} from '../actions/app/technicalData.actions'

export type TechnicalDataState = TechnicalData

export const defaultState: TechnicalDataState = {
    technicalData: [],
    remarks: [],
}

const technicalDataReducer = createReducer(defaultState)
    .handleAction(setTechnicalDataState, (state, action) => action.payload)
    .handleAction(resetTechnicalDataState, () => defaultState)

export default technicalDataReducer
