import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    deselectEquipment, fetchEquipmentGroups, fetchEquipmentGroupsAsync, selectEquipment, setEquipmentGroupsState,
} from '../../actions/app/equipmentGroups.actions'
import { getEquipmentApiUrl } from '../../../constants/apiUrls'
import { putConfiguration } from '../../actions/app/configuration.actions'
import { openInfoModal } from '../../actions/app/infoModal.actions'
import { ApiRequest } from '../../apiRequest'
import { setEquipmentsHaveAudiExclusiveState } from '../../actions/app/audiExclusiveApp.actions'
import { resetVehicleCodeState } from '../../actions/app/vehicleCode.actions'

// eslint-disable-next-line max-len
const equipmentMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchEquipmentGroups, action)) {
        apiRequest({
            options: {
                url: getEquipmentApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchEquipmentGroupsAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchEquipmentGroupsAsync.success, action)) {
        const { equipmentGroups, hasAudiExclusiveEquipments } = action.payload

        dispatch(setEquipmentsHaveAudiExclusiveState(hasAudiExclusiveEquipments))
        dispatch(setEquipmentGroupsState(equipmentGroups))
    }

    if (isActionOf(selectEquipment, action)) {
        const equipmentId = action.payload

        if (action.meta && action.meta.infoModal) {
            dispatch(openInfoModal({
                ...action.meta.infoModal,
                onClose: selectEquipment(equipmentId, undefined),

            }))
        } else {
            dispatch(putConfiguration({
                id: equipmentId,
                method: 'add',
            }))
        }

        dispatch(resetVehicleCodeState())
    }

    if (isActionOf(deselectEquipment, action)) {
        dispatch(putConfiguration({
            id: action.payload,
            method: 'remove',
        }))

        dispatch(resetVehicleCodeState())
    }
}

export default equipmentMiddleware
