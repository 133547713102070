import { createReducer } from 'typesafe-actions'
import SummaryData from '../../entities/SummaryData'
import { setSummaryState } from '../actions/app/summary.actions'

export type SummaryState = SummaryData

export const defaultState: SummaryState = {
    envkvLabelUrl: '',
    informations: [],
    remarks: [],
}

const summaryReducer = createReducer(defaultState)
    .handleAction(setSummaryState, (state, action) => action.payload)

export default summaryReducer
