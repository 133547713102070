import { createSelector } from 'reselect'
import efficiencyDataSelector from './efficiencyDataSelector'

const consumptionDataSelector = createSelector(
    efficiencyDataSelector,
    (efficiencyData): string[] => (
        efficiencyData.efficiencyGroups.reduce((accu, efficiencyGroup) => {
            const nodes = efficiencyGroup.efficiencyNodes.map((efficiencyNode) => (
                `${efficiencyNode.label}: ${efficiencyNode.value}`
            ))

            return [
                ...accu,
                ...nodes,
            ]
        }, [])
    ),
)

export default consumptionDataSelector
