import React, { FunctionComponent } from 'react'
import TechnicalData from '../../entities/TechnicalData'
import useOnMount from '../../hooks/useOnMount'
import TechnicalDataTable from '../technical-data-table'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'

export type Props = {
    configurationString: string
    loadTechnicalData: Function
    openInfoModal: Function
    technicalData: TechnicalData
    isLoading: boolean
}

const TechnicalDataTab: FunctionComponent<Props> = (props) => {
    const {
        configurationString,
        loadTechnicalData,
        technicalData,
        openInfoModal,
        isLoading,
    } = props

    useOnMount(() => {
        loadTechnicalData(configurationString)
    })

    return (
        <LoadingIndicatorWrapper isLoading={isLoading}>
            <TechnicalDataTable technicalData={technicalData} openInfoModal={openInfoModal} />
        </LoadingIndicatorWrapper>
    )
}

export default TechnicalDataTab
