import React, { FunctionComponent } from 'react'
import Modal, { ModalClosingButton } from '../modal'
import Gallery from '../gallery'
import style from './galleryModal.css'

export type Props = {
    modalActiveState: [boolean, Function]
    images: Pick<HTMLImageElement, 'src' | 'width' | 'height'>[]
    thumbnails: Pick<HTMLImageElement, 'src' | 'width' | 'height'>[]
    miwiImagesUrls: string[]
    activeImgIdx: number
}

const GalleryModal: FunctionComponent<Props> = (props) => {
    const {
        modalActiveState,
        images,
        thumbnails,
        activeImgIdx,
        miwiImagesUrls,
    } = props

    const [modalIsActive, setModalIsActive] = modalActiveState

    const closeModal = (): void => {
        setModalIsActive(false)
    }

    return (
        <Modal isVisible={modalIsActive} onClose={closeModal}>
            <div className={style.container}>
                <ModalClosingButton onClick={closeModal} className={style.closeButton} />
                <Gallery
                    images={images}
                    thumbnails={thumbnails}
                    activeImgIdx={activeImgIdx}
                    miwiImagesUrls={miwiImagesUrls}
                />
            </div>
        </Modal>
    )
}

export default GalleryModal
