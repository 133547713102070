import { createSelector } from 'reselect'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import configurationDataSelector from './configuration/configurationDataSelector'

const conflictItemsSelector = createSelector(
    configurationDataSelector,
    (configurationData): ConflictItem[] => {
        if (configurationData.conflictInformation === null
            || configurationData.conflictInformation === undefined
        ) {
            return []
        }

        return configurationData.conflictInformation.conflictSteps[0].map((conflictItem) => ({
            ...conflictItem,
            equipment: {
                ...conflictItem.equipment,
                selected: conflictItem.conflictType === 'REMOVE',
                selectable: true,
            },
        }))
    },
)

export default conflictItemsSelector
