import { createSelector } from 'reselect'
import equipmentGroupsSelector from './equipmentGroupsSelector'
import EquipmentGroup from '../../../entities/EquipmentGroup'
import filterEquipmentsOfGroups from './helpers/filterEquipmentsOfGroups'

const selectedEquipmentGroupsSelector = createSelector(
    equipmentGroupsSelector,
    (equipmentGroups): EquipmentGroup[] => (
        filterEquipmentsOfGroups(equipmentGroups, (equipment) => equipment.selected)
    ),
)

export default selectedEquipmentGroupsSelector
