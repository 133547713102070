import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import i18n from '../../../i18n/i18n'
import { pdfGotConfiguration } from '../../actions/app/pdf.actions'
import { pushTrackingEvent } from '../../actions/tracking/tracking.actions'

const trackPdfMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(pdfGotConfiguration, action)) {
        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'download',
                eventName: 'dcc download',
            },
            attributes: {
                componentName: 'basket',
                label: i18n.t('pdf.buttonLabel'),
                currentURL: window.location.href,
                targetURL: '',
                clickID: '',
                elementName: 'text link',
                value: 'PDF',
                pos: '',
            },
        }

        store.dispatch(pushTrackingEvent(dataLayerEvent))
    }
}

export default trackPdfMiddleware
