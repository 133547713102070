import { useState } from 'react'

type VisibleAnchor = {
    id: string
    top: number
}

export type AnchorContextState = {
    addVisibleAnchor: (visibleAnchor: VisibleAnchor) => void
    removeVisibleAnchor: (id: string) => void
    getTopMostVisibleAnchorId: () => string | undefined
}

const useVisibleAnchorContext = (): AnchorContextState => {
    const [visibleAnchors, setVisibleAnchors] = useState<VisibleAnchor[]>([])

    const addVisibleAnchor = (visibleAnchor: VisibleAnchor): void => {
        const newVisibleAnchors = [
            ...visibleAnchors,
            visibleAnchor,
        ].sort((a, b) => a.top - b.top)

        setVisibleAnchors(newVisibleAnchors)
    }

    const removeVisibleAnchor = (id: VisibleAnchor['id']): void => {
        setVisibleAnchors(visibleAnchors.filter((anchor) => anchor.id !== id))
    }

    const getTopMostVisibleAnchorId = (): string | undefined => visibleAnchors[0]?.id

    return {
        addVisibleAnchor,
        removeVisibleAnchor,
        getTopMostVisibleAnchorId,
    }
}

export default useVisibleAnchorContext
