import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    loadConfigurationByVehicleCode,
    loadConfigurationByVehicleCodeAsync,
} from '../../actions/app/vehicleCode.actions'
import { pushTrackingEvent, setTrackingState } from '../../actions/tracking/tracking.actions'
import trackingSelector from '../../selectors/tracking/trackingSelector'
import { fetchConfigurationAsync } from '../../actions/app/configuration.actions'
import { fetchCartAsync } from '../../actions/app/cart.actions'
import { fetchEquipmentGroupsAsync } from '../../actions/app/equipmentGroups.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'
import { fetchModelsAsync } from '../../actions/app/models.actions'
import { fetchSummaryAsync } from '../../actions/app/summary.actions'
import configurationDataSelector from '../../selectors/configuration/configurationDataSelector'
import modelsSelector from '../../selectors/models/modelsSelector'
import carlinesSelector from '../../selectors/carlines/carlinesSelector'
import carlineGroupsSelector from '../../selectors/carlines/carlineGroupsSelector'
import createCarlineTrackingObj from './helpers/createCarlineTrackingObj'
import getSelectedCarlineGroup from './helpers/getSelectedCarlineGroup'
import createModelTrackingObj from './helpers/createModelTrackingObj'
import someRequestIsPendingSelector from '../../selectors/pending-request/someRequestIsPendingSelector'
import createPageObj from './helpers/createPageObject'
import { ROUTE_CARLINES, ROUTE_OVERVIEW } from '../../../constants/routes'
import brandSelector from '../../selectors/brandSelector'
import getManufacturer from './helpers/getManufacturer'
import totalPriceSelector from '../../selectors/totalPriceSelector'
import currencySelector from '../../selectors/currencySelector'

const trackLoadConfigurationByVehicleCodeMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(loadConfigurationByVehicleCode, action)) {
        const vehicleCode = action.payload
        const state = store.getState()
        const tracking = trackingSelector(state)
        const updatedTracking = {
            ...tracking,
            vehicleCode,
        }

        store.dispatch(setTrackingState(updatedTracking, {
            causedBy: action,
        }))
    }

    if (isActionOf(loadConfigurationByVehicleCodeAsync.success, action)) {
        const state = store.getState()
        const tracking = trackingSelector(state)

        const dataLayerEvent = {
            eventInfo: {
                eventAction: 'internal_link',
                eventName: 'dcc start via audi code',
            },
            attributes: {
                componentName: '',
                label: '',
                currentURL: window.location.href,
                targetURL: window.location.href,
                clickID: '',
                elementName: '',
                value: tracking.vehicleCode,
                pos: '',
            },
        }

        store.dispatch(pushTrackingEvent(dataLayerEvent))
    }

    if (isActionOf([
        fetchConfigurationAsync.success,
        fetchCartAsync.success,
        fetchEquipmentGroupsAsync.success,
        fetchVisualizationAsync.success,
        fetchModelsAsync.success,
        fetchSummaryAsync.success,
    ], action)) {
        const state = store.getState()
        const someRequestIsPending = someRequestIsPendingSelector(state)
        const tracking = trackingSelector(state)

        if (!someRequestIsPending && tracking.vehicleCode) {
            const configurationData = configurationDataSelector(state)
            const models = modelsSelector(state)
            const carlines = carlinesSelector(state)
            const carlineGroups = carlineGroupsSelector(state)

            const { carlineId, modelId } = configurationData
            const selectedCarline = carlines.find((carline) => carline.id === carlineId)
            const selectedCarlineGroup = getSelectedCarlineGroup(carlineGroups, selectedCarline)
            const configuredModel = models.find((model) => model.id === modelId)

            if (
                configuredModel === undefined
                || selectedCarline === undefined
                || selectedCarlineGroup === undefined
            ) {
                return
            }

            const brand = brandSelector(state)
            const currency = currencySelector(state)
            const manufacturer = getManufacturer(brand)
            const totalPrice = totalPriceSelector(state)

            const carlineObj = createCarlineTrackingObj(
                selectedCarlineGroup,
                selectedCarline,
                manufacturer,
            )
            const modelObj = createModelTrackingObj(
                configuredModel,
                selectedCarlineGroup,
                'audi code',
                manufacturer,
                totalPrice,
                currency,
            )

            const prevPathObj = {
                path: ROUTE_CARLINES,
                isReferrer: false,
            }

            const updatedTracking = {
                ...tracking,
                products: {
                    ...tracking.products,
                },
                page: createPageObj(state, prevPathObj, ROUTE_OVERVIEW),
            }

            delete updatedTracking.vehicleCode

            updatedTracking.products.configuredVehicle[0] = {
                ...tracking.products.configuredVehicle[0],
                carline: carlineObj,
                model: modelObj,
            }

            store.dispatch(setTrackingState(updatedTracking, {
                causedBy: action,
            }))

            const dataLayerEvent = {
                eventInfo: {
                    eventAction: 'page_load',
                    eventName: 'generic',
                },
                attributes: {
                    componentName: '',
                    label: '',
                    currentURL: window.location.href,
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value: '',
                    pos: '',
                },
            }

            store.dispatch(pushTrackingEvent(dataLayerEvent))
        }
    }
}

export default trackLoadConfigurationByVehicleCodeMiddleware
