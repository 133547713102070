import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import style from './error.css'
import errorPageSelector, { ErrorPageErrorType } from '../../redux/selectors/errorPageSelector'

const getText = (type: ErrorPageErrorType): { heading: string; description: string } => {
    switch (type) {
        case ErrorPageErrorType.TokasAuthenticationFailed:
            return {
                heading: 'Authentication failed',
                description: 'TOKAS token authentication failed!',
            }
        default:
            return {
                heading: 'Server Error',
                description: 'We are sorry, the Configurator is currently not available.',
            }
    }
}

const Error: FunctionComponent = () => {
    const { type } = useSelector(errorPageSelector)
    const { heading, description } = getText(type)

    return (
        <div className={style.pageContainer}>
            <div className={style.pageContent}>
                <article className={style.content}>
                    <h1>{ heading }</h1>
                    <p>{ description }</p>
                </article>
            </div>
        </div>
    )
}

export default Error
