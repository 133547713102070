import React, { FunctionComponent } from 'react'
import style from './cartboxRemarks.css'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    remarks: CartRemarks
}

const CartboxRemarks: FunctionComponent<Props> = (props) => {
    const { remarks } = props
    const { t } = useTranslation()

    if (!remarks || remarks.length === 0) {
        return null
    }

    const remarkBlocks = remarks.map((remark, index) => (
        <li className={style.blockEntry} key={index}>
            <span className={style.entryValue}>{`${remark.mark ? remark.mark : ""}${t(`remark.${remark.key}`)}`}</span>
        </li>
    ))

    return (
        <ul className={style.container}>
            {remarkBlocks}
        </ul>
    )
}

export default CartboxRemarks
