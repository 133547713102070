import { Middleware } from 'redux'
import config from '../../../config'
import apiRequest from '../../apiRequest'
import abortMiddleware from './abortMiddleware'
import apiUiMiddleware from './apiUiMiddleware'
import audiExclusiveMiddleware from './audiExclusiveMiddleware'
import brandMiddleware from './brandMiddleware'
import carlineDataMiddleware from './carlineDataMiddleware'
import carlineSelectionMiddleware from './carlineSelectionMiddleware'
import cartMiddleware from './cartMiddleware'
import checkoutMiddleware from './checkoutMiddleware'
import configurationDataMiddleware from './configurationDataMiddleware'
import conflictSolutionMiddleware from './conflictSolutionMiddleware'
import efficiencyDataMiddleware from './efficiencyDataMiddleware'
import entryDataMiddleware from './entryDataMiddleware'
import equipmentFilterMiddleware from './equipmentFilterMiddleware'
import equipmentMiddleware from './equipmentMiddleware'
import errorMiddleware from './errorMiddleware'
import filterMiddleware from './filterMiddleware'
import i18nMiddleware from './i18nMiddleware'
import infoModalMiddleware from './infoModalMiddleware'
import initMiddleware from './initMiddleware'
import modelDataMiddleware from './modelDataMiddleware'
import notificationMiddleware from './notificationMiddleware'
import pdfMiddleware from './pdfMiddleware'
import priceModeMiddleware from './priceModeMiddleware'
import purchaseModeMiddleware from './purchaseModeMiddleware'
import renderImagesMiddleware from './renderImagesMiddleware'
import resetMiddleware from './resetMiddleware'
import scrollTopMiddleware from './scrollTopMiddleware'
import sidebarMiddleware from './sidebarMiddleware'
import summaryMiddleware from './summaryMiddleware'
import technicalDataMiddleware from './technicalDataMiddleware'
import vatIncludedMiddleware from './vatIncludedMiddleware'
import vehicleCodeMiddleware from './vehicleCodeMiddleware'
import visualizationMiddleware from './visualizationMiddleware'
import digitalGiveawayMiddleware from './digitalGiveawayMiddleware'
import prStringMiddleware from './prStringMiddleware'
import buildabilitySwitcherMiddleware from './buidabilitySwitcherMiddleware'
import comparisonCarlineStructureMiddleware from './comparisonCarlineStructureMiddleware'
import comparisonResultDataMiddleware from './comparisonResultDataMiddleware'

const appMiddleware: Middleware[] = [
    initMiddleware,
    i18nMiddleware,
    apiUiMiddleware,
    errorMiddleware,
    scrollTopMiddleware(window),
    brandMiddleware,
    notificationMiddleware(window.setTimeout),
    entryDataMiddleware(apiRequest),
    carlineDataMiddleware(apiRequest),
    carlineSelectionMiddleware,
    abortMiddleware,
    configurationDataMiddleware(apiRequest),
    modelDataMiddleware(apiRequest),
    cartMiddleware(apiRequest),
    equipmentMiddleware(apiRequest),
    audiExclusiveMiddleware(apiRequest),
    equipmentFilterMiddleware,
    summaryMiddleware(apiRequest),
    technicalDataMiddleware(apiRequest),
    infoModalMiddleware,
    renderImagesMiddleware,
    checkoutMiddleware,
    pdfMiddleware(fetch, console, config.PDF_SERVICE_URL),
    vehicleCodeMiddleware(apiRequest),
    priceModeMiddleware,
    purchaseModeMiddleware,
    sidebarMiddleware,
    visualizationMiddleware(window, apiRequest),
    efficiencyDataMiddleware(apiRequest),
    vatIncludedMiddleware,
    conflictSolutionMiddleware(apiRequest),
    resetMiddleware,
    filterMiddleware,
    digitalGiveawayMiddleware(apiRequest, console),
    prStringMiddleware(apiRequest),
    buildabilitySwitcherMiddleware(apiRequest),
    comparisonCarlineStructureMiddleware(apiRequest),
    comparisonResultDataMiddleware(apiRequest),
]

export default appMiddleware
