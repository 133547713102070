import { ApiRequest } from '../../apiRequest'
import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    API_URL_COMPARE_CODES,
    API_URL_COMPARE_MODELS,
    getCompareCodesApiUrl,
    getCompareModelsApiUrl,
} from '../../../constants/apiUrls'
import {
    fetchModelComparisonResultData,
    fetchModelComparisonResultDataAsync,
    fetchCodeComparisonResultData,
    fetchCodeComparisonResultDataAsync,
    setComparisonResultData,
} from '../../actions/app/comparisonResultData.actions'
import { addPendingRequest, removePendingRequest } from '../../actions/core/pendingRequests.actions'
import { createNotification } from '../../actions/app/notification.actions'

const comparisonResultDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchModelComparisonResultData, action)) {
        if (action.payload.models) {
            const models = action.payload.models
                .filter(model => model.modelId != null && model.modelId !== '')
            const request = {models}
            dispatch(addPendingRequest(API_URL_COMPARE_MODELS, { causedBy: fetchModelComparisonResultData(undefined) }))
            apiRequest({
                options: {
                    url: getCompareModelsApiUrl(),
                    method: 'POST',
                    body: JSON.stringify(request),
                },
                asyncActions: fetchModelComparisonResultDataAsync,
                causedBy: action,
            }, dispatch)
        }
    }

    if (isActionOf(fetchModelComparisonResultDataAsync.success, action)) {
        dispatch(removePendingRequest(API_URL_COMPARE_MODELS, { causedBy: fetchModelComparisonResultData(undefined) }))
        const comparisonResultData = action.payload
        dispatch(setComparisonResultData(comparisonResultData))
    }

    if (isActionOf(fetchModelComparisonResultDataAsync.failure, action)) {
        dispatch(removePendingRequest(API_URL_COMPARE_MODELS, { causedBy: fetchModelComparisonResultData(undefined) }))
        const message = action.payload.message !== "BAD_REQUEST" && action.payload.message !== "INTERNAL_SERVER_ERROR"
            ? action.payload.message
            : 'Error'
        dispatch(createNotification({
            type: 'error',
            message: message,
        }))
    }

    if (isActionOf(fetchCodeComparisonResultData, action)) {
        const codes = action.payload.codes
            .filter(codeData => codeData.code != null && codeData.code !== '')
            .map(codeData => codeData.code)
        const request = {
            vehicleCodes: codes,
        }
        dispatch(addPendingRequest(API_URL_COMPARE_CODES, { causedBy: fetchCodeComparisonResultData(undefined) }))
        apiRequest({
            options: {
                url: getCompareCodesApiUrl(),
                method: 'POST',
                body: JSON.stringify(request),
            },
            asyncActions: fetchCodeComparisonResultDataAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchCodeComparisonResultDataAsync.success, action)) {
        dispatch(removePendingRequest(API_URL_COMPARE_CODES, { causedBy: fetchCodeComparisonResultData(undefined) }))
        const comparisonResultData = action.payload
        dispatch(setComparisonResultData(comparisonResultData))
    }

    if (isActionOf(fetchCodeComparisonResultDataAsync.failure, action)) {
        dispatch(removePendingRequest(API_URL_COMPARE_CODES, { causedBy: fetchCodeComparisonResultData(undefined) }))
        const message = action.payload.message !== "BAD_REQUEST" && action.payload.message !== "INTERNAL_SERVER_ERROR"
            ? action.payload.message
            : 'Error'
        dispatch(createNotification({
            type: 'error',
            message: message,
        }))
    }
}

export default comparisonResultDataMiddleware
