import { connect, MapStateToProps } from 'react-redux'
import { Props } from './NotificationList'
import notificationsSelector from '../../redux/selectors/notificationsSelector'
import { removeNotification, resetNotifications } from '../../redux/actions/app/notification.actions'

type StateProps = Pick<Props, 'notifications'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    notifications: notificationsSelector(state),
})

type DispatchProps = Pick<Props, 'resetNotifications' | 'removeNotification'>

const mapDispatchToProps: DispatchProps = {
    resetNotifications,
    removeNotification,
}

export default connect(mapStateToProps, mapDispatchToProps)
