import { createReducer } from 'typesafe-actions'
import { resetComparisonData, setComparisonData } from '../actions/app/comparisonData.actions'
import ComparisonData from '../../entities/ComparisonData'

const defaultComparisonData: ComparisonData = {
    type: 'models',
    onlyDifferent: false,
    models: [
        {
            carlineId: '',
            modelId: '',
        },
        {
            carlineId: '',
            modelId: '',
        }, {
            carlineId: '',
            modelId: '',
        },
    ],
    codes: [
        {
            code: '',
        },
        {
            code: '',
        },
        {
            code: '',
        },
    ],
}

const comparisonDataReducer = createReducer(defaultComparisonData)
    .handleAction(setComparisonData, (state, action) => action.payload)
    .handleAction(resetComparisonData, (() => defaultComparisonData))

export default comparisonDataReducer
