import { createSelector } from 'reselect'
import carlinesRequestIsPendingSelector from '../pending-request/carlinesRequestIsPendingSelector'
import modelsRequestIsPendingSelector from '../pending-request/modelsRequestIsPendingSelector'

const carlineSelectionIsLoadingSelector = createSelector(
    carlinesRequestIsPendingSelector,
    modelsRequestIsPendingSelector,
    (carlinesRequestIsPending, modelsRequestIsPending): boolean => (
        carlinesRequestIsPending
        || modelsRequestIsPending
    ),
)

export default carlineSelectionIsLoadingSelector
