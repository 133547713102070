import { connect, MapStateToProps } from 'react-redux'
import conflictItemsSelector from '../../redux/selectors/conflictItemsSelector'
import configurationRequestIsPendingSelector from '../../redux/selectors/pending-request/configurationRequestIsPendingSelector'
import hasModelConflictSelector from '../../redux/selectors/configuration/hasModelConflictSelector'
import { Props } from './ModelConflictModal'
import {
    adoptConflictSolution,
    dontAdoptConflictSolution,
    cancelConflictSolution,
} from '../../redux/actions/app/conflictSolution.actions'

type StateProps = Pick<Props, 'hasModelConflict' | 'conflictItems' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    hasModelConflict: hasModelConflictSelector(state),
    conflictItems: conflictItemsSelector(state),
    isLoading: configurationRequestIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'adoptModelConflict' | 'dontAdoptModelConflict' | 'cancelModelConflict'>

const mapDispatchToProps: DispatchProps = {
    adoptModelConflict: adoptConflictSolution,
    dontAdoptModelConflict: dontAdoptConflictSolution,
    cancelModelConflict: cancelConflictSolution,
}

export default connect(mapStateToProps, mapDispatchToProps)
