import { createSelector } from 'reselect'
import priceViewFlagSelector from './priceViewFlagSelector'
import { PriceMode } from '../actions/app/priceMode.actions'
import { PriceViewFlag } from '../../entities/PriceViewFlag'

const priceModeSelector = createSelector(
    priceViewFlagSelector,
    (priceViewFlag): PriceMode => {
        const priceModeMap: Record<PriceViewFlag, PriceMode> = {
            PRICE_BRUTTO: 'full',
            RATE_BRUTTO: 'rate',
            BRUTTO: 'full',
            PRICE_NETTO: 'full',
            RATE_NETTO: 'rate',
            NETTO: 'full',
            PRICE_OTR: 'full',
            PRICE_WLTP: 'full',
        }

        return priceModeMap[priceViewFlag]
    },
)

export default priceModeSelector
