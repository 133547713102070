import { createSelector } from 'reselect'
import cartRequestIsPendingSelector from '../pending-request/cartRequestIsPendingSelector'
import equipmentRequestIsPendingSelector from '../pending-request/equipmentRequestIsPendingSelector'
import configurationRequestIsPendingSelector from '../pending-request/configurationRequestIsPendingSelector'

const cartIsLoadingSelector = createSelector(
    cartRequestIsPendingSelector,
    equipmentRequestIsPendingSelector,
    configurationRequestIsPendingSelector,
    (
        cartRequestIsPending,
        equipmentRequestIsPending,
        configurationRequestIsPending,
    ): boolean => cartRequestIsPending
            || equipmentRequestIsPending
            || configurationRequestIsPending,
)

export default cartIsLoadingSelector
