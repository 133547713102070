import CarlineGroup from '../../../../entities/CarlineGroup'
import Carline from '../../../../entities/Carline'

const createCarlineTrackingObj = (
    carlineGroup: CarlineGroup,
    carline: Carline,
    manufacturer: string,
): Object => ({
    productInfo: {
        productID: carline.id,
        productName: carline.name,
        manufacturer,
    },
    category: {
        primaryCategory: carlineGroup.name,
        subCategory1: 'not available',
        productType: 'configured car',
    },
    attributes: {
        'engine-type': carline.attributes['engine-type'],
        'gear-type': carline.attributes['gear-type'],
        mbvModelYear: carline.mbvModelYear,
        mbvHandbook: carline.mbvHandbook,
    },
})

export default createCarlineTrackingObj
