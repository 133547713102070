import { createSelector } from 'reselect'
import selectedCarlineIdSelector from './selectedCarlineIdSelector'
import carlinesSelector from './carlinesSelector'

const selectedCarlineTitleSelector = createSelector(
    selectedCarlineIdSelector,
    carlinesSelector,
    (activeCarlineId, carlines): string => {
        const activeCarline = carlines.find((carline) => carline.id === activeCarlineId)

        if (activeCarline === undefined) {
            return ''
        }

        return activeCarline.name
    },
)

export default selectedCarlineTitleSelector
