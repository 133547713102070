import { Markup } from 'interweave'
import React, { FunctionComponent } from 'react'
import SummaryData from '../../entities/SummaryData'
import useOnMount from '../../hooks/useOnMount'
import useTranslation from '../../hooks/useTranslation'
import { getRemarkLabels } from '../disclaimer-text'
import { Table, Td, Tr } from '../table'
import style from './summaryTable.css'

export type Props = {
    summary: SummaryData
    loadSummaryData?: Function
}

const SummaryTable: FunctionComponent<Props> = (props) => {
    const { summary, loadSummaryData } = props
    const { t, i18n } = useTranslation()

    useOnMount(() => {
        // eslint-disable-next-line no-unused-expressions
        loadSummaryData && loadSummaryData()
    })

    if (summary.informations.length === 0) {
        return null
    }

    const remarkLabels = getRemarkLabels(summary.remarks)

    const rowElements = summary.informations.map((summaryInfo) => {
        const { label, imageUrl, value, labelRemarkRefId, valueRemarkRefId } = summaryInfo

        const imageElement =
            imageUrl !== null ? (
                <figure className={style.figure}>
                    <img src={imageUrl} alt={label} />
                </figure>
            ) : null

        const translatedLabel = i18n.exists(`summaryData.${label}`) ? t(`summaryData.${label}`) : label

        return (
            <Tr key={label}>
                <Td className={style.col}>
                    <span>
                        <Markup tagName="span" content={translatedLabel} /> {remarkLabels[labelRemarkRefId]}
                    </span>
                    {imageElement}
                </Td>
                <Td className={`${style.col} ${style.valueCol}`}>
                    <Markup tagName="span" content={value} /> {remarkLabels[valueRemarkRefId]}
                </Td>
            </Tr>
        )
    })

    return (
        <Table>
            <tbody>{rowElements}</tbody>
        </Table>
    )
}

export default SummaryTable
