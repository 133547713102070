import React from 'react'
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core'
import { ReactFeatureApp } from '@feature-hub/react'
import { MiwiAppServiceProvider } from '@audi/miwi-feature-service'
import externals from './externals'
import DCC from '../DCC'
import MiwiFeatureServiceContext from './MiwiFeatureServiceContext'

export type Dependencies = FeatureServices

const featureAppDefinition: FeatureAppDefinition<
ReactFeatureApp,
Dependencies
> = {
    dependencies: {
        externals,
        featureServices: {
            'dasburo:miwi-app-feature-service': '^1.0.0',
        },
    },

    create: ({ featureServices }) => ({
        render: () => (
            <MiwiFeatureServiceContext.Provider value={featureServices['dasburo:miwi-app-feature-service'] as MiwiAppServiceProvider}>
                <DCC />
            </MiwiFeatureServiceContext.Provider>
        ),
    }),
}

export default featureAppDefinition
