import { createSelector } from 'reselect'
import sessionIdIsSetSelector from './sessionIdIsSetSelector'
import currentPathnameSelector from './router/currentPathnameSelector'
import { ROUTE_CHECK } from '../../constants/routes'
import trackingIsReadySelector from './tracking/trackingIsReadySelector'
import languageIsLoadedSelector from './i18n/languageIsLoadedSelector'

const shallAppRenderSelector = createSelector(
    sessionIdIsSetSelector,
    currentPathnameSelector,
    trackingIsReadySelector,
    languageIsLoadedSelector,
    (
        sessionIdSet,
        currentPathname,
        trackingIsReady,
        languageIsLoaded,
    ): boolean => (
        (trackingIsReady && sessionIdSet && languageIsLoaded) || currentPathname === ROUTE_CHECK
    ),
)

export default shallAppRenderSelector
